import { Link } from '@material-ui/core';import React from 'react';
const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
const BaseLink = props => {
  let { url, component } = props;
  return (
    // <Link underline="none" href={url} onClick={preventDefault} color="inherit">
    component && component
    // </Link>
  );
};

export default BaseLink;
