import { Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import AutocompleteTimeOfDay from '../AutoComplete/AutocompleteTimeOfDay';
import IconButtonOptions from '../Button/IconButtonOption';

function TimeRangeOfDay(props) {
  const {
    placeholderStart,
    placeholderEnd,
    formik,
    name,
    type,
    labelStart,
    labelEnd
  } = props;
  let listDayOfWeek: any = [
    { id: 'mon', title: 'Thứ 2' },
    { id: 'tue', title: 'Thứ 3' },
    { id: 'wed', title: 'Thứ 4' },
    { id: 'thu', title: 'Thứ 5' },
    { id: 'fri', title: 'Thứ 6' },
    { id: 'sat', title: 'Thứ 7' },
    { id: 'sun', title: 'Chủ nhật' }
  ];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px'
      }}
    >
      {type === 'weekly' &&
        listDayOfWeek.map((el: any) => (
          <div key={el.id} style={{ display: 'flex', width: '100%' }}>
            <Typography
              style={{
                width: '100px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 600,
                marginTop: '24px'
              }}
            >
              {el.title}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {formik.values[name][el.id] &&
                formik.values[name][el.id].map((item: any, index: any) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '8px 0px'
                    }}
                  >
                    <AutocompleteTimeOfDay
                      value={item}
                      keyField={index}
                      dayId={el.id}
                      formik={formik}
                      name={name}
                      type={'open'}
                      placeholder={placeholderStart || 'Đang mở cửa'}
                      label={labelStart || 'Giờ mở cửa'}
                      styleTextField={{ width: '160px' }}
                    />
                    <div style={{ margin: '0px 12px' }}>-</div>
                    <AutocompleteTimeOfDay
                      value={item}
                      keyField={index}
                      dayId={el.id}
                      formik={formik}
                      name={name}
                      type={'close'}
                      placeholder={placeholderEnd || 'Đang đóng cửa'}
                      label={labelEnd || 'Giờ đóng cửa'}
                      styleTextField={{ width: '160px' }}
                    />
                    {index !== formik.values[name][el.id].length - 1 ? (
                      <div style={{ display: 'flex' }}>
                        <IconButtonOptions
                          icon={
                            <i
                              className="fa-light fa-trash-can"
                              style={{
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#909090'
                              }}
                            ></i>
                          }
                          style={{
                            backgroundColor: '#ececec',
                            height: '100%',
                            marginLeft: '18px'
                          }}
                          action={() => {
                            let currentField = cloneDeep(formik.values[name]);
                            formik.setFieldValue(name, {
                              ...currentField,
                              [el.id]: currentField[el.id].filter(
                                (el: any, key: any) => key !== index
                              )
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ display: 'flex', height: '100%' }}>
                        {(formik.values[name][el.id].length > 1 ||
                          (formik.values[name][el.id].length === 1 &&
                            formik.values[name][el.id].find(
                              (element: any) => element.open && element.close
                            ))) && (
                          <IconButtonOptions
                            icon={
                              <i
                                className="fa-light fa-trash-can"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  color: '#909090'
                                }}
                              ></i>
                            }
                            style={{
                              backgroundColor: '#ececec',
                              height: '100%',
                              margin: '0px -12px 0px 18px'
                            }}
                            action={() => {
                              let currentField = cloneDeep(formik.values[name]);
                              if (formik.values[name][el.id].length === 1) {
                                formik.setFieldValue(name, {
                                  ...currentField,
                                  [el.id]: [{ open: null, close: null }]
                                });
                              } else {
                                formik.setFieldValue(name, {
                                  ...currentField,
                                  [el.id]: currentField[el.id].filter(
                                    (el: any, key: any) => key !== index
                                  )
                                });
                              }
                            }}
                          />
                        )}
                        <IconButtonOptions
                          icon={
                            <i
                              className="fa-light fa-plus"
                              style={{
                                fontSize: '14px',
                                fontWeight: 700,
                                color: '#417fe7'
                              }}
                            ></i>
                          }
                          style={{
                            backgroundColor: '#e7f3ff',
                            height: '100%',
                            marginLeft: '18px'
                          }}
                          action={() => {
                            let currentField = cloneDeep(formik.values[name]);
                            formik.setFieldValue(name, {
                              ...currentField,
                              [el.id]: currentField[el.id].concat({
                                open: null,
                                close: null
                              })
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default TimeRangeOfDay;
