import React from 'react';import { Checkbox, Typography, Box } from '@mui/material';

const OptionVote = props => {
  const { title, config, action, setConfig, valueDefault, valueOption } = props;
  const [multiple, setMultiple] = React.useState<string>(valueDefault);

  const handleCheck = () => {
    setMultiple(prev => (prev === valueDefault ? valueOption : valueDefault));
  };

  React.useEffect(() => {
    if (multiple === valueOption) {
      setConfig(prev => ({ ...prev, [action]: valueOption }));
    } else {
      setConfig(prev => ({ ...prev, [action]: valueDefault }));
    }
  }, [multiple]);

  return (
    <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Checkbox
        icon={<i className="fa-light fa-circle"></i>}
        checkedIcon={<i className="fa-solid fa-circle-check"></i>}
        checked={multiple === valueOption}
        onClick={handleCheck}
        sx={{
          fontSize: '24px',
          '&:hover': { background: 'none' }
        }}
      />
      <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
        {title}
      </Typography>
    </Box>
  );
};
export default OptionVote;
