import * as types from 'src/constants/store/friends';export const getListFriendsReq = (userId: string, params: object) => ({
  type: types.GET_LIST_FRIEND_REQ,
  payload: { userId, params }
});

export const getListFriendsSuccess = (listFriends: object[]) => {
  return {
    type: types.GET_LIST_FRIEND_SUCCESS,
    payload: {
      listFriends
    }
  };
};

export const getListStatusFriendsSuccess = (listStatus: object[]) => ({
  type: types.GET_LIST_STATUS_SUCCESS,
  payload: {
    listStatus
  }
});

export const updateStatusFriend = (username: string, status: string) => ({
  type: types.UPDATE_STATUS_FRIEND,
  payload: {
    username,
    status
  }
});

export const getFriendOneSuccess = (friend: object) => ({
  type: types.GET_FRIEND_ONE_SUCCESS,
  payload: {
    friend
  }
});
export const getListFriendsChatReq = (userId: string, ids: object) => ({
  type: types.GET_FRIEND_CHAT_REQUEST,
  payload: { userId, ids }
});

export const getListFriendsChatSuccess = (listFriends: any) => ({
  type: types.GET_FRIEND_CHAT_SUCCESS,
  payload: { listFriends }
});
