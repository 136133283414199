import React from 'react';import AboutUsLeft from './AboutUsLeft';
import AboutUsCenter from './AboutUsCenter';
import { Grid, Box, useMediaQuery } from '@mui/material';
import Particles from 'react-particles';
import ParticlesStyle from 'src/components/Particles/Particles-config';
import { loadFull } from 'tsparticles';
import { createStyles, makeStyles } from '@mui/styles';
import imageDrums from 'src/assets/images/trongdong.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      background:
        'linear-gradient( to right,rgba(244,120,47,0.9),rgba(226,70,40,0.9))'
    },
    boxBodyLeft: {
      width: '140px',
      height: '100%',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      zIndex: 100
    },
    boxBodyLeftIphone: {
      width: '100%',
      height: '60px',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 100
    },
    boxBodyCenter: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: '140px'
    },

    '@keyframes animationItem': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },

    animationCss: {
      animation: '$animationItem 100s linear infinite'
    },
    round: {
      width: '800px',
      height: '800px',
      position: 'fixed',
      left: 0,
      top: '50%',
      transform: 'translate(-34%,-50%)',
      zIndex: 1
    }
  })
);

const AboutUs = () => {
  const classes = useStyles();
  const screenIpad = useMediaQuery('(min-width:900px)');
  const screenIphone = useMediaQuery('(min-width:400px)');
  const particlesInit = React.useCallback(async engine => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = React.useCallback(async container => {
    await container;
  }, []);

  return (
    <Grid className={classes.root}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={ParticlesStyle}
      />
      <Box
        className={
          screenIphone ? classes.boxBodyLeft : classes.boxBodyLeftIphone
        }
        sx={{ width: screenIphone ? (screenIpad ? '140px' : '100px') : '100%' }}
      >
        <AboutUsLeft />
      </Box>
      <Box
        className={classes.boxBodyCenter}
        sx={{ padding: screenIphone ? '0 0 0 140px' : '30px' }}
      >
        <AboutUsCenter />
      </Box>
      <Box
        className={classes.round}
        sx={{
          transform: screenIphone
            ? screenIpad
              ? 'translate(-34%,-50%)'
              : 'translate(-37%,-50%)'
            : 'translate(0,0)'
        }}
      >
        <img
          className={classes.animationCss}
          src={imageDrums}
          alt="logo"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%'
          }}
        />
      </Box>
    </Grid>
  );
};

export default AboutUs;
