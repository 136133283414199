export const WATCH_SELECTED = 'WATCH_SELECTED';
export const RESET_WATCH_SELECTED = 'RESET_WATCH_SELECTED';
export const TIME_PLAY_SELECTED = 'TIME_PLAY_SELECTED';
export const RESET_TIME_PLAY_SELECTED = 'RESET_TIME_PLAY_SELECTED';
export const WATCH_SAVE_TIME_RUN = 'WATCH_SAVE_TIME_RUN';
export const VOLUME_PLAY_SELECTED = 'VOLUME_PLAY_SELECTED';
export const POST_SELECTED = 'POST_SELECTED';
export const WATCH_SEARCH = 'WATCH_SEARCH';
export const DATA_ACTIVITY = 'DATA_ACTIVITY';
export const SEARCH_MOMENT = 'SEARCH_MOMENT';
export const RESET_MOMENT_SEARCH = 'RESET_MOMENT_SEARCH';
export const SHOW_MINI_PLAYER = 'SHOW_MINI_PLAYER';
export const CHANGE_REACTION = 'CHANGE_REACTION';
