import { Typography, Button, TextField } from '@mui/material';import React from 'react';
import VideoJs from 'src/components/VideoPlayer/VideoJs';
const Test = () => {
  const [src, setSrc] = React.useState('');
  var ref: any = React.useRef();
  return (
    <div>
      <div>
        <VideoJs src={src} isLiveStream={true} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'center',
          marginTop: 50
        }}
      >
        <Typography> Nhập link để phát:</Typography> &nbsp;
        <TextField style={{ width: '50vh' }} inputRef={ref}></TextField> &nbsp;
        <Button onClick={() => setSrc(ref?.current?.value)}>Phát</Button>
      </div>
    </div>
  );
};

export default Test;
