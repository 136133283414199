import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Theme,
  Box,
  Typography,
  IconButton,
  LinearProgress
} from '@mui/material';

import { collapseString } from 'src/common/string';
import ThumbFileVideo from './ThumbFileVideo';

const useStyles = makeStyles((theme: Theme) => ({
  thumbsContainer: {
    paddingLeft: 2,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  thumb: {
    borderRadius: 2,
    width: '100%',
    maxHeight: 200,
    padding: '4px 0 4px 4px',
    boxSizing: 'border-box',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  thumbImage: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    borderRadius: '10px',
    position: 'relative'
  },
  img: {
    width: 'auto',
    height: '100%',
    borderRadius: '10px 5px 10px 10px'
  },
  thumbFileRoot: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.mode === 'dark' ? '#3a3b3c' : '#D6D9DE',
    borderRadius: '10px',
    padding: '5px',
    alignItems: 'center',
    marginRight: '5px !important',
    position: 'relative'
  },
  thumbFileLeft: {
    width: '32px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbFileRight: {
    width: 'calc(100% - 32px)',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  thumbFileLeftIcon: {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#e4e6e8'
  },
  iconClose: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? 'unset' : '#f1f2f5',
    borderRadius: '50%',
    padding: 1,
    height: 20,
    width: 20,
    border: '2px solid rgba(0,0,0,0.1)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

function ThumbListFile(props) {
  const { files, handleRemoveFile, sending, type } = props;
  const classes = useStyles();
  const theme = useTheme();
  const removeFile = file => {
    handleRemoveFile(file);
  };
  return (
    <div className={classes.thumbsContainer}>
      <Box
        className={classes.thumb}
        sx={
          sending === 'sending'
            ? {
                border: 'none',
                maxHeight: '200px',
                display: 'flex',
                justifyContent: 'end'
              }
            : {
                '::-webkit-scrollbar': {
                  height: 10
                },

                /* Track */
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1'
                },

                '::-webkit-scrollbar-thumb': {
                  background: '#bdbdbd',
                  borderRadius: 10
                },
                border: '1px solid #eaeaea',
                height: '60px',
                display: 'inline-flex'
              }
        }
      >
        {files.map((file: any, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  borderRadius: '10px !important',
                  marginLeft: '10px',
                  marginBottom: '10px'
                }}
              >
                {file.type.includes('image') ? (
                  <div className={classes.thumbImage}>
                    {sending !== 'sending' ? (
                      <img
                        src={file.preview}
                        className={classes.img}
                        alt="img"
                      />
                    ) : (
                      <img
                        src={file.preview}
                        className={classes.img}
                        style={
                          type === 'fullScreen'
                            ? {
                                maxHeight: '200px'
                              }
                            : {
                                maxHeight: '160px'
                              }
                        }
                        alt="img"
                      />
                    )}
                    {sending !== 'sending' && (
                      <div
                        className={classes.iconClose}
                        style={{
                          right: '-2px',
                          top: '-5px',
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#242526'
                              : '#E4E6E7'
                        }}
                        onClick={() => {
                          removeFile(file);
                        }}
                      >
                        <i
                          style={{ fontSize: 12 }}
                          className="fa-solid fa-xmark"
                        ></i>
                      </div>
                    )}
                  </div>
                ) : file.type.includes('video/mp4') ? (
                  <div className={classes.thumbImage}>
                    {sending !== 'sending' ? (
                      <ThumbFileVideo
                        file={file}
                        classesWrapIcon={classes.wrapIcon}
                        classesImg={classes.img}
                      />
                    ) : (
                      <video
                        className={classes.img}
                        style={
                          type === 'fullScreen'
                            ? {
                                maxHeight: '200px'
                              }
                            : {
                                maxHeight: '160px'
                              }
                        }
                      >
                        {' '}
                        <source src={file.preview} />{' '}
                      </video>
                    )}
                    {sending !== 'sending' && (
                      <div
                        className={classes.iconClose}
                        style={{
                          right: '-2px',
                          top: '-5px',
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#242526'
                              : '#E4E6E7'
                        }}
                        onClick={() => {
                          removeFile(file);
                        }}
                      >
                        <i
                          style={{ fontSize: 12 }}
                          className="fa-solid fa-xmark"
                        ></i>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={classes.thumbFileRoot}>
                    <IconButton
                      disabled
                      sx={{
                        color: '#000',
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#242526' : '#E4E6E7'
                      }}
                    >
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-file-lines"
                      ></i>
                    </IconButton>
                    <div className={classes.thumbFileRight}>
                      <Typography fontWeight="600" fontSize="14px">
                        {collapseString(file.name, 10)}
                      </Typography>
                    </div>
                    {sending !== 'sending' && (
                      <div
                        className={classes.iconClose}
                        style={{
                          right: '-10px',
                          top: '-5px'
                        }}
                        onClick={() => {
                          removeFile(file);
                        }}
                      >
                        <i
                          style={{ fontSize: 12 }}
                          className="fa-solid fa-xmark"
                        ></i>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </Box>
    </div>
  );
}

export default ThumbListFile;
