import React from 'react';const DialogEditRelationshipUser = props => {
  const {
    renderForm,
    type,
    partner,
    renderContent,
    openEdit,
    userSelected,
    text
  } = props;

  return (
    <>
      {openEdit === true && partner?.partner?.id === userSelected?.partner?.id
        ? renderForm(type, partner)
        : renderContent(1, partner, text)}
    </>
  );
};

export default DialogEditRelationshipUser;
