import * as types from 'src/constants/store/socialPost';
const _ = require('lodash');

const initialState = {
  isLoading: false,
  collectionSaved: []
};

export const socialSavedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_BOOKMARK_COLLECTION_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, collectionSaved: newState };
    }

    default:
      return state;
  }
};
