const _ = require('lodash');
const initialState = {
  conversationIds: []
};

export const notiChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_NOTI_CONVERSATION_ID_CHAT': {
      let newState = _.cloneDeep(state);
      let index = newState.conversationIds.findIndex(
        item => item === action.conversationId
      );
      if (index < 0 && !action.isSeen) {
        newState.conversationIds.push(action.conversationId);
      } else if (index > -1 && action.isSeen) {
        newState.conversationIds.splice(index, 1);
      }
      return newState;
    }

    default:
      return state;
  }
};
