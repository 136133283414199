import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonInherit from 'src/components/Button/ButtonInherit';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

interface DialogVerifyPageAndUserProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  productOwner: any;
}

export default function DialogVerifyPageAndUser(
  props: DialogVerifyPageAndUserProps
) {
  const { open, setOpen, productOwner } = props;

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
          {'Xác minh danh tính'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography fontWeight={500} gutterBottom>
            Để bán hàng trên EMSO Shop bạn vui lòng xác minh danh tính các thông
            xin sau.
          </Typography>
          <Typography>- Xác minh giấy tờ cá nhân.</Typography>
          <Typography>- Xác minh Doanh nghiệp.</Typography>
          <Typography>- Xác minh địa chỉ.</Typography>
        </DialogContent>
        <DialogActions sx={{ p: '16px !important' }}>
          <ButtonInherit
            label="Xác nhận"
            action={() => {
              history.push(
                `/marketplace/shop/welcome_market?page_id=${productOwner?.page_owner_id}`
              );
              handleClose();
            }}
            style={{
              width: '120px',
              marginRight: '14px'
            }}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
