export const COURSE_PREVIEW_INFO = 'COURSE_PREVIEW_INFO';
export const GET_COURSE_DETAIL_SUCCESS = 'GET_COURSE_DETAIL_SUCCESS';
export const GET_COURSE_DETAIL_ERROR = 'GET_COURSE_DETAIL_ERROR';
export const GET_COURSE_DETAIL_REQUEST = 'GET_COURSE_DETAIL_REQUEST';
export const UPDATE_COURSES_RELATIONSHIP = 'UPDATE_COURSES_RELATIONSHIP';

export const RESET_COURSE = 'RESET_COURSE';
export const LOADING_COURSE = 'LOADING_COURSE';
export const UPDATE_SHARE_COUNT_COURSE = 'UPDATE_SHARE_COUNT_COURSE';
export const CHECK_EMPTY_LAYOUT_COURSE = 'CHECK_EMPTY_LAYOUT_COURSE';
