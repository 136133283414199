import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonTime from 'src/components/Button/ButtonTime';
import InputTextField from 'src/components/Input/SocialInput/InputTextField';
import {
  buttonColor,
  buttonColorHover,
  modalBackdrop,
  scrollStyle
} from 'src/constants/styles';
import { useHistory } from 'react-router-dom';
import { checkEmailUniqueApi, registrationAccountApi } from 'src/apis/user.api';
import { genderOptions } from 'src/constants/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    overflow: 'auto',
    padding: '0px !important'
  },
  '& .MuiPaper-root': {
    width: 480,
    overflow: 'hidden'
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  open: boolean;
  handleClose?: any;
}

export default function CustomizedDialogs(props: Props) {
  const { open, handleClose } = props;
  const history = useHistory();

  const [objectError, setObjectError] = React.useState({
    isError: false,
    message: '',
    type: ''
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().required('Email không được để trống'),
    password: Yup.string()
      .matches(/^\S*$/, 'Không được chứa khoảng trắng')
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống'),
    display_name: Yup.string().required('Họ tên không được để trống'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Mật khẩu xác nhận phải giống mật khẩu')
      .matches(/^\S*$/, 'Không được chứa khoảng trắng')
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống')
  });

  const handleCreateAccount = async data => {
    try {
      let response = await registrationAccountApi(data);
      if (response.status === 200) {
        handleClose();
        setIsLoading(false);
        history.push('/login?type=registration');
        let dataRegistration = () => ({
          email: data.email
        });

        localStorage.setItem(
          'emailRegistration',
          JSON.stringify(dataRegistration)
        );
      }
    } catch (error: any) {
      setIsLoading(false);
      setObjectError({
        isError: true,
        message:
          error.response.data.error ===
          'Bạn chưa đủ tuổi để đăng ký mạng xã hội EMSO, Vui lòng đề nghị người giám hộ của bạn đăng ký tài khoản'
            ? 'Bạn chưa đủ 14 tuổi để đăng ký mạng xã hội EMSO, Vui lòng đề nghị người giám hộ của bạn đăng ký tài khoản'
            : error.response.data.error,
        type: 'error'
      });
    }
  };

  const handleCheckEmailUnique = async () => {
    let params = {
      email: formik.values.email
    };
    try {
      let response = await checkEmailUniqueApi(params);
      if (response.status === 200) {
        setObjectError({
          isError: false,
          type: '',
          message: ''
        });
      }
    } catch (error) {
      setObjectError({
        isError: true,
        type: 'email',
        message: 'Email đã tồn tại, vui lòng sử dụng email khác.'
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      display_name: '',
      username: '',
      email: '',
      password: '',
      phone_number: '',
      password_confirmation: '',
      birth_date: '',
      birth_month: '',
      birth_year: '',
      gender: 'female',
      agreement: 1
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (values.password !== values.password_confirmation) {
        setObjectError({
          isError: true,
          message: 'Xác nhận mật khẩu không đúng, vui lòng kiểm tra lại',
          type: 'password'
        });
      } else if (values.password.length <= 8) {
        setObjectError({
          isError: true,
          message: 'Mật khẩu phải tối thiểu 9 kí tự',
          type: 'password'
        });
      } else {
        setIsLoading(true);
        handleCreateAccount({
          ...values,
          username: values.email.split('@')[0]
        });
      }
    }
  });

  const textStyle = {
    color: 'rgba(0,0,0,0.6)',
    marginBottom: 5,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: ' 1.4375em',
    letterSpacing: '0.00938em'
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('gender', (event.target as HTMLInputElement).value);
  };

  const handleSetTime = time => {
    formik.setFieldValue('birth_date', time.date);
    formik.setFieldValue('birth_month', time.month);
    formik.setFieldValue('birth_year', time.year);
  };

  const renderAlert = () => {
    return (
      objectError.isError && (
        <Grid item xs={12}>
          <Alert sx={{ width: '100% !important' }} severity="error">
            <Typography style={{ fontSize: 15, fontStyle: 'italic' }}>
              {objectError.message}
            </Typography>
          </Alert>
        </Grid>
      )
    );
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      BackdropProps={modalBackdrop}
    >
      {isLoading ? (
        <div
          style={{
            height: '100%',
            width: 480,
            position: 'absolute',
            zIndex: 2000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgba(255,255,255,0.6)'
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : null}
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          Đăng ký tài khoản
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent>
        <div style={{ padding: '20px 12px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputTextField
                formik={formik}
                id="name"
                label="Họ và tên"
                autoFocus
                size="small"
                required
                field="display_name"
              />
            </Grid>
            <Grid item xs={12}>
              <InputTextField
                formik={formik}
                id="email"
                label="Email"
                size="small"
                required
                field="email"
                onBlur={() => handleCheckEmailUnique()}
              />
            </Grid>

            {objectError.type === 'email' && renderAlert()}

            <Grid item xs={12}>
              <InputTextField
                formik={formik}
                id="phone_number"
                label="Số điện thoại"
                size="small"
                field="phone_number"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <InputTextField
                formik={formik}
                id="password"
                label="Mật khẩu"
                size="small"
                required
                field="password"
                type="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={6}>
              <InputTextField
                formik={formik}
                id="password_confirmation"
                label="Xác nhận mật khẩu"
                size="small"
                required
                field="password_confirmation"
                type="password"
              />
            </Grid>

            {objectError.type === 'password' && renderAlert()}

            <Grid item xs={12}>
              <Typography style={textStyle}>Sinh nhật</Typography>

              <ButtonTime
                action={time => handleSetTime(time)}
                styleButton={{
                  backgroundColor: buttonColor.backgroundColor,
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: buttonColorHover.backgroundColor
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography style={textStyle}>
                Giới tính
                <RadioGroup
                  value={formik.values.gender}
                  onChange={handleChange}
                >
                  <Grid container spacing={2}>
                    {genderOptions?.map((el: any, index) => (
                      <Grid key={index} item xs={4}>
                        <div
                          style={{
                            borderRadius: 8,
                            border: '1px solid rgba(0,0,0,0.25)',
                            height: 40,
                            paddingLeft: 12,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <FormControlLabel
                            key={el.id}
                            value={el.id}
                            control={
                              <Radio
                                sx={{
                                  '&.Mui-checked': {
                                    color: buttonColor.backgroundColor
                                  }
                                }}
                              />
                            }
                            label={el.label}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </Typography>
            </Grid>
            {objectError.type === 'error' && renderAlert()}
            <Grid item xs={12}>
              <Typography style={{ fontSize: 12, color: '#65676b' }}>
                Bằng cách nhấp vào Đăng ký, bạn đồng ý với Điều khoản, Chính
                sách dữ liệu và Chính sách cookie của chúng tôi. Bạn có thể nhận
                được thông báo của chúng tôi và hủy nhận bất kỳ lúc nào.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <ButtonInherit
          action={() => formik.submitForm()}
          solid={true}
          style={{
            backgroundColor: buttonColor.backgroundColor,
            color: 'white'
          }}
          label={'Đăng ký'}
        />
      </DialogActions>
    </BootstrapDialog>
  );
}
