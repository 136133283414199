import React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import PreviewFile from 'src/pages/Chat/BoxChat/DetailMessage/PreviewFile';

import { collapseString } from 'src/common/string';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '206px',
    maxHeight: '60px',
    display: 'flex',
    backgroundColor: '#e4e6eb',
    padding: '5px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  rightIcon: {
    width: '44px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#babdc3'
  }
}));

function DetailMessageFile(props) {
  const {
    message,
    borderBottomMessage,
    borderTopMessage,
    showTimeSend,
    nextMessageShowTimeSend,
    renderPostReactEmojis,
    conversation,
    mainTopic,
    getBorderRadius,
    theme
  } = props;
  const _classes = useStyles();
  const [openPreview, setOpenPreview] = React.useState(false);

  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <div
        id={message?._id}
        className={_classes.root}
        style={{
          background: theme.palette.mode === 'light' ? '#F0F0F0' : '#303030',
          color: theme.palette.mode === 'light' ? '#050505' : '#fff',
          position: 'relative',
          justifyContent:
            message.u?._id === rocketId ? 'flex-end' : 'flex-start',
          margin: '5px 0px',
          borderRadius:
            getBorderRadius &&
            getBorderRadius(
              conversation,
              message,
              rocketId,
              borderTopMessage,
              borderBottomMessage,
              nextMessageShowTimeSend,
              showTimeSend
            )
        }}
        onClick={() => setOpenPreview(true)}
      >
        <div className={_classes.content}>
          <div className={_classes.rightIcon}>
            <div className={_classes.icon}>
              <i
                style={{ fontSize: '16px' }}
                className="fa-solid fa-file-lines"
              />
            </div>
          </div>
          <div
            style={{
              width: 'calc(100% - 20px)',
              height: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography
              fontWeight="600"
              fontSize="14px"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word'
              }}
            >
              {collapseString(message.attachments[0].title, 25)}
            </Typography>
          </div>
        </div>
        {message.reactions ? renderPostReactEmojis() : null}
      </div>

      {openPreview ? (
        <PreviewFile
          open={openPreview}
          handleClose={handleClosePreview}
          file={message}
          type="file"
          conversation={conversation}
        />
      ) : null}
    </>
  );
}

export default DetailMessageFile;
