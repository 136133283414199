import React from 'react';
const SocialCutImage = props => {
  let { item, image, alt, zoom, style, heightImage, widthImage, styleIcon } =
    props;
  return (
    <div
      style={{
        height: '39px',
        width: '39px',
        display: 'flex',
        position: 'relative',
        zoom: zoom ?? 1,
        ...style
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '36px',
          width: '39px',
          overflow: 'hidden',
          ...styleIcon
        }}
      >
        <img
          alt={alt ?? 'images'}
          style={{
            width: widthImage ?? '55px',
            height: heightImage ?? '700px',
            transform: `translate(${item?.positionX ?? 0}px, ${
              item?.positionY ?? 0
            }px)`
          }}
          src={image}
        ></img>
      </div>
    </div>
  );
};

export default SocialCutImage;
