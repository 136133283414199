import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from 'src/constants/store/friends';
import { getFriendUser } from 'src/apis/socialUser';
import {
  getListFriendsChatSuccess,
  getListFriendsSuccess
} from 'src/store/action/socialFriendsAction';

const typesFriends: any = types;

export const getListFriendSaga = function* ({ payload }) {
  try {
    const { userId, params } = payload;
    const response = yield call(getFriendUser, userId, params, null);

    if (response.status === 200) {
      yield put(getListFriendsSuccess(response.data));
    }
  } catch (err: any) {
    console.log(err);
  }
};

export const getFriendChatSaga = function* ({ payload }) {
  try {
    const { userId, ids } = payload;
    const response = yield call(getFriendUser, userId, ids, null);

    if (response.status === 200) {
      yield put(getListFriendsChatSuccess(response.data));
    }
  } catch (err: any) {
    console.log(err);
  }
};

export function* watchSocialFriendAsync() {
  yield takeEvery(typesFriends.GET_LIST_FRIEND_REQ, getListFriendSaga);
  yield takeEvery(typesFriends.GET_FRIEND_CHAT_REQUEST, getFriendChatSaga);
}
