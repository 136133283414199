import * as types from 'src/constants/store/growSocial';
export const growPreview = (data: Object) => ({
  type: types.GROW_PREVIEW_INFO,
  payload: {
    data
  }
});

export const getGrowDetailSuccess = (data: any) => ({
  type: types.GET_GROW_DETAIL_SUCCESS,
  payload: {
    data
  }
});
export const updateMoneyDonate = (data: any) => ({
  type: types.GET_GROW_DONATE,
  payload: {
    data
  }
});

export const getGrowDetailError = (data: any) => ({
  type: types.GET_GROW_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getGrowDetailRequest = (idGrow: any) => ({
  type: types.GET_GROW_DETAIL_REQUEST,
  payload: {
    idGrow
  }
});

export const resetGrow = () => ({
  type: types.RESET_GROW
});

export const loadingGrow = (value: any) => ({
  type: types.LOADING_GROW,
  payload: { value }
});
export const updateGrow = (data: any) => ({
  type: types.UPDATE_FOLLOW_PROJECT,
  payload: { data }
});
export const updateGrowStatusCount = () => ({
  type: types.UPDATE_STATUS_COUNT_PROJECT,
  payload: {}
});
export const updateGrowShareCount = () => ({
  type: types.UPDATE_SHARE_COUNT_PROJECT,
  payload: {}
});
export const CheckEmptyLayoutGrow = (isCheck: any) => ({
  type: types.CHECK_EMPTY_PROJECT,
  payload: { isCheck }
});
