import { Box, Chip, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import ListButton from 'src/components/List/ListButton';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      height: '100%',
      maxWidth: '310px',
      backgroundColor: theme.palette.background.primary,
      position: 'fixed',
      boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
    },
    rootPage: {
      marginRight: '10px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '&:hover': {
        overflowY: 'auto'
      }
    },
    icon: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#474849' : '#e4e6eb',
      cursor: 'pointer'
    },
    body: {
      borderBottom:
        theme.palette.mode === 'dark'
          ? '1px solid #3e4042'
          : '1px solid #ced0d4',
      padding: '4px'
    },
    TextBodyTop: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    chip: {
      padding: '0 12px',
      fontSize: '15px !important',
      color: theme.palette.mode === 'dark' ? '#e4e6ea' : '#1d1f23',
      cursor: 'pointer !important',
      '&:hover': {
        backgroundColor: '#ccc'
      }
    },
    boxEvent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 0px '
    }
  })
);

const BloodDonationLeft = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [openDialogLocation, setOpenDialogLocation] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const listMenuBloodDonation = [
    {
      id: 'bloodDonation',
      title: 'Trang chủ',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-droplet"
          style={{
            fontSize: '20px',
            color: 'white'
          }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.BLOOD_DONATION);
      },
      style: {
        height: 36,
        width: 36,
        backgroundColor: buttonColor.backgroundColor
      },
      position: 'top'
    }
  ];

  const ListAddressBloodDonation = [
    // {
    //   id: 1,
    //   title: 'Trung tâm Truyền Máu, Bệnh Viện Hữu Nghị Việt Đức',
    //   avatar_social: avatar_default,
    //   styleAvatar_social: {
    //     minWidth: 48,
    //     height: 48,
    //     width: 48,
    //     backgroundColor: 'transparent',
    //     margin: ' 0px 10px 0px 0px'
    //   },
    //   action: () => {
    //     history.push(PATHS.BLOOD_DONATION);
    //   },
    //   position: 'bottom',
    //   expandInfo: 'Hà Nội',
    //   expandInfoToo: '490m .Mở cửa đến 4CH'
    // }
  ];

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.BLOOD_DONATION: {
        setSelectedIndex('bloodDonation');
        break;
      }
    }
  }, [match.path]);
  const renderHeader = () => {
    switch (match.path) {
      case PATHS.BLOOD_DONATION: {
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '336px',
                margin: ' 20px 10px 20px 20px',
                paddingLeft: '0px !important'
              }}
            >
              <Box>
                <Typography
                  style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                  variant="h5"
                >
                  Hiến máu
                </Typography>
              </Box>
            </Grid>
          </Grid>
        );
      }
    }
  };

  const renderBody = () => {
    switch (match.path) {
      case PATHS.BLOOD_DONATION: {
        return {
          bodyTop: (
            <Box className={classes.body} sx={{ padding: 0 }}>
              <ListButton
                item={listMenuBloodDonation.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'300px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                typeAction={'action'}
              />
            </Box>
          ),
          bodyCenter: (
            <Box className={classes.body}>
              <div
                className={classes.TextBodyTop}
                style={{ paddingBottom: '16px' }}
              >
                <div style={{ padding: '18px 6px 6px 6px', maxWidth: '280px' }}>
                  <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>
                    Hiến máu gần nơi bạn ở
                  </Typography>
                  <Typography sx={{ fontSize: '15px' }}>
                    Gọi cho trung tâm hiến máu để lên lịch hẹn hoặc đặt lời nhắc
                    cho dịp sau.
                  </Typography>
                </div>
              </div>
              <div
                className={classes.TextBodyTop}
                style={{ padding: '0 16px' }}
              >
                <Chip
                  className={classes.chip}
                  icon={
                    <i
                      className="fa-solid fa-location-dot"
                      style={{ fontSize: 16 }}
                    ></i>
                  }
                  label="Hà Nội"
                  onClick={() => setOpenDialogLocation(true)}
                />
              </div>
              <div style={{ padding: '8px' }}>
                <ListButton
                  item={ListAddressBloodDonation.filter(
                    el => el.position === 'bottom'
                  )}
                  width={'294px'}
                  styleAccordionDetail={{ padding: '3px' }}
                  stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                  styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                  expandInfoStyle={{ margin: 0 }}
                  typeAction={'action'}
                />
              </div>
            </Box>
          ),
          bodyFooter: (
            <Box className={classes.body}>
              <div
                className={classes.TextBodyTop}
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '6px'
                }}
              >
                <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>
                  Sự kiện hiến máu
                </Typography>
                <div style={{ padding: '8px 8px 20px 8px' }}>
                  <div className={classes.boxEvent}>
                    <Box
                      className={classes.icon}
                      sx={{
                        backgroundColor: '#f3425f',
                        color: '#fff',
                        cursor: 'default'
                      }}
                    >
                      <i
                        className="fa-solid fa-box-heart"
                        style={{ fontSize: 20 }}
                      ></i>
                    </Box>
                    <Box sx={{ paddingTop: '16px' }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '600',
                          opacity: '0.7'
                        }}
                      >
                        Tại đây, bạn sẽ nhìn thấy các sự kiện hiến máu có lịch
                        diễn ra ở gần mình.
                      </Typography>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          )
        };
      }
    }
  };

  return (
    <Box className={classes.root}>
      <div>{renderHeader()}</div>
      <Box className={classes.rootPage}>
        {renderBody()?.bodyTop}
        {renderBody()?.bodyCenter}
        {renderBody()?.bodyFooter}
      </Box>
      <DialogLocation
        open={openDialogLocation}
        handleClose={() => {
          setOpenDialogLocation(false);
        }}
        type={'bloodDonation'}
      />
    </Box>
  );
};

export default BloodDonationLeft;
