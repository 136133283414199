import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { modalBackdrop } from 'src/constants/styles';

import ButtonInherit from 'src/components/Button/ButtonInherit';

import rule from 'src/assets/images/group/rule.svg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 21 }}
            textAlign={'center'}
          >
            Thông tin thêm về nội dung này
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={rule}
              alt="question"
              style={{ width: '112px', height: ' 112px' }}
            />
            <p
              style={{
                margin: 20,
                color: '#65676b',
                fontSize: 20,
                fontWeight: 700
              }}
            >
              Hiện tại chưa có thông tin về nội dung này
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonInherit action={handleClose} label="Đóng" />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
