export const GROW_PREVIEW_INFO = 'GROW_PREVIEW_INFO';
export const GET_GROW_DETAIL_SUCCESS = 'GET_GROW_DETAIL_SUCCESS';
export const GET_GROW_DONATE = 'GET_GROW_DONATE';
export const GET_GROW_DETAIL_ERROR = 'GET_GROW_DETAIL_ERROR';
export const GET_GROW_DETAIL_REQUEST = 'GET_GROW_DETAIL_REQUEST';

export const RESET_GROW = 'RESET_GROW';
export const LOADING_GROW = 'LOADING_GROW';

export const UPDATE_FOLLOW_PROJECT = 'UPDATE_FOLLOW_PROJECT';
export const UPDATE_SHARE_COUNT_PROJECT = 'UPDATE_SHARE_COUNT_PROJECT';
export const UPDATE_STATUS_COUNT_PROJECT = 'UPDATE_STATUS_COUNT_PROJECT';
export const CHECK_EMPTY_PROJECT = 'CHECK_EMPTY_PROJECT';
