import { all } from 'redux-saga/effects';
import { watchLoginAsync } from './login';
import { watchGetSettingAsync } from './settingSaga';
import { watchUserAsync } from './userSaga';
import { watchGetPostSocialAsync } from './socialPostSaga';
import { watchGroupSocialAsync } from './socialGroupSaga';
import { watchUserSpaceAsync } from './socialUserSaga';
import { watchPageSocialAsync } from './socialPageSaga';
import { watchEventSocialAsync } from './socialEventSaga';
import { watchGrowSocialAsync } from './socialGrowSaga';
import { watchRecruitSocialAsync } from './socialRecruitSaga';
import { watchCourseSocialAsync } from './socialCourseSaga';
import {
  watchProductSocialAsync,
  watchOrderSocialAsync
} from './socialProductSaga';
import { watchSocialChatAsync } from './socialChatSaga';
import { watchSocialReportAsync } from './socialReport';
import { watchSocialFriendAsync } from './socialFriendsSaga';

export default function* rootSaga() {
  yield all([
    watchLoginAsync(),
    watchUserAsync(),
    watchGetSettingAsync(),
    watchGetPostSocialAsync(),
    watchGroupSocialAsync(),
    watchUserSpaceAsync(),
    watchPageSocialAsync(),
    watchEventSocialAsync(),
    watchGrowSocialAsync(),
    watchRecruitSocialAsync(),
    watchCourseSocialAsync(),
    watchProductSocialAsync(),
    watchOrderSocialAsync(),
    watchSocialChatAsync(),
    watchSocialReportAsync(),
    watchSocialFriendAsync()
  ]);
}
