import * as React from 'react';import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
    display: 'flex'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: 500,
    height: 490
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  backgroundPost: {
    height: 80,
    width: 80,
    '&:hover': {
      cursor: 'pointer'
    },
    borderRadius: 15,
    padding: 5
  },
  listCategory: {
    height: '500px !important',
    display: 'block !important'
  },
  listItemCategory: {
    padding: '10px 16px'
  },
  title: {
    fontSize: '18px !important',
    fontWeight: '500 !important',
    paddingLeft: '6px'
  }
}));

export default function CustomizedDialogs({
  open,
  handleClose,
  listBackground,
  setBackgroundSelected,
  handleAction
}) {
  const classes = useStyles();
  const listCategoryNames = listBackground?.map(
    (item: any) => item?.category_name
  );
  const uniqueSet = new Set(listCategoryNames);
  const newListCategoryNames = Array.from(uniqueSet);

  const renderListBackground = (title: any, index) => {
    return (
      <div key={index} className={classes.listItemCategory}>
        <Typography className={classes.title}>{title}</Typography>
        {listBackground?.map(
          (item: any, index) =>
            item?.category_name === title && (
              <img
                key={item.name}
                onClick={() => {
                  setBackgroundSelected(item);
                  handleClose();
                  handleAction('background');
                }}
                className={classes.backgroundPost}
                src={item.url}
                alt={item.name}
              />
            )
        )}
      </div>
    );
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        //TransitionComponent={Transition}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: '700', fontSize: 20 }}
            textAlign={'center'}
          >
            Chọn phông nền
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className={classes.listCategory}>
          {newListCategoryNames?.map((el: any, index) =>
            renderListBackground(el, index)
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
