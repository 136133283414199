import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getGifApi } from 'src/apis/socialPost.api';
import _ from 'lodash';
import Loading from 'src/components/Loading/Loading';

import { makeStyles } from '@mui/styles';
import { ClickAwayListener, Paper, Popper, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0px'
  },
  imgGif: {
    width: '300px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rootPaper: {
    width: 300,
    height: 360,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px !important',
    zIndex: 30
  },
  paper: {
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    width: '100%',
    overflow: 'hidden auto',
    height: 309
  },
  search: {
    padding: 8
  }
}));

export default function BasicPopover(props) {
  const {
    openPopper,
    setOpenPopper,
    anchorEl,
    setAnchorEl,
    setGifSelected,
    formik,
    setTypeFocus,
    inputRef,
    type
  } = props;

  let key = 'sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh';
  const classes = useStyles();

  const [keyword, setKeyword] = React.useState<any>('');
  const [listGif, setListGif] = React.useState<any>([]);
  const [offset, setOffset] = React.useState<any>(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setOpenPopper(false);
    setAnchorEl(null);
  };

  const fetchGifs = async params => {
    setIsLoading(true);
    let res = await getGifApi(params);
    if (res.status === 200) {
      setIsLoading(false);
      setListGif(prev => [...prev, ...res.data.data]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounce_fetch = React.useCallback(
  //   _.debounce(async function getGifByKeyword(keyword) {
  //     if (!keyword) {
  //       return;
  //     }
  //     setListGif([]);

  //     const res = await getGifApi({
  //       offset: 0,
  //       api_key: key,
  //       limit: 10,
  //       q: keyword
  //     });
  //     if (res.status === 200) {
  //       console.log('🚀 ~ getGifByKeyword ~ res.data.data:', res.data.data);
  //       setListGif(res.data.data);
  //       setIsLoading(false);
  //     }
  //   }, 800),
  //   []
  // );
  const debounce_fetch = React.useCallback(
    _.debounce(async function getGifByKeyword(keyword) {
      if (!keyword) {
        return;
      }
      setIsLoading(true);
      setListGif([]);

      const res = await getGifApi({
        offset: 0,
        api_key: key,
        limit: 10,
        q: keyword
      });
      if (res.status === 200) {
        setListGif(res.data.data);
        setIsLoading(false);
      }
    }, 800),
    []
  );
  React.useEffect(() => {
    if (!listGif.length && keyword.length < 1) {
      fetchGifs({
        offset: 0,
        api_key: key,
        limit: 10
      });
    }
  }, [listGif.length, key, keyword.length]);
  const handleKeywordChange = (newKeyword: any) => {
    setKeyword(newKeyword);
    if (newKeyword.length > 0) {
      debounce_fetch(newKeyword);
    } else {
      setListGif([]);
      fetchGifs({
        offset: 0,
        api_key: key,
        limit: 10
      });
    }
  };

  const funcLoad = () => {
    let params = {
      offset: offset,
      api_key: key,
      limit: 10,
      q: keyword
    };
    fetchGifs(params);
    setOffset(prev => prev + 1);
  };

  return (
    <div>
      <ClickAwayListener
        onClickAway={() => {
          handleClose();
        }}
      >
        <Popper
          anchorEl={anchorEl}
          key="popper"
          open={openPopper}
          placement={type === 'chat' ? 'top-start' : 'bottom-start'}
          disablePortal
          style={{
            padding: '5px 0px',
            zIndex: 1001
          }}
          id="scrollableDivGifPopup"
          className={classes.rootPaper}
        >
          <Paper key={'paper'}>
            <div className={classes.search}>
              <InputSearch
                keyword={keyword}
                setKeyword={setKeyword}
                handleKeywordChange={handleKeywordChange}
                label="Tìm kiếm"
              />
            </div>

            <div className={classes.paper}>
              {isLoading ? (
                <Loading />
              ) : listGif.length ? (
                <InfiniteScroll
                  dataLength={listGif.length}
                  next={funcLoad}
                  hasMore={true}
                  loader={<></>}
                  style={{ overflow: 'hidden' }}
                  scrollableTarget="scrollableDivGifPopup"
                  scrollThreshold={1}
                >
                  {listGif?.map((el: any, index) => (
                    <img
                      key={index}
                      onClick={() => {
                        if (formik) {
                          formik.setFieldValue(
                            'display_gift',
                            el?.images?.original?.url
                          );
                        }
                        setGifSelected(el?.images?.original?.url);
                        setTypeFocus && setTypeFocus('gif');
                        handleClose();
                        inputRef?.current?.focus();
                      }}
                      className={classes.imgGif}
                      alt="gif"
                      src={el?.images?.original?.url}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <div className={classes.wrapNoMedia}>
                  <Typography
                    style={{ color: '#65676b', fontSize: 15, fontWeight: 500 }}
                  >
                    Không tìm thấy file nào
                  </Typography>
                </div>
              )}
            </div>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
}
