import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

const AntTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff'
  }
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  fontWeight: 'bold',
  fontSize: '16px',
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',

    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));

const TabSocial = props => {
  const {
    value,
    handleChange,
    listTabs,
    style,
    styleTab,
    scrollButton,
    disabled
  } = props;

  return (
    <Box key={value}>
      <AntTabs
        scrollButtons={scrollButton ? scrollButton : 'auto'}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="ant example"
      >
        {listTabs?.map((el: any, index) => {
          return (
            <AntTab
              key={index}
              label={el.text}
              sx={value === el.id ? { ...styleTab, ...style } : styleTab}
              disabled={disabled}
            />
          );
        })}
      </AntTabs>
    </Box>
  );
};

export default TabSocial;
