import { Avatar, Button, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { buttonColor } from 'src/constants/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import {
  addFamilyRelationship,
  deleteFamilyRelationship,
  getAboutUser,
  getFriendUser,
  getRelationshipCategories,
  getRelationshipFamily,
  postUpdateRelationshipUser,
  updateFamilyUser
} from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonOption from 'src/components/Button/ButtonOption';
import DialogEditRelationshipUser from 'src/components/Dialog/DialogEditProfile/DialogEditRelationshipUser';
import MenuAction from 'src/components/Menu';
import Visibility from 'src/components/Visibility';
import {
  listVisibility,
  optionDate,
  optionMonth,
  optionYear
} from 'src/constants/common';
import { buttonStyle } from 'src/constants/styles';
import AutocompleteUser from './AutocomplateUser';
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: '500 !important',
    fontSize: '17px !important'
  },
  wrapContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 12,
    '& i': {
      fontSize: '20px !important'
    }
  },
  wrapText: {
    fontSize: '15px !important',
    color: theme.palette.mode === 'dark' ? '#b8b8b8' : '#65676b',
    fontWeight: '500 !important'
  },
  wrapContentIcon: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      color: theme.palette.mode === 'dark' ? '#b8b8b8' : '#65676b',
      width: 24
    }
  },
  wrapAddAction: {
    display: 'flex',
    color: buttonColor.backgroundColor,
    alignItems: 'center',
    padding: 10,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& i': {
      color: '#65676b'
    }
  },
  wrapSubText: {
    fontSize: '13px !important',
    color: '#65676b'
  }
}));

const FamilyRelationship = ({ aboutUser, setAboutUser }) => {
  const classes = useStyles();
  const [type, setType] = React.useState<any>('relationship');
  const [relationshipCategories, setRelationshipCategories] =
    React.useState<any>([]);
  const [visibility, setVisibility] = React.useState('public');
  const [familyCategories, setFamilyCategories] = React.useState<any>([]);
  const meInfo = useSelector((state: any) => state?.meReducer.info);
  const [friend, setFriend] = React.useState<any>([]);
  const [userSelected, setUserSelected] = React.useState<any>();
  const [dataUpdate, setDataUpdate] = React.useState<any>({
    family_relationship_category_id: null,
    relationship_category_id: null,
    visibility: 'public',
    partner_id: null,
    start_date: null,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    date: new Date().getDate()
  });
  const [isCU, setIsCU] = React.useState<any>({
    isNewRelationship: false,
    isNewFamily: false,
    isEditRelationship: false,
    isEditFamily: false
  });
  const [openEdit, setOpenEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const handleClickMenu = (event: any, partner: any, type = null) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
    if (type) {
      setType(type);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  async function fetchAboutUser(id) {
    let response = await getAboutUser(id, null);
    if (response.status === 200) {
      setAboutUser(response.data);
    }
  }
  const handleUpdateRelationship = async (params: any) => {
    let response = await postUpdateRelationshipUser(params, '', null);
    if (response.status === 200) {
      fetchAboutUser(meInfo?.id);
      setIsLoading(false);
    }
  };
  const handleUpdateFamily = async (params: any) => {
    let response = await addFamilyRelationship(params);
    if (response.status === 200) {
      fetchAboutUser(meInfo?.id);
      setIsLoading(false);
    }
  };
  const handleDeleteFamily = async (params: any) => {
    let response = await deleteFamilyRelationship(params);
    if (response.status === 200) fetchAboutUser(meInfo?.id);
  };

  const handlePatchFamily = async (params: any, id: any) => {
    let response = await updateFamilyUser(params, id);
    if (response.status === 200) {
      fetchAboutUser(meInfo?.id);

      setIsLoading(false);
    }
  };
  let listMoreAction = [
    {
      key: 'seeEditHistory',
      icon: 'fa-light fa-pen',
      label: 'Chỉnh sửa thành viên',
      action: () => {
        if (type === 'relationship') {
          handleClick('relationship', 'update');
        } else if (type === 'family') {
          handleClick('family', 'update');
        }
        handleCloseMenu();

        setDataUpdate(prev => ({
          ...prev,
          relationship_category_id: userSelected?.relationship_category?.id,
          family_relationship_category_id:
            userSelected?.family_relationship_category?.id,
          partner_id: userSelected?.partner?.id,
          visibility: userSelected?.visibility,
          year: new Date(userSelected?.start_date).getFullYear(),
          month: new Date(userSelected?.start_date).getMonth() + 1,
          date: new Date(userSelected?.start_date).getDate()
        }));
        setOpenEdit(true);
      }
    },
    {
      key: 'turnOnlNotification',
      icon: 'fa-light fa-trash-can',
      label: 'Xóa thành viên',
      action: () => {
        handleDeleteFamily(userSelected.id);
        handleCloseMenu();
      }
    }
  ];

  const handleClick = (type, subType, obj = null) => {
    let objectCU = { ...isCU };
    if (type === 'relationship' && subType === 'create') {
      objectCU.isNewRelationship = true;
    } else if (type === 'family' && subType === 'create') {
      objectCU.isNewFamily = true;
    } else if (type === 'relationship' && subType === 'update') {
      objectCU.isEditRelationship = true;
    } else if (type === 'family' && subType === 'update') {
      objectCU.isEditFamily = true;
    }

    setIsCU(objectCU);
  };

  const renderContent = (index, partner, type) => {
    return (
      <div className={classes.wrapContent}>
        <div className={classes.wrapContentIcon}>
          <Avatar src={partner?.partner?.avatar_media?.url} /> &nbsp;
          <div>
            <Typography className={classes.wrapText}>
              {partner?.partner?.display_name}
            </Typography>
            <Typography className={classes.wrapSubText}>
              {type === 'relationship'
                ? partner?.relationship_category?.name
                : partner?.family_relationship_category?.name}
              {''}
              {partner?.status === 'pending' ? '(Đang chờ)' : ''}
              {partner?.start_date
                ? ` đã bắt đầu từ ${partner?.start_date}`
                : ''}
            </Typography>
          </div>
        </div>
        <div
          onClick={e => {
            setType(type);
            handleClickMenu(e, type);
            setUserSelected(partner);
            setOpenEdit(false);
          }}
          className={classes.iconButton}
        >
          <i className="fa-solid fa-ellipsis"></i>
        </div>
      </div>
    );
  };

  const renderFamily = () => {
    if (aboutUser.family_members) {
      return aboutUser.family_members?.map((el: any, index) => {
        return (
          <React.Fragment key={index}>
            {/* {renderContent(index, el, 'family')} */}
            <DialogEditRelationshipUser
              renderContent={renderContent}
              renderForm={renderForm}
              type={type}
              setType={setType}
              partner={el}
              openEdit={openEdit}
              setOpenEdit={setOpenEdit}
              userSelected={userSelected}
              text={'family'}
              dataUpdate={dataUpdate}
            />
          </React.Fragment>
        );
      });
    }
  };

  const renderButtonAdd = type => {
    return (
      <div
        onClick={() => handleClick(type, 'create')}
        className={classes.wrapAddAction}
      >
        <i
          style={{
            borderRadius: '50%',
            border: `1px solid ${buttonColor.backgroundColor}`,
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className="fa-solid fa-plus"
        ></i>{' '}
        &nbsp;
        <Typography style={{ fontWeight: 500 }}>
          {type === 'relationship'
            ? 'Thêm mối quan hệ mới'
            : 'Thêm thành viên gia đình'}
        </Typography>
      </div>
    );
  };

  const renderButton = type => {
    return (
      <>
        <Divider />
        <div style={{ height: 10 }}></div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Visibility
            visibility={dataUpdate['visibility']}
            handleAction={item => {
              setDataUpdate(prev => ({
                ...prev,
                visibility: item
              }));
              setVisibility(item);
            }}
            listVisibility={listVisibility}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => {
                setDataUpdate({
                  relationship_category_id: null,
                  visibility: 'public',
                  partner_id: null,
                  start_date: null,
                  year: new Date().getFullYear(),
                  month: new Date().getMonth(),
                  date: new Date().getDate()
                });
                setIsCU(prev =>
                  type === 'relationship'
                    ? {
                        ...prev,
                        isNewRelationship: false,
                        isEditRelationship: false
                      }
                    : {
                        ...prev,
                        isNewFamily: false,
                        isEditFamily: false
                      }
                );
                setOpenEdit(false);
              }}
              style={{
                ...buttonStyle,
                textTransform: 'none',
                margin: '0px 0px 0px 5px'
              }}
            >
              Hủy
            </Button>
            <ButtonInherit
              label="Xong"
              action={() => {
                setIsLoading(true);
                if (
                  isCU.isNewFamily ||
                  isCU.isNewRelationship ||
                  isCU.isEditRelationship
                ) {
                  if (type === 'relationship') {
                    let params = {
                      relationship_category_id:
                        dataUpdate.relationship_category_id,
                      visibility: dataUpdate.visibility,
                      partner_id:
                        dataUpdate.relationship_category_id === 2
                          ? meInfo.id
                          : dataUpdate.partner_id,
                      start_date: new Date(
                        dataUpdate.year,
                        dataUpdate.month - 1,
                        dataUpdate.date + 1
                      )
                    };
                    handleUpdateRelationship(params);
                  } else if (type === 'family') {
                    let params = {
                      family_relationship_category_id:
                        dataUpdate.family_relationship_category_id,
                      visibility: dataUpdate.visibility,
                      partner_id: dataUpdate.partner_id
                    };

                    handleUpdateFamily(params);
                  }
                } else if (isCU.isEditFamily) {
                  if (type === 'family') {
                    let params = {
                      family_relationship_category_id:
                        dataUpdate.family_relationship_category_id,
                      visibility: dataUpdate.visibility,
                      partner_id: dataUpdate.partner_id
                    };
                    handlePatchFamily(params, userSelected.id);
                  }
                }
                setIsCU(prev =>
                  type === 'relationship'
                    ? {
                        ...prev,
                        isNewRelationship: false,
                        isEditRelationship: false
                      }
                    : {
                        ...prev,
                        isNewFamily: false,
                        isEditFamily: false
                      }
                );
                setDataUpdate({
                  relationship_category_id: null,
                  visibility: 'public',
                  partner_id: null,
                  start_date: null,
                  year: new Date().getFullYear(),
                  month: new Date().getMonth(),
                  date: new Date().getDate()
                });
                setOpenEdit(false);
              }}
              loading={isLoading ? true : false}
              color="button.custom.color  "
              style={{ margin: '0px 0px 0px 5px' }}
            />
          </div>
        </div>
        <div style={{ height: 10 }}></div>
      </>
    );
  };

  const renderForm = (type, partner = null) => {
    return (
      <div>
        {type === 'family' && (
          <div style={{ width: '100%' }}>
            <AutocompleteUser
              options={friend.map(option => ({
                id: option?.id,
                label: option.title,
                url: option.url
              }))}
              handleChange={(e, value) =>
                setDataUpdate(prev => ({
                  ...prev,
                  partner_id: value?.id
                }))
              }
              value={friend
                .map(option => ({
                  id: option?.id,
                  label: option.title,
                  url: option.url
                }))
                .find(option => option.id === dataUpdate.partner_id)}
            />

            <div style={{ height: 10 }}></div>
          </div>
        )}
        <ButtonOption
          label={
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography>Chọn mối quan hệ</Typography>
            </div>
          }
          type={'fullWidth'}
          buttonDisplay={
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography>
                {
                  (type === 'relationship'
                    ? relationshipCategories
                    : familyCategories
                  )?.find(
                    (el: any) =>
                      el.id ===
                      dataUpdate[
                        type === 'relationship'
                          ? 'relationship_category_id'
                          : 'family_relationship_category_id'
                      ]
                  )?.label
                }
              </Typography>
            </div>
          }
          options={
            type === 'relationship' ? relationshipCategories : familyCategories
          }
          optionSelected={(type === 'relationship'
            ? relationshipCategories
            : familyCategories
          )?.find(
            (el: any) =>
              el.id ===
              dataUpdate[
                type === 'relationship'
                  ? 'relationship_category_id'
                  : 'family_relationship_category_id'
              ]
          )}
          setOptionSelected={item => {
            setDataUpdate(prev =>
              type === 'relationship'
                ? {
                    ...prev,
                    relationship_category_id: item.id
                  }
                : {
                    ...prev,
                    family_relationship_category_id: item.id
                  }
            );
          }}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        />
        {relationshipCategories?.find(
          (el: any) => el.id === dataUpdate.relationship_category_id
        )?.has_partner &&
          type !== 'family' && (
            <>
              <div style={{ height: 10 }}></div>
              <AutocompleteUser
                options={friend.map(option => ({
                  id: option?.id,
                  label: option.title,
                  url: option.url
                }))}
                value={friend
                  .map(option => ({
                    id: option?.id,
                    label: option.title,
                    url: option.url
                  }))
                  .find(option => option.id === dataUpdate.partner_id)}
                handleChange={(e, value) =>
                  setDataUpdate(prev => ({
                    ...prev,
                    partner_id: value?.id
                  }))
                }
              />
            </>
          )}
        <div style={{ height: 10 }}></div>
        {type !== 'family' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonOption
              buttonDisplay={`${dataUpdate.year}`}
              options={optionYear}
              optionSelected={optionYear?.find(
                (el: any) => el?.id === +dataUpdate.year
              )}
              setOptionSelected={item => {
                setDataUpdate(prev => ({
                  ...prev,
                  year: item.id
                }));
              }}
            />
            <ButtonOption
              buttonDisplay={`Tháng ${dataUpdate.month}`}
              options={optionMonth}
              optionSelected={optionMonth?.find(
                (el: any) => el.id === +dataUpdate.month
              )}
              setOptionSelected={item => {
                setDataUpdate(prev => ({
                  ...prev,
                  month: item.id
                }));
              }}
            />
            <ButtonOption
              buttonDisplay={`${dataUpdate.date}`}
              options={optionDate}
              optionSelected={optionDate?.find(
                (el: any) => el.id === +dataUpdate.date
              )}
              setOptionSelected={item => {
                setDataUpdate(prev => ({
                  ...prev,
                  date: item.id
                }));
              }}
            />
          </div>
        )}
        <div style={{ height: 10 }}></div>
        {renderButton(type)}
      </div>
    );
  };
  const fetchFriend = async () => {
    let response = await getFriendUser(meInfo?.id, null, null);
    if (response.status === 200) {
      let array: any = [];
      response.data?.map((el: any) => {
        array.push({
          id: el.id,
          title: el.display_name,
          url: el?.avatar_media
        });
      });
      setFriend(array);
    }
  };
  const fetchCategories = async type => {
    let response;
    if (type === 'relationship') {
      response = await getRelationshipCategories();
    } else if (type === 'family') {
      response = await getRelationshipFamily();
    }

    if (response.status === 200) {
      if (type === 'relationship') {
        const data = response.data?.map((el: any) => ({
          ...el,
          label: el.name
        }));
        setRelationshipCategories([
          {
            id: null,
            name: 'Trạng thái',
            has_partner: false,
            label: 'Trạng thái'
          },
          ...data
        ]);
      } else if (type === 'family') {
        setFamilyCategories(
          response.data?.map((el: any) => ({
            ...el,
            label: el.name
          }))
        );
      }
    }
  };

  React.useEffect(() => {
    fetchCategories('relationship');
    fetchCategories('family');
    fetchFriend();
  }, []);
  return (
    <div style={{ padding: 10 }}>
      <Typography className={classes.title}>Mối quan hệ</Typography>
      {!isCU.isNewRelationship && !aboutUser?.account_relationship
        ? renderButtonAdd('relationship')
        : null}
      <div style={{ margin: '20px 10px 10px 10px' }}>
        {isCU.isNewRelationship ? renderForm('relationship') : null}
      </div>
      {aboutUser?.account_relationship ? (
        <DialogEditRelationshipUser
          renderContent={renderContent}
          renderForm={renderForm}
          type={type}
          setType={setType}
          partner={aboutUser?.account_relationship}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          userSelected={userSelected}
          text={'relationship'}
          dataUpdate={dataUpdate}
        />
      ) : null}

      <Typography className={classes.title}>Gia đình</Typography>
      {renderFamily()}
      {!isCU.isNewFamily ? renderButtonAdd('family') : null}
      <div style={{ margin: '20px 10px 10px 10px' }}>
        {isCU.isNewFamily ? renderForm('family') : null}
      </div>

      {anchorEl && (
        <MenuAction
          anchorEl={anchorEl}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
          handleCloseMenu={handleCloseMenu}
          listMenu={listMoreAction}
        />
      )}
    </div>
  );
};

export default FamilyRelationship;
