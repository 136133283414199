import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialCourseAction';
import * as types from 'src/constants/store/courseSocial';
import { getDetailCourseApi } from 'src/apis/socialCourse.api';
const typesSetting: any = types;

export const getCourseDetailSagas = function* ({ payload }) {
  try {
    yield put(actions.loadingCourse(true));
    const { idCourse } = payload;
    let response = yield call(getDetailCourseApi, idCourse);
    if (response.status === 200) {
      yield put(actions.loadingCourse(false));
      yield put(actions.getCourseDetailSuccess(response.data));
      yield put(actions.checkEmptyLayout(true));
    }
  } catch (error: any) {
    yield put(actions.loadingCourse(false));
    yield put(actions.getCourseDetailError(error.response));
    yield put(actions.checkEmptyLayout(false));
  }
};

export function* watchCourseSocialAsync() {
  yield takeEvery(typesSetting.GET_COURSE_DETAIL_REQUEST, getCourseDetailSagas);
}
