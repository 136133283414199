import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface ProjectInformation {
  infoUpdate: any;
  formik: any;
  setCheckStep: React.Dispatch<React.SetStateAction<any>> | any;
  privacyOptions: any;
  setNotificationLeave: React.Dispatch<React.SetStateAction<any>> | any;
}

const ProjectInformation: React.FC<ProjectInformation> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    setCheckStep,
    privacyOptions,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        Object.keys(formik.errors).length < 1 &&
        formik.values.risks &&
        formik.values.refund_policy &&
        formik.values.phone_number &&
        formik.values.visibility
      ) {
        setCheckStep(prev => ({ ...prev, step4: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step4: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'risks',
      label: 'Nguy cơ',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Mô tả nguy cơ nhà đầu tư có thể gặp phải',
      rows: 6,
      onChange: true,
      defaultValue: formik.values.risks
    },
    {
      field_type: 'textField',
      name: 'refund_policy',
      label: 'Chính sách hoàn trả',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Viết chính sách hoàn trả nhà đầu tư',
      rows: 6,
      onChange: true,
      defaultValue: formik.values.refund_policy
    },
    {
      field_type: 'textField',
      name: 'phone_number',
      placeholder: 'Nhập số điện thoại liên hệ',
      label: 'Số điện thoại liên hệ',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      maxRows: 1,
      defaultValue: formik.values.phone_number
    },
    {
      field_type: 'autocomplete',
      name: 'visibility',
      options: privacyOptions,
      multiple: false,
      placeholder: 'Quyền riêng tư',
      descriptionLabel: 'Bắt buộc*',
      label: 'Quyền riêng tư',
      disabled: true
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default ProjectInformation;
