import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';

interface SkeletonFriendItemProps {
  itemRenderList?: number;
  widthTextTop?: number;
  widthTextBottom?: number;
  widthAndHeightAvatar?: number;
  xsItem?: number;
}

const useStyles = makeStyles((theme: any) => ({
  divWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.primary
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '14px',
    padding: '8px 8px 8px 8px !important',
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    },
    '& .MuiCardContent-root': {
      height: '100%'
    },
    height: '100%',
    width: '100%'
  },
  wrapContentItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '120px'
  },
  secondaryText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  wrapPrice: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const SkeletonFriendItem: React.FC<SkeletonFriendItemProps> = props => {
  const {
    itemRenderList = 10,
    widthTextTop = 120,
    widthTextBottom = 80,
    widthAndHeightAvatar = 60,
    xsItem = 12
  } = props;
  return (
    <Grid container spacing={2}>
      {Array.from(new Array(itemRenderList)).map((item: any, index) => (
        <Grid key={index + 'skeleton'} item xs={xsItem}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 10px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}
            >
              <Skeleton
                variant="rectangular"
                sx={{
                  width: widthAndHeightAvatar,
                  height: widthAndHeightAvatar,
                  borderRadius: '8px'
                }}
              />
              <Box>
                <Skeleton variant="text" width={widthTextTop} height={25} />
                <Skeleton variant="text" width={widthTextBottom} height={25} />
              </Box>
            </Box>
            <Skeleton variant="text" width={40} height={25} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export const SkeletonPageLiked: React.FC = () => {
  return (
    <Grid container spacing={1} mt="2px">
      {Array.from(Array(2)).map((item, idx) => (
        <Grid key={idx} item xs={12} lg={6}>
          <Card sx={{ borderRadius: 4, pb: 1 }}>
            <CardHeader
              avatar={<Skeleton variant="circular" width={80} height={80} />}
              action={<Skeleton width={50} height={60} />}
              title={<Skeleton width={140} />}
              subheader={<Skeleton width={170} />}
            />
            <Skeleton width={'90%'} height={60} sx={{ m: '0 auto' }} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export const SkeletonItemMarket: React.FC = () => {
  const classes = useStyles();
  const itemWidth: any = React.useRef();
  return (
    <div className={classes.divWrap} ref={itemWidth}>
      <Box sx={{ width: '100%', height: itemWidth.current?.clientWidth }}>
        <Skeleton height={itemWidth.current?.clientWidth} width="100%" />
      </Box>

      <CardContent className={classes.cardContent}>
        <div className={classes.wrapContentItem}>
          <div className={classes.secondaryText}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </div>
          <Skeleton width="33%" />
          <Box className={classes.wrapPrice}>
            <Skeleton width="50%" />
            <Skeleton width="30%" />
          </Box>
        </div>
      </CardContent>
    </div>
  );
};
