import React from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Theme,
  Box,
  IconButton
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { urlify } from 'src/helpers/common';
import { collapseString } from 'src/common/string';
import imageDefault from 'src/assets/images/group_cover.png';
import parse from 'html-react-parser';

import DialogDetailLink from 'src/components/Dialog/DialogDetailLink';
import useWindowDimensions from 'src/hooks/useSizeScreen';

const CardPost = props => {
  const { post, type, typePreview, typeShare } = props;
  const { width } = useWindowDimensions();
  const useStyles = makeStyles((theme: Theme) => ({
    wrapLinkComment: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginTop: '10px'
    },
    wrapLinkChild: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(68, 73, 80, 0.15)'
      },
      width: post.in_reply_to_parent_id ? '81%' : '87%',
      height: 96,
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 20,
      marginRight: post.in_reply_to_parent_id ? 40 : 0,
      display: 'flex'
    },
    wrapGifComment: {
      display: 'flex !important',
      justifyContent:
        post?.card?.description === 'sticky'
          ? 'space-between !important'
          : 'start',
      padding: '10px 40px !important'
    },
    imageGif: {
      objectFit: 'cover'
    },
    wrapImage: {
      width: '120px',
      borderRadius: '20px 0px 0px 20px',
      height: 95,
      backgroundImage: `url(${
        post.card.type ? post.card.image : post.card.url
      })`,
      backgroundSize: 'cover'
    },
    wrapLinkContent: {
      width: 'calc(100% - 100px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    wrapContent: {
      height: 80,
      width: '90%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    textDomain: {
      fontSize: '14px !important',
      color: '#65676B',
      fontWeight: '500 !important'
    },
    text: { fontSize: 18 },
    information: {
      width: 28,
      height: 28,
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: '50%',
      position: 'absolute',
      top: '-5px',
      right: post.in_reply_to_parent_id ? '65px' : '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#595959' : '#fff',
      zIndex: 1000,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    rootCard: {
      width: '100% !important',
      padding: '0px !important'
    },
    icon: {
      width: 32,
      height: 32,
      display: 'flex'
    }
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState<any>(null);

  let content = post.card.link ? post.card.link : post.content;
  let stringDomain = '';

  let arrayContent = content?.split('/');

  if (arrayContent.length) {
    for (let i = 0; i <= arrayContent.length; i++) {
      if (arrayContent[i] === '') {
        stringDomain = arrayContent[i + 1];
      }
    }
  }

  React.useEffect(() => {
    if (
      post?.card?.provider_name === 'GIPHY' ||
      post?.card?.description === 'sticky'
    ) {
      setFiles(post?.card?.url || post?.card?.link);
    }
  }, [JSON.stringify(post)]);

  let listLink = urlify(content);
  const renderCard = () => {
    if (post?.card && Object.keys(post?.card)) {
      let extraBody = post.card;

      let imageUrl = extraBody.image
        ? extraBody.image
        : post.card?.url
        ? post.card?.url
        : '';
      let title = extraBody.title ?? '';
      let description = extraBody.description ?? '';
      let src = extraBody.link ?? extraBody.url;

      return type && type === 'comment' ? (
        post?.card?.provider_name === 'GIPHY' ||
        post?.card?.description === 'sticky' ? (
          files ? (
            <Box className={classes.wrapGifComment}>
              <div>
                <img
                  className={classes.imageGif}
                  style={
                    post?.card?.description === 'sticky'
                      ? { width: 80, height: 80 }
                      : { borderRadius: 15, width: '100%' }
                  }
                  src={files}
                  alt="gif"
                />
              </div>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  setFiles(null);
                }}
              >
                <i className="far fa-times"></i>
              </IconButton>
            </Box>
          ) : null
        ) : (
          <Box className={classes.wrapLinkComment}>
            <div onClick={() => setOpen(true)} className={classes.information}>
              <i style={{ fontStyle: 'italic' }} className="fas fa-info"></i>
            </div>
            <Box
              onClick={() => window.open(`${listLink[0]}`)}
              className={classes.wrapLinkChild}
              sx={{ backgroundColor: 'background.secondary' }}
            >
              <div className={classes.wrapImage}></div>
              <div className={classes.wrapLinkContent}>
                <div className={classes.wrapContent}>
                  <Typography className={classes.textDomain}>
                    {stringDomain?.toUpperCase()}
                  </Typography>
                  <Typography className={classes.text}>
                    {parse(
                      collapseString(
                        post.card.title,
                        typePreview === 'previewMedia' ? 50 : 100
                      )
                    )}
                  </Typography>
                </div>
              </div>
            </Box>
          </Box>
        )
      ) : (
        <div style={{ width: '100%' }}>
          <Card
            sx={{
              borderRight: 0,
              borderLeft: 0,
              width: '100%'
            }}
            variant="outlined"
            classes={{ root: classes.rootCard }}
          >
            <CardActionArea onClick={() => window.open(src)}>
              {imageUrl ? (
                <CardMedia
                  component="img"
                  style={{
                    height:
                      type === 'violate' && width <= 1600 ? '195px' : 'auto',
                    width: '100% !important',
                    objectFit: 'cover'
                  }}
                  image={imageUrl}
                  onError={(e: any) => (e.target.src = imageDefault)}
                  title="Contemplative Reptile"
                />
              ) : null}
              {typeShare !== 'share-messenger' && (
                <CardContent
                  sx={{
                    backgroundColor: 'background.secondary',
                    borderBottomLeftRadius: type === 'violate' ? '4px' : '0px',
                    borderBottomRightRadius: type === 'violate' ? '4px' : '0px'
                  }}
                >
                  <Typography
                    style={{ color: '#65676B', fontSize: 13 }}
                    // gutterBottom
                  >
                    {stringDomain?.toUpperCase()}
                  </Typography>
                  <Typography
                    style={{ fontWeight: 500, fontSize: 16 }}
                    // gutterBottom
                  >
                    {parse(title)}
                  </Typography>
                  {title.length >= 100 ? null : (
                    <Typography
                      style={{ fontSize: 15 }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {description.length <= 80
                        ? parse(description)
                        : parse(description.slice(0, 80)) + '...'}
                    </Typography>
                  )}
                </CardContent>
              )}
            </CardActionArea>
          </Card>
        </div>
      );
    }
  };

  return (
    <>
      {renderCard()}

      <DialogDetailLink open={open} setOpen={setOpen} />
    </>
  );
};

export default CardPost;
