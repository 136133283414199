import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Theme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { scrollStyle, modalBackdrop } from 'src/constants/styles';
import CloseIcon from '@mui/icons-material/Close';

import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'src/components/Loading/Loading';

import { getGifApi } from 'src/apis/socialPost.api';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0px'
  },
  imgGif: {
    width: '490px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    padding: '0px !important'
  },
  '& .MuiPaper-root': {
    width: 500,
    height: 700
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { open, handleClose, setGifSelected, setMenuSelected } = props;
  const classes = useStyles();
  let key = 'sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh';

  const [keyword, setKeyword] = React.useState<any>('');
  const [listGif, setListGif] = React.useState<any>([]);
  const [offset, setOffset] = React.useState<any>(1);
  const [isLoading, setIsLoading] = React.useState(true);
  async function getGifByKeyword(params) {
    let res = await getGifApi(params);
    if (res.status === 200) {
      setIsLoading(false);
      setListGif(res.data.data);
    }
  }
  // let debounce_fetch = _.debounce(function (text) {
  //   setKeyword(text);
  // }, 500);
  const debounceSearchChat = React.useCallback(
    _.debounce(async function (q) {
      setIsLoading(true);
      let params = {
        offset: 0,
        api_key: key,
        limit: 10,
        q: q
      };

      getGifByKeyword(params);
    }, 500),
    []
  );
  const fetchGifs = async params => {
    let res = await getGifApi(params);
    if (res.status === 200) {
      setIsLoading(false);
      setListGif(prev => [...prev, ...res.data.data]);
    }
  };

  React.useEffect(() => {
    if (!listGif.length) {
      fetchGifs({
        offset: 0,
        api_key: key,
        limit: 10
      });
    }
  }, []);

  React.useEffect(() => {
    if (keyword) {
      debounceSearchChat(keyword);
    }
  }, [keyword]);

  const funcLoad = () => {
    let params = {
      offset: offset,
      api_key: key,
      limit: 10,
      q: keyword
    };
    fetchGifs(params);
    setOffset(prev => prev + 1);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      BackdropProps={modalBackdrop}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography
          style={{ fontWeight: 'bold', fontSize: 20 }}
          textAlign={'center'}
        >
          Chọn file GIF
        </Typography>
      </BootstrapDialogTitle>
      <div
        style={{
          margin: '10px 14px 10px 14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <InputSearch
          keyword={keyword}
          setKeyword={setKeyword}
          label="Tìm kiếm"
        />
      </div>
      <DialogContent id="scrollableDivGif">
        {isLoading ? (
          <Loading />
        ) : listGif.length ? (
          <InfiniteScroll
            dataLength={listGif.length}
            next={funcLoad}
            hasMore={true}
            loader={<></>}
            style={{ overflow: 'hidden' }}
            scrollableTarget="scrollableDivGif"
            scrollThreshold={1}
          >
            {listGif?.map((el: any, index) => (
              <img
                key={index}
                onClick={() => {
                  setGifSelected(el?.images?.original?.url);
                  handleClose();
                  setMenuSelected(['gif']);
                }}
                className={classes.imgGif}
                alt="gif"
                src={el?.images?.original?.url}
                loading="lazy"
              />
            ))}
          </InfiniteScroll>
        ) : (
          <div className={classes.wrapNoMedia}>
            <Typography
              style={{ color: '#65676b', fontSize: 15, fontWeight: 500 }}
            >
              Không tìm thấy file nào
            </Typography>
          </div>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
