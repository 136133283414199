import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    open,
    handleClose,
    userBlock,
    colorButton,

    alignText
  } = props;
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={alignText ?? 'start'}
          >
            Xác nhận chặn
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography style={{ fontSize: 15 }}>
            Bạn đã chặn thành công
            <span style={{ fontWeight: 500, fontSize: 15 }}>{userBlock}</span>.
          </Typography>
          <Typography style={{ fontSize: 15 }}>
            Các bạn sẽ không thể nhìn thấy hoặc liên hệ với nhau.
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonInherit
            action={() => {
              handleClose();
            }}
            color={colorButton || buttonColor.backgroundColor}
            style={{ fontSize: '14px' }}
            label={'Tiếp tục'}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
