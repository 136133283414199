import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { scrollStyle, modalBackdrop } from 'src/constants/styles';

import { getReblogPostApi } from 'src/apis/socialPost.api';
import SocialPost from 'src/components/SocialPost';

import _ from 'lodash';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '8px 12px',
    ...scrollStyle
  },

  '& .MuiPaper-root': {
    width: '560px'
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { post, open, setOpen } = props;
  const [listPostShare, setListPostShare] = React.useState<any>([]);
  const [hasMore, setHasMore] = React.useState(true);

  const getListSharePost = async params => {
    let res = await getReblogPostApi(post.id, params);
    if (res.data.length) {
      setListPostShare(prev => _.uniqBy([...prev, ...res.data], 'id'));
    } else {
      setHasMore(false);
    }
  };

  const funcLoad = () => {
    let postLast = [...listPostShare].pop();
    let maxId = postLast?.id;

    getListSharePost({
      max_id: maxId
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!listPostShare.length) {
      getListSharePost({});
    }
  }, []);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: '700', fontSize: 20 }}
            textAlign={'center'}
          >
            Những người đã chia sẻ bài viết này
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent id="scrollableDiv" dividers>
          <div style={{ width: '100%', margin: '0px 0px 10px 0px' }}>
            <InfiniteScroll
              dataLength={listPostShare.length}
              next={funcLoad}
              hasMore={hasMore}
              loader={<></>}
              style={{ overflow: 'hidden' }}
              scrollableTarget="scrollableDiv"
              scrollThreshold={1}
            >
              {listPostShare.length
                ? listPostShare?.map((el: any) => (
                    <SocialPost type="sharePost" key={el.id} post={el} />
                  ))
                : null}
            </InfiniteScroll>
            <Typography
              style={{ fontSize: 13, color: '#65676B', marginTop: 10 }}
            >
              Một số bài viết có thể không xuất hiện ở đây do cài đặt quyền
              riêng tư
            </Typography>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
