import React from 'react';import { Snackbar, Alert } from '@mui/material';

const ToastMessage = props => {
  const { open, setOpen, statusToast, style, label } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // Time Display of toast (3 seconds)
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // position the toast
    >
      <Alert
        onClose={handleClose}
        severity={statusToast}
        sx={{ width: '100%', ...style }}
      >
        {label}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;
