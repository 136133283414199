import React from 'react';import { Box, Stack, Skeleton } from '@mui/material';

export default function SuspenseConversation() {
  return (
    <Stack
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        margin: '4px 10px'
      }}
    >
      <Skeleton variant="circular" width={60} height={50} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            fontSize: '20px',
            width: '200px',
            borderRadius: '50px'
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            fontSize: '16px',
            width: '250px',
            borderRadius: '50px'
          }}
        />
      </Box>
    </Stack>
  );
}
