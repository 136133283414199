import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ButtonCustom from 'src/components/Button/Button';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import BaseLink from 'src/components/Link/BaseLink';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { resetActivities } from 'src/store/action/socialPostAction';

import { getListPageLikesApi } from 'src/apis/socialPages.api';

import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { searchVideos } from 'src/apis/socialPost.api';
import Loading from 'src/components/Loading/Loading';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import { searchWatchVideos } from 'src/store/action/socialWatchAction';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 'calc(100% - 50px)',
    width: 360,
    position: 'fixed',
    top: 50,
    marginBottom: 20,
    boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)',
    backgroundColor: theme.palette.button.primary.background
  },
  rootTitle: {
    fontSize: '24px !important',
    fontWeight: '700 !important'
  },
  wrapHeader: {
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapInput: {
    padding: '8px 12px'
  },
  wrapMenu: {
    padding: '0px 12px'
  },
  list: {
    width: '340px'
  },
  selected: {
    backgroundColor: theme.palette.button.primary.background
  },
  textPrimary: {
    fontSize: '15px !important',

    fontWeight: '500 !important'
  },
  wrapBlock: {
    padding: '8px 12px'
  },
  wrapTitleBlock: { fontSize: '17px !important', fontWeight: '500 !important' },
  hashtag: {
    display: 'inline-block',
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: 20,
    padding: '2px 5px',
    margin: '5px 10px 0px 0px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapContent: {
    width: '100%',
    height: 'calc(100vh - 175px)',
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    },
    '&:hover&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bdbdbd',
      borderRadius: 10
    }
  },
  textSecondary: {
    fontSize: '13px !important',
    color: buttonColor.backgroundColor
  }
  // lineClamp: lineClamp(1)
}));

const LiveBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const match: any = useRouteMatch();
  const [selectedIndex, setSelectedIndex] = React.useState('live_home');
  const [listPageFollow, setListPageFollow] = React.useState<any>(null);
  const meSelected = useSelector((state: any) => state.meReducer.info);
  const listWatchSearch = useSelector(
    (state: any) => state.watchReducer.watchSearch
  );
  const [keyword, setKeyword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<any>(true);

  const listMenu = [
    {
      id: 'live_home',
      label: 'Trang chủ',
      action: () => {
        history.push(PATHS.LIVESTREAM_HOME);
      },
      icon: 'fa-sharp fa-solid fa-circle-play'
    },
    {
      id: 'live_follow',
      label: 'Đang theo dõi',
      action: () => {
        history.push(PATHS.LIVESTREAM_FOLLOW);
      },
      icon: 'fa-solid fa-tv-music'
    },
    {
      id: 'live_sell',
      label: 'Live bán hàng',
      action: () => {
        history.push(PATHS.LIVESTREAM_SELL);
      },
      icon: 'fa-solid fa-monitor-waveform'
    },
    {
      id: 'live_game',
      label: 'Live chơi game',
      action: () => {
        history.push(PATHS.LIVESTREAM_GAME);
      },
      icon: 'fa-solid fa-gamepad'
    }
  ];

  async function fetchListFollowPages() {
    let response = await getListPageLikesApi(meSelected?.id, { limit: 10 });

    if (response.status === 200) {
      setListPageFollow(response.data.data);
    }
  }

  const resetActivity = React.useCallback(
    () => dispatch(resetActivities()),
    [dispatch]
  );

  const handleSearch = React.useCallback(
    _.debounce(async keyword => {
      let response = await searchVideos(keyword, 'livestream');
      if (response.status === 200) {
        dispatch(searchWatchVideos(response.data.statuses, keyword, 'watch'));
        setIsLoading(false);
      }
    }, 800),
    []
  );

  React.useEffect(() => {
    if (meSelected?.id) {
      fetchListFollowPages();
    }
  }, [meSelected?.id]);

  React.useEffect(() => {
    if (keyword) {
      handleSearch(keyword);
    }
  }, [keyword]);

  const renderList = (list, type = null) => {
    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {list?.map((item: any, index) => (
          <BaseLink
            key={index}
            url={item?.url ? item?.url : null}
            component={
              <ListItem
                sx={{
                  borderRadius: '10px',
                  maxHeight: item.icon ? '58px' : '72px',
                  padding: '8px',
                  backgroundColor:
                    selectedIndex === item?.id ||
                    selectedIndex === item?.page?.id
                      ? 'button.secondary.background'
                      : 'transparent',
                  marginBottom: '10px'
                }}
                button
                onClick={() => {
                  if (type === 'page') {
                    history.push(`/page/${item?.id}`);
                  }
                  item.action && item.action();
                }}
                key={item?.id}
              >
                {item?.icon && (
                  <ListItemIcon sx={{ minWidth: 24 }}>
                    <i style={{}} className={`${item.icon} fa-lg`}></i>
                  </ListItemIcon>
                )}

                {!item?.icon && (
                  <AvatarSocial
                    type="feed"
                    avatarObj={item.avatar_media}
                    style={{ width: 40, minWidth: 40, height: 40 }}
                  />
                )}

                <ListItemText
                  style={{ margin: '0 15px 0 10px' }}
                  primary={
                    <Typography sx={{ fontWeight: '600' }}>
                      {item?.label ? item?.label : item?.title}
                    </Typography>
                  }
                  secondary={
                    type === 'page' &&
                    item.id !== 'watch-new' && (
                      <Typography className={classes.textSecondary}>
                        {<i className="fa-solid fa-circle-small"></i>}&nbsp; 9+
                        thông báo mới
                      </Typography>
                    )
                  }
                />
              </ListItem>
            }
          />
        ))}
      </List>
    );
  };

  const renderAccount = (title, listPageFollow, type) => {
    return (
      <div className={classes.wrapBlock}>
        <Typography className={classes.wrapTitleBlock}>{title}</Typography>

        {listPageFollow?.length ? (
          renderList(listPageFollow, 'page' as any)
        ) : (
          <Typography style={{ padding: '8px 12px' }}>
            Không có tài khoản nào
          </Typography>
        )}

        <div style={{ height: 5 }}></div>
      </div>
    );
  };

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.LIVESTREAM_HOME: {
        setSelectedIndex('live_home');
        break;
      }
      case PATHS.LIVESTREAM_FOLLOW: {
        setSelectedIndex('live_follow');
        break;
      }
      case PATHS.LIVESTREAM_SELL: {
        setSelectedIndex('live_sell');
        break;
      }
      case PATHS.LIVESTREAM_GAME: {
        setSelectedIndex('live_game');
        break;
      }
      case PATHS.LIVESTREAM_DETAIL: {
        setSelectedIndex('live_detail');
      }
    }
  }, [match.path]);

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <div className={classes.wrapHeader}>
        <Typography className={classes.rootTitle}>Video trực tiếp</Typography>
      </div>

      <div className={classes.wrapInput}>
        <InputSearch
          label="Tìm kiếm video"
          keyword={keyword}
          setKeyword={setKeyword}
          type={'video'}
        />
        <div style={{ height: 15 }}></div>
        <Divider />
      </div>
      {keyword.length < 1 ? (
        <div className={classes.wrapContent}>
          <div className={classes.wrapMenu}>
            {renderList(listMenu)}
            <ButtonCustom
              label="Bắt đầu phát trực tiếp"
              action={() => {
                history.push('/live');
                // dispatch(resetEvent());
              }}
              solid={true}
              style={{
                color: '#e7f3ff',
                backgroundColor: buttonColor.backgroundColor
              }}
              icon={<i className="far fa-plus"></i>}
            />
            <div style={{ height: 5 }}></div>
            <Divider />
          </div>

          {renderAccount(
            'Người phát trực tiếp bạn đang theo dõi',
            listPageFollow,
            'isShowFollow'
          )}
        </div>
      ) : (
        <>
          {isLoading ? (
            <Loading style={{ marginTop: '10px', marginLeft: '100px' }} />
          ) : listWatchSearch?.data?.length ? (
            listWatchSearch?.data?.map((el, index) => {
              return (
                <Box key={index} sx={{ width: '100%', height: 'auto' }}>
                  <Conversation type="video" conversation={el} key={index} />
                </Box>
              );
            })
          ) : (
            <div style={{ textAlign: 'center' }}>Không tìm thấy video.</div>
          )}
        </>
      )}
    </Box>
  );
};

export default LiveBar;
