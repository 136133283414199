import React, { useState, useEffect } from 'react';import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CheckConnectDevice = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return !isOnline ? (
    <Typography style={{ fontSize: '14px', opacity: 0.8, fontWeight: '600' }}>
      <i
        style={{ padding: '0 4px' }}
        className="fa-regular fa-circle-exclamation"
      ></i>
      Không có kết nối internet
    </Typography>
  ) : (
    <></>
  );
};

export default CheckConnectDevice;
