import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px 20px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface DescriptionProject {
  infoUpdate?: any;
  formik?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const DescriptionProject: React.FC<DescriptionProject> = props => {
  const classes = useStyles();
  const { infoUpdate, formik, setCheckStep, setNotificationLeave } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        formik.values.description_company &&
        formik.values.description_project &&
        formik.values.plan
      ) {
        setCheckStep(prev => ({ ...prev, step2: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step2: false }));
      }
    }
  }, [JSON.stringify(formik.values)]);

  let fieldUpdate: any = [
    {
      field_type: 'textField',
      name: 'description_company',
      label: 'Mô tả doanh nghiệp',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về doanh nghiệp để cộng đồng biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.description_company
    },
    {
      field_type: 'textField',
      name: 'description_project',
      label: 'Mô tả dự án',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 4,
      descriptionTitle:
        'Cung cấp thêm thông tin về dự án để cộng đồng biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.description_project
    },
    {
      field_type: 'textField',
      name: 'plan',
      label: 'Mô tả kế hoạch dự án',
      descriptionLabel: 'Bắt buộc*',
      placeholder: 'Thêm mô tả',
      rows: 6,
      descriptionTitle:
        'Cung cấp thêm thông tin về kế hoạch dự án gọi vốn để cộng đồng biết nên mong đợi điều gì.',
      onChange: true,
      defaultValue: formik.values.plan
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default DescriptionProject;
