import React from 'react';import { Box, Typography, Grid } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import ListButton from 'src/components/List/ListButton';
import { WebkitScrollbarRGB, buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      height: '100%',
      maxWidth: '310px',
      backgroundColor: theme.palette.background.primary,
      position: 'fixed',
      boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
    },
    rootPage: {
      marginRight: '10px',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      '&:hover': {
        overflowY: 'auto'
      },
      ...WebkitScrollbarRGB()
    },
    icon: {
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#474849' : '#e4e6eb',
      cursor: 'pointer'
    },
    body: {
      borderBottom:
        theme.palette.mode === 'dark'
          ? '1px solid #3e4042'
          : '1px solid #ced0d4',
      padding: '4px'
    },
    TextBodyTop: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    chip: {
      padding: '0 12px',
      fontSize: '15px !important',
      color: theme.palette.mode === 'dark' ? '#e4e6ea' : '#1d1f23',
      cursor: 'pointer !important',
      '&:hover': {
        backgroundColor: '#ccc'
      }
    }
  })
);

const CelebrateLeft = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const listMenuCelebrate = [
    {
      id: 'Celebrate',
      title: 'Trang chủ kỷ niệm',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-house"
          style={{
            fontSize: '20px',
            color: selectedIndex === 'Celebrate' ? 'white' : '#333'
          }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.CELEBRATE);
        setSelectedIndex('Celebrate');
      },
      style: {
        height: 36,
        width: 36,
        backgroundColor:
          selectedIndex === 'Celebrate'
            ? buttonColor.backgroundColor
            : '#e4e6eb'
      },
      position: 'top'
    }
  ];

  const ListActionCelebrate = [
    {
      id: 'notifi',
      title: 'Thông báo',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-solid fa-bell"
          style={{
            fontSize: '20px',
            color: selectedIndex === 'notifi' ? 'white' : '#333'
          }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.CELEBRATENOTIFI);
        setSelectedIndex('notifi');
      },
      style: {
        height: 36,
        width: 36,
        backgroundColor:
          selectedIndex === 'notifi' ? buttonColor.backgroundColor : '#e4e6eb'
      },
      position: 'top'
    },
    {
      id: 'hide',
      title: 'Ẩn mọi người',
      styleTitle: { fontWeight: '600' },
      expandInfo: '0 đã ẩn',
      icon: (
        <i
          className="fa-sharp fa-solid fa-users"
          style={{
            fontSize: '20px',
            color: selectedIndex === 'hide' ? 'white' : '#333'
          }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.CELEBRATEHIDE);
        setSelectedIndex('hide');
      },
      style: {
        height: 36,
        width: 36,
        backgroundColor:
          selectedIndex === 'hide' ? buttonColor.backgroundColor : '#e4e6eb'
      },
      position: 'top'
    }
  ];

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.CELEBRATE: {
        setSelectedIndex('Celebrate');
        break;
      }
      case PATHS.CELEBRATEHIDE: {
        setSelectedIndex('hide');
        break;
      }
      case PATHS.CELEBRATENOTIFI: {
        setSelectedIndex('notifi');
        break;
      }
    }
  }, [match.path]);
  const renderHeader = () => {
    switch (match.path) {
      case PATHS.CELEBRATE:
      case PATHS.CELEBRATEHIDE:
      case PATHS.CELEBRATENOTIFI: {
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '336px',
                margin: ' 20px 10px 20px 20px',
                paddingLeft: '0px !important'
              }}
            >
              <Box>
                <Typography
                  style={{ margin: '8px 14px 8px 3px', fontWeight: 'bold' }}
                  variant="h5"
                >
                  Kỷ niệm
                </Typography>
              </Box>
            </Grid>
          </Grid>
        );
      }
    }
  };

  const renderBody = () => {
    switch (match.path) {
      case PATHS.CELEBRATE:
      case PATHS.CELEBRATEHIDE:
      case PATHS.CELEBRATENOTIFI: {
        return {
          bodyTop: (
            <Box className={classes.body} sx={{ padding: 0 }}>
              <ListButton
                item={listMenuCelebrate.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'300px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                typeAction={'action'}
              />
            </Box>
          ),
          bodyCenter: (
            <Box className={classes.body} sx={{ padding: 0 }}>
              <div style={{ padding: '18px 6px 6px 6px', maxWidth: '280px' }}>
                <Typography sx={{ fontSize: '17px', fontWeight: '600' }}>
                  Cài đặt
                </Typography>
              </div>
              <ListButton
                item={ListActionCelebrate.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'300px'}
                styleAccordionDetail={{ padding: '3px' }}
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleMargin={{ margin: '0px 5px 0px 0px !important' }}
                typeAction={'action'}
              />
            </Box>
          ),
          bodyFooter: null
        };
      }
    }
  };

  return (
    <Box className={classes.root}>
      <div>{renderHeader()}</div>
      <Box className={classes.rootPage}>
        {renderBody()?.bodyTop}
        {renderBody()?.bodyCenter}
        {renderBody()?.bodyFooter}
      </Box>
    </Box>
  );
};
export default CelebrateLeft;
