import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import InputTextField from 'src/components/Input/SocialInput/InputTextField';

import { useHistory } from 'react-router-dom';
import { changePasswordApi } from 'src/apis/user.api';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';

import { useFormik } from 'formik';
import { buttonColor } from 'src/constants/styles';
import * as Yup from 'yup';

const FormSubmitReset = ({ tokenReset, setTokenReset }) => {
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [disableChange, setDisableChange] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleChangePassword = async data => {
    try {
      let response = await changePasswordApi(data);

      if (response.status === 200) {
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Bạn đã đổi mật khẩu thành công, vui lòng đăng nhập lại.'
        });
        setIsLoading(false);
        setTimeout(() => {
          setTokenReset('');
          history.push('/login?type=reset');
        }, 1000);
      }
    } catch (error: any) {
      setOpenSnackbar(true);
      setNoti({
        code: error.response.status,
        message: error.response.data.error
      });
    }
  };

  const validationSchema = Yup.object({
    new_password: Yup.string()
      .matches(
        /^[^\s!@#$%^&*()_+={}\[\]:;<>,.?~\\/]+$/,
        'Mật khẩu không được chứa ký tự đặc biệt'
      )
      .matches(/[a-zA-Z]/, 'Mật khẩu phải bao gồm ít nhất ký tự chữ')
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống'),
    new_password_confirmation: Yup.string()
      .matches(
        /^[^\s!@#$%^&*()_+={}\[\]:;<>,.?~\\/]+$/,
        'Mật không được chứa ký tự đặc biệt'
      )
      .matches(/[a-zA-Z]/, 'Mật khẩu phải bao gồm ít nhất ký tự chữ')
      .oneOf(
        [Yup.ref('new_password'), null],
        'Xác nhận mật khẩu không đúng, vui lòng kiểm tra lại.'
      )
      .min(9, 'Mật khẩu phải tối thiểu 9 kí tự.')
      .required('Mật khẩu không được để trống')
  });

  const formik = useFormik({
    initialValues: {
      reset_password_token: tokenReset,
      new_password: '',
      new_password_confirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleChangePassword(values);
    }
  });

  React.useEffect(() => {
    if (
      formik.values.new_password &&
      formik.values.new_password_confirmation &&
      !Object.keys(formik.errors).length
    ) {
      setDisableChange(false);
    } else {
      setDisableChange(true);
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: 17, fontWeight: 500 }}>
            Hoàn thiện bước cuối cùng để đăng nhập tài khoản của bạn.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: 15,
              fontWeight: 500,
              color: buttonColor.backgroundColor
            }}
          >
            Cập nhật mật khẩu mới
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <InputTextField
            formik={formik}
            id="new_password"
            label="Nhập mật khẩu mới"
            required
            field="new_password"
            type="password"
            autoFocus
            onBlur={() => {
              formik.setFieldTouched('new_password', false);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <InputTextField
            formik={formik}
            id="new_password"
            label="Xác nhận mật khẩu mới"
            required
            field="new_password_confirmation"
            type="password"
            onBlur={() => {
              formik.setFieldTouched('new_password_confirmation', false);
            }}
          />
        </Grid>

        <Grid container justifyContent="flex-end" item xs={12}>
          <ButtonInherit
            action={() => {
              formik.submitForm();
              setIsLoading(true);
            }}
            style={{
              marginRight: 0,
              backgroundColor: buttonColor.backgroundColor,
              color: '#fff'
            }}
            label="Cập nhật mật khẩu"
            disabled={disableChange}
            loading={isLoading}
          />
        </Grid>
      </Grid>

      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </>
  );
};

export default FormSubmitReset;
