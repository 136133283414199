import React from 'react';import _ from 'lodash';
import {
  Typography,
  List,
  Box,
  IconButton,
  Theme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, createStyles, styled, useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';

import { getListGroupsChat } from 'src/apis/socialChat.apis';
import {
  createInviteGroup,
  getGroupAccountApi,
  searchGroupAccountApi
} from 'src/apis/socialGroup';

import { urlRocketChat } from 'src/util/config';

import Loading from 'src/components/Loading/Loading';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import InviteMembersSocialChat from 'src/pages/Chat/BoxChat/InviteMemberSocialChatComponent';
import Button from 'src/components/Button/Button';
import ListButton from 'src/components/List/ListButton';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogInviteMembersSocialChat = (props: any) => {
  const { open, handleClose, group } = props;
  const [keyword, setKeyword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [listGroup, setListGroup] = React.useState<any>('');
  const [value, setValue] = React.useState(0);
  const [listMembersGroup, setListMembersGroup] = React.useState<any>([]);
  const [chooseItem, setChooseItem] = React.useState<any>([]);
  const [accountsSearch, setAccountsSearch] = React.useState<any>([]);
  const [includeFriends, setIncludesFriends] = React.useState<any>([]);
  const [excludeFriends, setExcludesFriends] = React.useState<any>([]);
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const theme: any = useTheme();

  const handleInvite = async () => {
    let listIdInviteChoose: any = chooseItem.map((el: any) => el.id);

    if (listIdInviteChoose) {
      let res;
      if (group?.id) {
        res = await createInviteGroup(group?.id, {
          target_account_ids: listIdInviteChoose
        });
      }
      if (res.status === 200) {
        setChooseItem([]);
        setAccountsSearch([]);
      } else {
      }
      setValue(2);
    }
  };

  async function getListGroupChat() {
    let response = await getListGroupsChat(5);
    if (response.status === 200) {
      setListGroup(response.data);
    }
  }

  React.useEffect(() => {
    getListGroupChat();
  }, []);

  const getMemberGroup = async (id, params) => {
    let response;
    response = await getGroupAccountApi(id, params);
    if (response.status === 200) {
      return response.data;
    }
  };

  const getMembersGroup = async () => {
    const responseFriend = await getMemberGroup(group.id, {
      role: 'member',
      include_friend: true
    });
    let dataIncludeFriend = responseFriend.map(item => ({
      id: item?.account?.id,
      title: item?.account?.display_name,
      username: item?.account?.acct,
      avatar_icon: item?.account?.avatar_media?.show_url,
      checkbox: true,
      styleAvatar: { height: 40, width: 40 },
      styleImgAvatar: { height: 39, width: 39 }
    }));
    setIncludesFriends(dataIncludeFriend);

    const responseMember = await getMemberGroup(group?.id, {
      role: 'member',
      exclude_friend: true
    });

    let dataExcludeFriend = responseMember.map(item => ({
      id: item?.account?.id,
      title: item?.account?.display_name,
      username: item?.account?.acct,
      avatar_icon: item?.account?.avatar_media?.show_url,
      checkbox: true,
      styleAvatar: { height: 40, width: 40 },
      styleImgAvatar: { height: 39, width: 39 }
    }));
    setExcludesFriends(dataExcludeFriend);
    if (responseFriend && responseMember) {
      setListMembersGroup([...responseFriend, ...responseMember]);
    }
  };

  React.useEffect(() => {
    if (group) {
      getMembersGroup();
    }
  }, [group?.id]);

  let listMembersSocialGroup: any =
    listMembersGroup.map(item => ({
      id: item?.account?.id,
      title: item?.account?.display_name,
      username: item?.account?.acct,
      avatar_icon: item?.account?.avatar_media?.show_url,
      checkbox: true,
      styleAvatar: { height: 40, width: 40 },
      styleImgAvatar: { height: 39, width: 39 }
    })) || [];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      buttonGroup: {
        '& .css-1daoff1-MuiButtonBase-root-MuiButton-root:hover': {
          backgroundColor: '#cccccc36 !important'
        },
        width: '100%',
        justifyContent: 'flex-start !important',
        color: '#333 !important',
        marginTop: '20px !important',
        padding: '12px 15px !important',
        borderRadius: '10px !important',
        fontSize: '17px !important'
      },
      sendGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      sendFriend: {
        minWidth: '30px !important',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? `${buttonColor.backgroundColor} !important`
            : '#E7F3FF !important',
        color:
          theme.palette.mode === 'dark'
            ? '#00b2ff !important'
            : `${buttonColor.backgroundColor} !important`,
        fontWeight: '500 !important'
      },
      textBold: {
        fontWeight: '500 !important'
      },
      textFriend: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        marginLeft: '15px !important',
        marginBottom: '5px !important'
      },
      title: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        textAlign: 'center'
      },
      dialogContent: {
        height: '100%',
        maxHeight: '548px',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
          borderRadius: '100px'
        }
      }
    })
  );
  const classes = useStyles();

  const debounceSearchGlobal = React.useCallback(
    _.debounce(async function (q) {
      let response = await searchGroupAccountApi(group.id, {
        q
      });
      if (response.status === 200) {
        setIsLoading(false);
        let data = response.data.map(item => ({
          id: item?.id,
          title: item?.display_name,
          username: item?.acct,
          avatar_icon: item?.avatar_media?.show_url,
          checkbox: true,
          styleAvatar: { height: 40, width: 40 },
          styleImgAvatar: { height: 39, width: 39 }
        }));
        setAccountsSearch(data);
      }
    }, 800),
    []
  );

  React.useEffect(() => {
    if (keyword) {
      debounceSearchGlobal(keyword);
    } else {
      setAccountsSearch([]);
    }
  }, [keyword]);

  const SuggestInviteMembers = props => {
    const { members, titleSuggest, descriptionSuggest } = props;
    const avatarTop = `${urlRocketChat}/avatar/${members?.[0].username}`;
    const avatarBottom = `${urlRocketChat}/avatar/${members?.[1].username}`;
    return (
      <Accordion style={{ background: 'transparent' }}>
        <AccordionSummary
          expandIcon={<i className="fa-solid fa-chevron-down"></i>}
          aria-controls={titleSuggest}
          id={descriptionSuggest}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              margin: '8px 0',
              alignItems: ' center',
              cursor: 'pointer'
            }}
          >
            <Box style={{ position: 'relative' }}>
              <AvatarSocial
                avatarObj={avatarTop}
                style={{
                  width: '28px',
                  height: '28px',
                  zIndex: 1
                }}
                isAvatarGroupChat={'yes'}
              />
              <AvatarSocial
                type="feed"
                avatarObj={avatarBottom}
                style={{
                  width: '28px',
                  height: '28px',
                  position: 'absolute',
                  right: '-5px',
                  bottom: '0px',
                  top: '-5px',
                  zIndex: 0
                }}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                paddingLeft: '16px'
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: '16px' }}>
                {titleSuggest}
              </Typography>
              <Typography style={{ fontSize: '12px', opacity: 0.9 }}>
                {descriptionSuggest}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <ListButton
            item={members}
            chooseItem={chooseItem}
            setChooseItem={setChooseItem}
            typeAction={'action'}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography className={classes.title}>Mời chat</Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <InputSearch
          label="Tìm kiếm người và nhóm"
          keyword={keyword}
          setKeyword={setKeyword}
          // type={'share-group'}
          styleInput={{
            backgroundColor: ' #ccc3',
            borderRadius: '30px'
          }}
        />
        {keyword?.length < 1 ? (
          <div>
            <List>
              {listMembersGroup && listMembersGroup?.length ? (
                <>
                  <Typography className={classes.textFriend}>Gợi ý</Typography>
                  {/* <ListButton
                    item={listMembersSocialGroup}
                    setChooseItem={setChooseItem}
                    chooseItem={chooseItem}
                  /> */}
                  {listMembersSocialGroup && listMembersGroup.length > 1 ? (
                    <SuggestInviteMembers
                      members={listMembersSocialGroup}
                      titleSuggest={'Tất cả thành viên'}
                      descriptionSuggest={'Mọi người trong cộng đồng'}
                    />
                  ) : null}
                  {includeFriends && includeFriends.length > 1 ? (
                    <SuggestInviteMembers
                      members={includeFriends}
                      titleSuggest={'Bạn bè trên Emso'}
                      descriptionSuggest={'Thành viên là bạn bè của bạn'}
                    />
                  ) : null}
                </>
              ) : null}
            </List>
            <List>
              {listMembersGroup && listMembersGroup?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Thành viên cộng đồng
                  </Typography>
                  <ListButton
                    item={listMembersSocialGroup}
                    setChooseItem={setChooseItem}
                    chooseItem={chooseItem}
                    typeAction={'action'}
                  />
                </>
              ) : null}
            </List>

            <List>
              {friends?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Người liên hệ
                  </Typography>
                  {friends?.map((item: any, index) => (
                    <InviteMembersSocialChat item={item} key={item?._id} />
                  ))}
                </>
              ) : null}
            </List>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Loading style={{ marginTop: '20px' }} />
              </Box>
            ) : accountsSearch ? (
              <ListButton
                item={accountsSearch}
                setChooseItem={setChooseItem}
                chooseItem={chooseItem}
                typeAction={'action'}
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                Không tìm thấy cuộc trò chuyện.
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box style={{ display: 'flex', gap: 8 }}>
          <Button
            style={{
              minWidth: '30px !important',
              backgroundColor: '#E7F3FF !important',
              color: `${buttonColor.backgroundColor} !important`,
              fontWeight: '500 !important',
              padding: '8px 16px'
            }}
            label={'Hủy'}
          />
          <Button
            variant="contained"
            style={{
              minWidth: '30px !important',
              backgroundColor: `${buttonColor.backgroundColor} !important`,

              '&:hover': {
                backgroundColor: `${buttonColorHover.backgroundColor} !important`
              },
              color: '#fff !important',
              fontWeight: '500 !important',
              padding: '8px 16px',
              cursor: 'pointer'
            }}
            label={'Gửi'}
          />
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DialogInviteMembersSocialChat;
