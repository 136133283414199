/* eslint-disable jsx-a11y/alt-text */import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import 'src/components/ReactEmoji/Emoji.css';
export default function CircularIndeterminate(props) {
  const { color, style, size } = props;
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',

        ...style
      }}
    >
      <CircularProgress
        size={size ? size : 32}
        color={color ? color : 'inherit'}
      />
      <div className="emoji-like" style={{ height: 0, width: 0 }}></div>
      <div className="emoji-love" style={{ height: 0, width: 0 }}></div>
      <div className="emoji-yay" style={{ height: 0, width: 0 }}></div>
      <div className="emoji-haha" style={{ height: 0, width: 0 }}></div>
      <div className="emoji-wow" style={{ height: 0, width: 0 }}></div>
      <div className="emoji-angry" style={{ height: 0, width: 0 }}></div>
      <img
        style={{ height: '0px' }}
        src={
          'https://s3-hn-2.cloud.cmctelecom.vn/sn-web/uploads/menu/IconMenu.png'
        }
      ></img>
    </Box>
  );
}
