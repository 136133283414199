import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import { genderSubject, privacyOptions } from 'src/constants/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface CourseMedia {
  infoUpdate: any;
  formik: any;
  hostTeacherAdd: any;
  fetchHostTeacher: any;
  setNotificationLeave: React.Dispatch<React.SetStateAction<any>> | any;
}

const CourseMedia: React.FC<CourseMedia> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    hostTeacherAdd,
    fetchHostTeacher,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);
  let fieldUpdate: any = [
    {
      field_type: 'radio',
      name: 'free',
      label: 'Thanh toán',
      options: [
        {
          value: false,
          labelRadio: 'Trả phí'
        },
        {
          value: true,
          labelRadio: 'Miễn phí'
        }
      ]
    },
    {
      field_type: !formik?.values?.free ? 'current_value' : null,
      name: 'price',
      placeholder: 'Nhập số tiền Vnđ...',
      onChange: true
    },
    {
      field_type: 'autocomplete',
      name: 'course_host_added',
      options: hostTeacherAdd,
      multiple: true,
      fetchData: fetchHostTeacher,
      search: true,
      placeholder: 'Tìm kiếm giáo viên',
      label: 'Mời giáo viên',
      renderListTag: true
    },
    {
      field_type: 'textField',
      name: 'phone_number',
      placeholder: 'Vui lòng nhập số điện thoại...',
      label: 'Số điện thoại',
      descriptionLabel: 'Bắt buộc*',
      onChange: true,
      defaultValue: formik.values.phone_number
    },
    {
      field_type: 'autocomplete',
      name: 'age_restrictions',
      options: genderSubject,
      multiple: false,
      placeholder: 'Đối tượng',
      descriptionLabel: 'Bắt buộc*',
      label: 'Đối tượng'
    },
    {
      field_type: 'autocomplete',
      name: 'visibility',
      options: privacyOptions,
      multiple: false,
      placeholder: 'Quyền riêng tư',
      descriptionLabel: 'Bắt buộc*',
      label: 'Quyền riêng tư',
      disabled: true
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNotificationLeave={setNotificationLeave}
      />
    </Box>
  );
};

export default CourseMedia;
