import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Plyr from 'plyr';
import React from 'react';
import Hls from 'hls.js';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { miniPlayer, timePlayAction } from 'src/store/action/socialWatchAction';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles({
  wrapVideo: {
    position: 'relative',
    // right: 0,
    // bottom: 10,
    // zIndex: 1500,
    width: '350px'
  },
  title: {
    height: '47px',
    background: '#fff',
    borderRadius: '10px 10px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    alignContent: 'center',
    padding: '6px'
  },
  titleVideo: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    wordBreak: 'break-word'
  }
});
export default function MiniPlayer(props) {
  const dataMiniPlayer = useSelector((state: any) => state.watchReducer);
  const dispatch = useDispatch();
  const [isShowControl, setIsShowControl] = React.useState<any>(false);
  const videoRef: any = React.useRef(null);
  const playerRef: any = React.useRef(null);
  const history = useHistory();
  const classes = useStyles();
  const watchSelected = useSelector((state: any) => state.watchReducer);

  React.useEffect(() => {
    playerRef.current = new Plyr(videoRef.current, {
      controls: [
        'play',
        'current-time',
        'progress',
        'settings',
        'mute',
        'volume'
      ]
    });
  }, []);

  React.useEffect(() => {
    const video = videoRef.current;
    if (dataMiniPlayer.src?.includes('m3u8')) {
      if (Hls.isSupported()) {
        const hls = new Hls({ maxBufferSize: 1000000 });
        hls.loadSource(dataMiniPlayer.src);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = dataMiniPlayer.src;
      }
    }
  }, [dataMiniPlayer.src]);
  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = watchSelected?.timePlay;
    }
  };
  React.useEffect(() => {
    if (dataMiniPlayer.showMiniPlayer === false) {
      videoRef.current.pause();
    }
  }, [dataMiniPlayer.showMiniPlayer]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (!videoRef.current.paused) {
        dispatch(timePlayAction(videoRef.current.currentTime));
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      className={classes.wrapVideo}
      onMouseEnter={() => setIsShowControl(true)}
      onMouseLeave={() => setIsShowControl(false)}
    >
      <video
        ref={videoRef}
        style={{ maxWidth: '350px', maxHeight: '200px' }}
        controls
        autoPlay
        id="miniVideo"
        disablePictureInPicture
        onLoadedMetadata={handleVideoLoaded}
      >
        <source src={dataMiniPlayer.src} />
      </video>
      {isShowControl && (
        <Box
          sx={{
            position: 'absolute',
            backgroundImage:
              'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
            width: '100%',
            height: '50px',
            zIndex: 10,
            top: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            onClick={() => {
              history.push(`/watch?v=${dataMiniPlayer.idVideo}`);
              dispatch(
                miniPlayer({
                  showMiniPlayer: false
                })
              );
            }}
          >
            <i
              className="fa-regular fa-bring-forward fa-lg"
              style={{ color: '#fff' }}
            ></i>
          </Button>
          <Button
            onClick={() => {
              dispatch(
                miniPlayer({
                  showMiniPlayer: false
                })
              );
            }}
          >
            <i
              className="fa-solid fa-xmark fa-xl"
              style={{ color: '#fff' }}
            ></i>
          </Button>
        </Box>
      )}
    </Box>
  );
}
