import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialGroupAction';
import * as actionsPost from 'src/store/action/socialPostAction';
import * as types from 'src/constants/store/groupSocial';
import {
  getGroupDetailApi,
  getGroupAccountApi,
  deleteAccountGroupApi,
  getListAccountRemoveGroupApi,
  getWaitingPostApi,
  requestListAccountGroupApi,
  getReportPostGroupApi,
  CUDRuleGroupApi,
  CRUDMemberQuestionGroupApi,
  getSchedulePostGroupApi,
  CRUDReportCensorshipGroupApi,
  CRUDActivityLogApi,
  getListInvitationGroupApi,
  groupTimelineApi
} from 'src/apis/socialGroup';
const typesSetting: any = types;

export const getGroupDetailSagas = function* ({ payload }) {
  try {
    const { id, type } = payload;
    let response = yield call(getGroupDetailApi, id);
    if (response.status === 200) {
      yield put(actions.getGroupDetailSuccess(response.data));
      if (type === 'banner_group') {
        yield put(
          actionsPost.getPostRequestAction(
            {
              sort_by: 'new_post',
              exclude_replies: true,
              id: id,
              limit: 3
            },
            'banner_group',
            window.location.href
          )
        );
      }
    }
  } catch (error: any) {
    yield put(actions.getGroupDetailError(error.response));
  }
};

export const getGroupAccountSagas = function* ({ payload }) {
  try {
    const { id, params, type } = payload;
    yield put(actions.isLoadingGroupMember(true));
    let response = yield call(getGroupAccountApi, id, params);
    if (response.status === 200) {
      yield put(actions.isLoadingGroupMember(false));
      yield put(actions.getGroupAccountSuccess(response.data, type));
    }
  } catch (error) {}
};

export const deleteGroupAccountSagas = function* ({ payload }) {
  try {
    const { id, accountId } = payload;

    let response = yield call(deleteAccountGroupApi, id, {
      account_id: accountId
    });
    if (response.status === 200) {
      yield put(actions.deleteAccountSuccess(accountId, 'member'));
    }
  } catch (error) {}
};

export const getRemoveGroupAccountSagas = function* ({ payload }) {
  try {
    const { id } = payload;

    let response = yield call(getListAccountRemoveGroupApi, id);
    if (response.status === 200) {
      yield put(actions.getRemoveAccountSuccess(response.data));
    }
  } catch (error) {}
};

export const getWaitingPostSagas = function* ({ payload }) {
  try {
    const { id, params } = payload;

    let response = yield call(getWaitingPostApi, id, params);
    if (response.status === 200) {
      yield put(actions.getWaitingPostSuccess(response.data));
    }
  } catch (error) {}
};

export const getWaitingMemberSagas = function* ({ payload }) {
  try {
    const { id, params } = payload;

    let response = yield call(requestListAccountGroupApi, id, params);
    if (response.status === 200) {
      yield put(actions.getWaitingMemberSuccess(response.data));
    }
  } catch (error) {}
};

export const getReportPostSagas = function* ({ payload }) {
  try {
    const { id, params } = payload;

    let response = yield call(getReportPostGroupApi, id, params);
    if (response.status === 200) {
      response.data.reverse(); //reverse array
      yield put(actions.getReportPostSuccess(response.data));
    }
  } catch (error) {}
};

export const getRuleGroupSagas = function* ({ payload }) {
  try {
    const { id } = payload;

    let response = yield call(CUDRuleGroupApi, 'get', id, {}, {});
    if (response.status === 200) {
      yield put(actions.getRuleGroupSuccess(response.data));
    }
  } catch (error) {}
};

export const getMemberQuestionSagas = function* ({ payload }) {
  try {
    const { id, type } = payload;

    let response = yield call(CRUDMemberQuestionGroupApi, type, id, {}, {});
    if (response.status === 200) {
      yield put(actions.getMemberQuestionSuccess(response.data));
    }
  } catch (error) {}
};

export const getSchedulePostSagas = function* ({ payload }) {
  try {
    const { id } = payload;

    let response = yield call(getSchedulePostGroupApi, id);
    if (response.status === 200) {
      yield put(actions.getSchedulePostSuccess(response.data));
    }
  } catch (error) {}
};

export const getCensorshipSagas = function* ({ payload }) {
  try {
    const { id } = payload;

    let response = yield call(
      CRUDReportCensorshipGroupApi,
      'get',
      id,
      {},
      {},
      ''
    );
    if (response.status === 200) {
      yield put(actions.getCensorshipSuccess(response.data));
    }
  } catch (error) {}
};

export const getActivityLogSagas = function* ({ payload }) {
  try {
    const { id, params } = payload;

    let response = yield call(CRUDActivityLogApi, 'get', id, params, {});
    if (response.status === 200) {
      yield put(actions.getActivityLogSuccess(response.data));
      yield put(actions.loadingActivityLogGroup(false));
    }
  } catch (error) {}
};

export const noteActivityLogSagas = function* ({ payload }) {
  try {
    const { id, params, data } = payload;

    let response = yield call(CRUDActivityLogApi, 'patch', id, params, data);
    if (response.status === 200) {
      yield put(actions.noteActivityLogSuccess(response.data));
    }
  } catch (error) {}
};

export const getInvitationSaga = function* ({ payload }) {
  try {
    const { id, params } = payload;
    let response = yield call(getListInvitationGroupApi, id, params);
    if (response.status === 200) {
      yield put(actions.groupInvitationSuccess(response.data, params.role));
    }
  } catch (error) {}
};

export const getActivitiesLogUserInGroup = function* ({ payload }) {
  try {
    const { params, id } = payload;

    yield put(actions.changeIsLoadingActivitiesGroup(true));

    let response = yield call(groupTimelineApi, id, params);

    if (response.status === 200) {
      yield put(
        actions.getListStatusUserInGroupSuccess({
          listStatus: response.data,
          hasMore: response.data?.length >= params.limit,
          isLoading: false
        })
      );
    }
  } catch (error) {}
};
export function* watchGroupSocialAsync() {
  yield takeEvery(typesSetting.GET_GROUP_DETAIL_REQ, getGroupDetailSagas);
  yield takeEvery(typesSetting.GET_GROUP_ACCOUNT_REQ, getGroupAccountSagas);
  yield takeEvery(typesSetting.DELETE_ACCOUNT_REQ, deleteGroupAccountSagas);
  yield takeEvery(typesSetting.GET_WAITING_POST_REQ, getWaitingPostSagas);
  yield takeEvery(typesSetting.GET_WAITING_MEMBER_REQ, getWaitingMemberSagas);
  yield takeEvery(typesSetting.GET_REPORT_POST_REQ, getReportPostSagas);
  yield takeEvery(typesSetting.GET_RULE_GROUP_REQ, getRuleGroupSagas);
  yield takeEvery(typesSetting.GET_SCHEDULE_POST_REQ, getSchedulePostSagas);
  yield takeEvery(typesSetting.GET_MEMBER_QUESTION_REQ, getMemberQuestionSagas);
  yield takeEvery(typesSetting.GET_CENSORSHIP_REQ, getCensorshipSagas);
  yield takeEvery(
    typesSetting.GET_ACCOUNT_REMOVE_REQ,
    getRemoveGroupAccountSagas
  );
  yield takeEvery(typesSetting.GET_ACTIVITY_LOG_REQ, getActivityLogSagas);
  yield takeEvery(typesSetting.NOTE_ACTIVITY_LOG_REQ, noteActivityLogSagas);
  yield takeEvery(typesSetting.GET_INVITATION_REQ, getInvitationSaga);
  yield takeEvery(
    typesSetting.GET_ACTIVITIES_USER_IN_GROUP_REQUEST,
    getActivitiesLogUserInGroup
  );
}
