import React from 'react';
import { Box } from '@mui/material';
import PreviewFile from 'src/pages/Chat/BoxChat/DetailMessage/PreviewFile';
import { urlRocketChat } from 'src/util/config';
import { useSelector } from 'react-redux';

function DetailMessageImage(props) {
  const {
    message,
    borderBottomMessage,
    borderTopMessage,
    showTimeSend,
    nextMessageShowTimeSend,
    renderPostReactEmojis,
    meInfo,
    conversation,
    getBorderRadius
  } = props;

  const [openPreview, setOpenPreview] = React.useState(false);

  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <Box
        id={message?._id}
        onClick={() => setOpenPreview(true)}
        sx={{
          cursor: 'pointer',
          padding: 0,
          width: 'auto',
          display: 'flex',
          justifyContent:
            message.u?._id === rocketId ? 'flex-end' : 'flex-start',
          position: 'relative'
        }}
      >
        <img
          style={{
            height: 'auto',
            width: 'auto',
            maxHeight: 200,
            maxWidth: '100%',
            borderRadius: borderTopMessage
              ? getBorderRadius(
                  conversation,
                  message,
                  rocketId,
                  borderTopMessage,
                  borderBottomMessage,
                  nextMessageShowTimeSend,
                  showTimeSend
                )
              : '12px',
            border: '0.5px solid #afafaf'
          }}
          data-src={
            message?.typeForwards
              ? message?.msg
              : `${urlRocketChat}${message.attachments?.[0].image_url}`
          }
          src={
            message?.typeForwards
              ? message?.msg
              : message?.attachments && message?.attachments?.length > 0
              ? message?.attachments[0].preview
                ? message?.attachments[0].preview
                : `${urlRocketChat}${
                    message.attachments.find(el => el.image_url).image_url
                  }`
              : message.msg
          }
          alt={`${urlRocketChat}${message.attachments?.[0].image_url}` || ''}
        />
        {message.reactions ? renderPostReactEmojis() : null}
      </Box>

      {openPreview ? (
        <PreviewFile
          open={openPreview}
          handleClose={handleClosePreview}
          file={message}
          type="image"
          conversation={conversation}
        />
      ) : null}
    </>
  );
}
export default React.memo(DetailMessageImage);
