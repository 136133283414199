import * as types from 'src/constants/store/user';
export const getInfoMeReq = () => ({
  type: types.GET_INFO_ME_REQ
});

export const updateInfoMe = (data: Object) => ({
  type: types.UPDATE_INFO_ME,
  payload: {
    data
  }
});

export const getInfoMeSuccess = (data: Object) => ({
  type: types.GET_INFO_ME_SUCCESS,
  payload: {
    data
  }
});

export const getInfoMeFailed = (data: Object) => ({
  type: types.GET_INFO_ME_FAILED,
  payload: {
    data
  }
});

export const resetUser = () => ({
  type: types.RESET_ME
});
