import React from 'react';
import { Box, IconButton, Slider } from '@mui/material';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import PreviewFile from 'src/pages/Chat/BoxChat/DetailMessage/PreviewFile';
import { urlRocketChat } from 'src/util/config';

function DetailMessageVideo(props) {
  const { message, conversation, renderPostReactEmojis, type } = props;

  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState<any>();
  const [volume, setVolume] = React.useState(0);
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const videoRef: any = React.useRef();

  const handleClosePreview = (time: any, volumeVideoPreview) => {
    videoRef.current.currentTime = time;
    setCurrentTime(time);
    setVolume(volumeVideoPreview);
    setOpenPreview(false);
    videoRef.current.play();
  };

  const handleVideoLoaded = () => {
    if (videoRef?.current) {
      setDuration(videoRef.current.duration);
      setCurrentTime(videoRef.current.currentTime);
    }
  };
  const handleTimeSliderChange = (time: any) => {
    if (!isNaN(time) && isFinite(time)) {
      videoRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  const handlePlayVideo = () => {
    if (videoRef?.current) {
      videoRef.current.pause();
    }
  };

  return (
    <>
      <Box
        id={message?._id}
        style={{
          cursor: 'pointer',
          padding: 0,
          width: 'auto',
          display: 'flex',
          justifyContent:
            message.u?._id === rocketId ? 'flex-end' : 'flex-start',
          position: 'relative',
          // overflow: 'hidden',
          margin: '5px 0px'
        }}
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          setOpenPreview(true);
          videoRef.current.pause();
        }}
      >
        {videoRef?.current && videoRef.current.paused && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 10,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0,0.2)'
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  backgroundColor: 'unset !important'
                },
                padding: '10px'
              }}
              onClick={e => {
                e.stopPropagation();
                videoRef.current.play();
              }}
            >
              <i
                className="fa-regular fa-circle-play fa-2xl"
                style={{ color: '#fff' }}
              ></i>
            </IconButton>
          </Box>
        )}
        <video
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
          ref={videoRef}
          style={{
            maxHeight: 200,
            height: 'auto',
            minWidth: '100px',
            maxWidth: type === 'boxChat' ? '200px' : '28vw',
            display: 'block',
            borderRadius: '12px'
          }}
          loop
          // controls
          muted={volume === 0 ? true : false}
          onLoadedMetadata={handleVideoLoaded}
          onTimeUpdate={() => {
            if (videoRef?.current) {
              setCurrentTime(videoRef.current.currentTime);
            }
          }}
        >
          <source
            src={
              message?.typeForwards
                ? message?.msg
                : message.attachments[0]?.preview
                ? message.attachments[0]?.preview
                : `${urlRocketChat}${
                    message.attachments[0]?.video_url ||
                    message.attachments[0]?.AmazonS3?.AmazonS3?.path ||
                    message.attachments.find(el => el.video_url)?.video_url
                  }`
            }
          />
        </video>
        {videoRef?.current && !videoRef.current.paused && isHover && (
          <Box
            id="idVideo"
            sx={{
              position: 'absolute',
              bottom: '0px',
              left: '0px',
              width: '100%',
              backgroundImage:
                'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7))',
              borderRadius: '0px 0px 12px 12px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                widows: '100%',
                justifyContent: 'space-between',
                alignItems: 'end',
                padding: '0px 12px 12px'
              }}
            >
              <IconButton
                sx={{
                  '&:hover': {
                    backgroundColor: 'unset !important'
                  },
                  padding: '10px 10px 10px 4px'
                }}
                onClick={e => {
                  e.stopPropagation();
                  handlePlayVideo();
                }}
              >
                <i
                  className="fa-solid fa-pause fa-xs"
                  style={{ color: '#fff' }}
                ></i>
              </IconButton>
              <Slider //slide time video
                onClick={e => {
                  e.stopPropagation();
                }}
                sx={{
                  '& .MuiSlider-thumb': {
                    width: '10px',
                    height: '10px'
                  },
                  margin: '0px 6px',
                  color: '#fff',
                  padding: '8px 0px'
                }}
                size="medium"
                aria-label="medium"
                min={0}
                value={currentTime}
                max={duration}
                onChange={(_, value) => {
                  handleTimeSliderChange(value);
                }}
              />
              <Box
                onClick={e => {
                  e.stopPropagation();
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  alignItems: 'center',
                  position: 'relative',
                  paddingTop: '10px',
                  '&:hover': {
                    '& .MuiSlider-root': {
                      display: 'block !important'
                    }
                  },
                  '& .MuiSlider-root': {
                    display: 'none !important'
                  }
                }}
              >
                <Slider //slide volume
                  sx={{
                    '& .MuiSlider-thumb': {
                      width: 12,
                      height: 12,
                      backgroundColor: '#fff',
                      '&:before': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                      },
                      '&:after': {
                        width: 12,
                        height: 12
                      },
                      '&:hover, &.Mui-focusVisible, &.Mui-active': {
                        boxShadow: 'none'
                      }
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.5,
                      boxShadow: 'inset 0px 0px 4px -2px #000',
                      backgroundColor: '#d0d0d0'
                    },

                    marginBottom: '12px',
                    height: '50px',
                    position: 'absolute',
                    bottom: '12px',
                    right: '5px'
                  }}
                  orientation="vertical"
                  size="medium"
                  aria-label="medium"
                  value={volume}
                  max={1}
                  step={0.1}
                  min={0}
                  onChange={(_, value: any) => {
                    setVolume(value);
                    videoRef.current.volume = value;
                  }}
                />
                <IconButton
                  sx={{
                    '&:hover': {
                      backgroundColor: 'unset !important'
                    }
                  }}
                  onClick={() => {
                    if (volume === 0) {
                      setVolume(0.5);
                    } else {
                      setVolume(0);
                    }
                  }}
                >
                  {volume === 0 ? (
                    <i
                      className="fa-light fa-volume-xmark fa-xs"
                      style={{ color: '#fff' }}
                    ></i>
                  ) : (
                    <i
                      className="fa-light fa-volume fa-xs"
                      style={{ color: '#fff' }}
                    ></i>
                  )}
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
        {message.reactions ? renderPostReactEmojis() : null}
      </Box>

      {openPreview ? (
        <PreviewFile
          open={openPreview}
          handleClose={handleClosePreview}
          file={message}
          type="video"
          conversation={conversation}
          currentTime={currentTime}
        />
      ) : null}
    </>
  );
}

export default React.memo(DetailMessageVideo);
