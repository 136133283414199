import React from 'react';
interface RenderStatusSendingMessageProps {
  type: string;
  classes: any;
  lastMessage: any;
  meInfo: any;
}
interface StatusMessageProps {
  label: string;
}

const RenderStatusSendingMessage: React.FC<RenderStatusSendingMessageProps> =
  React.memo(props => {
    const { type, classes, lastMessage, meInfo } = props;
    const StatusMessage: React.FC<StatusMessageProps> = React.memo(
      ({ label }) => (
        <span
          style={{ color: label === 'Lỗi' ? '#e74c3c' : '' }}
          className={`${classes.statusSendingMessage} message__status`}
        >
          {label}
          {label === 'Lỗi' && (
            <i className="fa-light fa-circle-exclamation"></i>
          )}
        </span>
      )
    );

    let result;

    switch (type) {
      case 'sending':
        result = <StatusMessage label={'Đang gửi'} />;
        break;
      case 'success':
        result = (
          <span className={`${classes.statusSendingMessage}`}>Đã gửi</span>
        );
        break;
      case 'error':
        result = <StatusMessage label={'Lỗi'} />;
        break;
      default:
        result = (
          <span className={classes.statusSendingMessage}>
            {lastMessage?.u?._id === meInfo.id ? 'Đã gửi' : ''}
          </span>
        );
        break;
    }

    return result;
  });

export default RenderStatusSendingMessage;
