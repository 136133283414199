import * as types from 'src/constants/store/courseSocial';
const _ = require('lodash');

const initialState = {
  info: {},
  rolePreviewEdit: { role: '' },
  error: false,
  tempInteract: [],
  infoPreview: null,
  isLoading: false,
  isCheckEmptyLayout: false
};

export const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COURSE_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, infoPreview: newState };
    }

    case types.GET_COURSE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: data,
        infoPreview: null
      };
    }
    case types.UPDATE_COURSES_RELATIONSHIP: {
      let { data } = action.payload;
      return {
        ...state,
        info: {
          ...state.info,
          course_relationships: {
            ...state.info.course_relationships,
            follow_course: data.follow_course,
            participant_course: data.participant_course
          }
        },
        infoPreview: null
      };
    }

    case types.RESET_COURSE: {
      return {
        info: {
          id: '',
          title: '',
          benefit: '',
          company_description: '',
          due_date: '',
          gender: '',
          job_description: '',
          level: '',
          course_category_id: '',
          course_category_subjects: '',
          coursements_count: '',
          requirement: '',
          salary_max: '',
          salary_min: '',
          visibility: '',
          work_experience: '',
          working_form: '',
          page_category_id: [],
          banner: null,
          location: null,
          address: ''
        },
        rolePreviewEdit: { role: '' },
        infoPreview: null
      };
    }

    case types.GET_COURSE_DETAIL_ERROR: {
      return { ...state, error: true, infoPreview: null };
    }
    case types.LOADING_COURSE: {
      return { ...state, isLoading: action.payload.value };
    }
    case types.CHECK_EMPTY_LAYOUT_COURSE: {
      return { ...state, isCheckEmptyLayout: action.payload };
    }
    case types.UPDATE_SHARE_COUNT_COURSE: {
      return {
        ...state,
        info: {
          ...state.info,
          shares_count: state.info.shares_count + 1
        }
      };
    }
    default:
      return state;
  }
};
