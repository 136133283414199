export const GROUP_TYPE = {
  openGroup: 'open_group',
  closeGroup: 'closed_group',
  secretGroup: 'secret_group',
  allGroup: 'all_group'
};

export const GROUP_USER_REQUEST_STATUS = {
  pending: 'pending',
  approved: 'approved',
  default: ''
};
