import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Theme,
  Slide,
  DialogActions,
  Box,
  Tooltip,
  Slider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { urlRocketChat } from 'src/util/config';
import { getListFiles, getListFilesGroup } from 'src/apis/socialChat.apis';

import RightHeaderGlobal from 'src/components/Header/HeaderGlobal/RightHeaderGlobal';
import DropdownGlobalContainer from 'src/containers/HeaderContainer/DropdownGlobalContainer';
import DropdownArrow from 'src/containers/HeaderContainer/DropdownArrow';
import DialogForwardMessage from 'src/components/Dialog/DialogForward/DialogForwardMessage';
import { buttonColor } from 'src/constants/styles';
const useStyles = makeStyles((theme: Theme) => ({
  paperFullScreen: {
    backgroundColor: 'rgba(0,0,0,.5) !important' as any,
    overflow: 'hidden',
    zIndex: 35
  },
  dialogTitle: {
    padding: '0 10px !important',
    position: 'absolute',
    height: 100,
    zIndex: 35,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5),transparent)'
  },
  wrapControl: {
    width: '90%',
    display: 'flex',
    alignItems: 'flex-end',
    // justifyContent: '',
    position: 'absolute',
    bottom: 0,
    backgroundImage: 'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.7))'
  },
  wrapPlay: {
    width: '20px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '12px'
  },
  wrapText: {
    width: '100px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapSlider: {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapVolume: {
    width: '35px',
    height: '90px',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column-reverse',
    paddingBottom: '18px'
  },
  wrapFullScreen: {
    padding: '6px',
    margin: '0px 10px 1px 10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapSpeed: {
    minWidth: '68px !important',
    '& .MuiSelect-icon': {
      color: '#fff'
    },
    '& .MuiSelect-select': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc'
    }
  },
  wrapIcon: {
    marginBottom: '-10px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapVideo: {
    height: '100%',
    width: '100%',
    borderRadius: 0
  },
  mediaIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginBottom: '10px !important',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255, 0.3) !important'
        : 'rgba(255,255,255, 0.5) !important',

    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255, 0.5) !important'
          : 'rgba(255,255,255, 0.7) !important'
    }
  }
}));

const Transition: any = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function PreviewFile(props) {
  const {
    open,
    handleClose,
    file,
    type,
    conversation,
    mainTopic,
    currentTime,
    previewInforConversation
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const videoRef: any = React.useRef();

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [currentFile, setCurrentFile] = React.useState(file);
  const [listFiles, setListFiles] = React.useState<any>([]);
  const [openMenuArrow, setOpenMenuArrow] = React.useState(false);
  // pagination list file footer
  const [page, setPage] = React.useState<number>(0);
  const [over, setOver] = React.useState<Boolean>(false);
  // state video preview bar
  const [volume, setVolume] = React.useState(0.5);
  const [isShowVolume, setIsShowVolume] = React.useState(false);
  const [isShowControl, setIsShowControl] = React.useState(false);
  const [isPlay, setIsPlay] = React.useState(true);
  const [position, setPosition] = React.useState(currentTime ?? 0);

  const [numberTitle, setNumberTitle] = React.useState<any>();
  const [defaultDataNoti, setDefaultDataNoti] = React.useState<any>(null);
  const [openDialogForwardMessage, setOpenDialogForwardMessage] =
    React.useState<boolean>(false);
  const limitFilesCount = Math.trunc(windowDimensions.width / 50);
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};

  let videoDOMPreview: any = document.getElementById(currentFile._id);
  let videoInboxChat: any = videoDOMPreview?.querySelector('video.js-player');

  React.useEffect(() => {
    if (type === 'image' || type === 'video') {
      fetchListFiles();
    }
  }, [type]);

  React.useEffect(() => {
    if (videoInboxChat && open) {
      videoInboxChat.pause();
    }
  }, [open, videoInboxChat]);
  React.useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.currentTime = position;
    }
  }, []);

  React.useEffect(() => {
    if (
      listFiles.length > 0 &&
      listFiles.findIndex((el: any) => el._id === currentFile._id) ===
        listFiles.length - 1 &&
      !over
    ) {
      const offset = limitFilesCount + page * 10;
      fetchListFiles(offset, 10);
      setPage(page + 1);
    }
  }, [JSON.stringify(currentFile)]);

  const fetchListFiles = async (offset = 0, count = limitFilesCount) => {
    try {
      let response;
      if (conversation?.type === 'user') {
        response = await getListFiles(currentFile.rid, count, offset, type);
      } else {
        response = await getListFilesGroup(
          currentFile.rid,
          count,
          offset,
          type
        );
      }

      if (response.status === 200) {
        if (count !== 10) {
          setListFiles(response.data.files);
        } else {
          setListFiles([
            ...listFiles.slice(response.data.files.length),
            ...response.data.files
          ]);
        }

        if (
          (response.data.files.length < 10 && page > 0) ||
          (response.data.files.length < limitFilesCount && page === 0)
        ) {
          setOver(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const playVideo = () => {
    if (videoRef?.current?.paused) {
      setIsPlay(true);
      videoRef?.current?.play();
    } else {
      setIsPlay(false);
      videoRef?.current?.pause();
    }
  };

  function formatDuration(value: number) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const timeUpdateFunc = () => {
    if (videoRef?.current) {
      setPosition(videoRef.current.currentTime);
    }
  };

  const handleChangeVolume = value => {
    setVolume(value);
    videoRef.current.volume = value;
  };

  const openFullscreen = () => {
    const videoElem = videoRef.current;

    if (videoElem.requestFullscreen) {
      videoElem.requestFullscreen();
    } else if (videoElem.mozRequestFullScreen) {
      /* Firefox */
      videoElem.mozRequestFullScreen();
    } else if (videoElem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      videoElem.webkitRequestFullscreen();
    } else if (videoElem.msRequestFullscreen) {
      /* IE/Edge */
      videoElem.msRequestFullscreen();
    }
  };

  const [playbackRate, setPlaybackRate] = React.useState<number | null>();
  const handlePlaybackRateChange = (event: any) => {
    const selectedPlaybackRate: number = parseFloat(event.target.value);
    setPlaybackRate(selectedPlaybackRate);
    videoRef.current.playbackRate = selectedPlaybackRate;
  };

  const renderContent = (file: any) => {
    if (type === 'image') {
      return (
        <div
          style={{
            display: 'flex',
            overflow: 'hidden !important',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              height: 'auto',
              maxHeight: 'calc(100% - 60px)',
              width: 'auto',
              maxWidth: '100%'
            }}
            src={
              file.attachments?.[0]?.preview
                ? file.attachments?.[0]?.preview
                : `${urlRocketChat}${
                    file.attachments?.[0].title_link ?? file.path
                  }`
            }
            alt=""
          />
        </div>
      );
    } else if (type === 'video') {
      return (
        <div
          style={{
            display: 'flex',
            overflow: 'hidden !important',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}
          onMouseEnter={() => setIsShowControl(true)}
          onMouseLeave={() => setIsShowControl(false)}
        >
          <div
            style={{
              maxWidth: '80%',
              height: '100%',
              maxHeight: '100%',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <video
              id={`${currentFile._id}`}
              onTimeUpdate={timeUpdateFunc}
              ref={videoRef}
              loop
              onLoadedMetadata={() => {
                if (!previewInforConversation) {
                  videoRef.current.currentTime = position;
                }
                videoRef.current.play();
              }}
              autoPlay
              src={
                file.attachments?.[0]?.preview
                  ? file.attachments?.[0]?.preview
                  : `${urlRocketChat}${
                      file.attachments?.[0].title_link ?? file.path
                    }`
              }
              className={classes.wrapVideo}
            />
            {isShowControl && (
              <div className={classes.wrapControl}>
                <div className={classes.wrapPlay}>
                  <div className={classes.wrapIcon} onClick={playVideo}>
                    <i
                      className={`fa-solid ${
                        isPlay ? 'fa-pause' : 'fa-play'
                      }  fa-lg`}
                      style={{ color: '#fff' }}
                    ></i>
                  </div>
                </div>
                <div className={classes.wrapText}>
                  {(file?.file?.time || file?.time) && position ? (
                    <Typography style={{ color: '#fff', fontSize: 14 }}>
                      {formatDuration(Math.ceil(position))}
                      {!isNaN(file?.file?.time ?? file?.time) &&
                      isFinite(file?.file?.time ?? file?.time) ? (
                        <>
                          {` / `}
                          {formatDuration(
                            Math.ceil(file?.file?.time ?? file?.time)
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </Typography>
                  ) : null}
                </div>

                <div className={classes.wrapSlider}>
                  <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={position}
                    min={0}
                    step={0.000001}
                    max={Number(file?.file?.time ?? file?.time)}
                    onChange={(_, value: any) => {
                      if (!isNaN(value) && isFinite(value)) {
                        setPosition(value as number);
                        videoRef.current.currentTime = value;
                      }
                    }}
                    sx={{
                      color: '#fff',
                      height: 4,
                      padding: 0,
                      width: '100%',
                      '& .MuiSlider-thumb': {
                        width: 8,
                        height: 8,
                        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)'
                      }
                    }}
                  />
                </div>

                <div
                  onMouseEnter={() => setIsShowVolume(true)}
                  onMouseLeave={() => setIsShowVolume(false)}
                  className={classes.wrapVolume}
                >
                  {volume ? (
                    <div
                      onClick={() => handleChangeVolume(0)}
                      className={classes.wrapIcon}
                    >
                      <i
                        style={{ color: '#fff' }}
                        className="fa-solid fa-volume fa-lg"
                      ></i>
                    </div>
                  ) : (
                    <div
                      onClick={() => handleChangeVolume(0.5)}
                      className={classes.wrapIcon}
                    >
                      <i
                        style={{ color: '#fff' }}
                        className="fa-solid fa-volume-xmark fa-lg"
                      ></i>
                    </div>
                  )}
                  {isShowVolume ? (
                    <Slider
                      aria-label="Volume"
                      value={volume}
                      orientation="vertical"
                      max={1}
                      step={0.1}
                      min={0}
                      onChange={(_, value) => handleChangeVolume(value)}
                      sx={{
                        color: '#fff',
                        height: 60,
                        marginBottom: 1,
                        '& .MuiSlider-rail': {
                          opacity: 0.5,
                          boxShadow: 'inset 0px 0px 4px -2px #000',
                          backgroundColor: '#d0d0d0'
                        },

                        '& .MuiSlider-track': {
                          border: 'none'
                        },
                        '& .MuiSlider-thumb': {
                          width: 12,
                          height: 12,
                          backgroundColor: '#fff',
                          '&:before': {
                            boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                          },
                          '&:after': {
                            width: 12,
                            height: 12
                          },
                          '&:hover, &.Mui-focusVisible, &.Mui-active': {
                            boxShadow: 'none'
                          }
                        }
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>

                <div
                  className={classes.wrapFullScreen}
                  onClick={openFullscreen}
                >
                  <i
                    className="fa-solid fa-expand fa-lg"
                    style={{ color: '#fff' }}
                  ></i>
                </div>

                <FormControl
                  variant="outlined"
                  className={classes.wrapSpeed}
                  size="small"
                >
                  <InputLabel id="demo-select-small-speed">
                    <i
                      style={{ color: '#fff' }}
                      className="fa-solid fa-gear fa-sm"
                    ></i>
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-speed"
                    id="demo-select-small"
                    value={playbackRate}
                    label={
                      <i
                        style={{ color: '#fff' }}
                        className="fa-solid fa-gear fa-lg"
                      ></i>
                    }
                    onChange={handlePlaybackRateChange}
                  >
                    <MenuItem value="1">
                      <b>Speed</b>
                    </MenuItem>
                    <MenuItem value="0.5">0.5</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="1.5">1.5</MenuItem>
                    <MenuItem value="2.5">2</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      );
    } else if (type === 'file') {
      return (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{ fontWeight: '400', fontSize: '16px', color: '#fff' }}
            >
              Không có bản xem trước
            </Typography>

            <Button
              variant="contained"
              style={{
                background: mainTopic
                  ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                  : buttonColor.backgroundColor,
                color: '#fff',
                marginTop: '10px'
              }}
            >
              <a
                href={file.attachments[0].url_download}
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  textTransform: 'none',
                  color: 'unset',
                  textDecoration: 'unset'
                }}
                download={file.attachments?.[0]?.title}
              >
                <i
                  style={{ fontSize: '16px' }}
                  className="fa-solid fa-download"
                ></i>{' '}
                Tải xuống
              </a>
            </Button>
          </div>
        </>
      );
    } else {
      return <p></p>;
    }
  };

  const renderListFiles = () => {
    const listFilesRender = listFiles.map((el: any, index) => {
      if (el.type?.startsWith('image')) {
        return (
          <Box
            key={el._id}
            style={{
              margin: '0px 5px 5px 5px',
              width: '40px',
              height: '40px',
              padding: 0,
              opacity: el._id === currentFile._id ? 1 : 0.6
            }}
            sx={{
              '&:hover': {
                opacity: '1 !important',
                cursor: 'pointer'
              }
            }}
            onClick={() => handleClickFile(el)}
          >
            <img
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '4px'
              }}
              alt=""
              src={el.url}
            />
          </Box>
        );
      } else {
        return (
          <Box
            key={el._id}
            style={{
              margin: '0px 5px 5px 5px',
              width: '40px',
              height: '40px',
              padding: 0,
              opacity: el._id === currentFile._id ? 1 : 0.6
            }}
            sx={{
              '&:hover': {
                opacity: '1 !important',
                cursor: 'pointer'
              },
              'video::-webkit-media-controls': {
                display: 'none !important'
              }
            }}
            onClick={() => handleClickFile(el)}
          >
            <video
              muted={false}
              autoPlay={false}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '4px',
                pointerEvents: 'none'
              }}
              src={el.url}
            />
          </Box>
        );
      }
    });

    return listFilesRender;
  };

  const handleClickFile = file => {
    setCurrentFile(file);
  };

  const handleNextFile = () => {
    const index = listFiles.findIndex((el: any) => el._id === currentFile._id);
    if (index < listFiles.length - 1) {
      setCurrentFile(listFiles[index + 1]);
    }
  };

  const handlePreFile = () => {
    const index = listFiles.findIndex((el: any) => el._id === currentFile._id);
    if (index > 0) {
      setCurrentFile(listFiles[index - 1]);
    }
  };

  const handleClickForward = () => {
    setOpenDialogForwardMessage(true);
  };

  function handleClickKey(e) {
    if (e.keyCode === 38) {
      handlePreFile();
    } else if (e.keyCode === 40) {
      handleNextFile();
    } else if (e.keyCode === 27) {
      videoInboxChat?.play();
      handleClose(position, volume);
    }
  }
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClose(position, volume);
        }}
        TransitionComponent={Transition}
        classes={{ paperFullScreen: classes.paperFullScreen }}
        sx={{
          backgroundImage:
            type === 'image'
              ? `url(${urlRocketChat}${
                  currentFile.attachments?.[0].title_link ?? currentFile.path
                })`
              : // : type === 'video'
                // ? `${urlRocketChat}${
                //     file.attachments?.[0].title_link ?? file.path
                //   }`
                null,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor:
            type === 'image' ? null : ('rgba(0,0,0,.7) !important' as any),
          zIndex: 2000
        }}
        onKeyDown={handleClickKey}
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          {/* left header */}
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Tooltip title="Nhấn esc để đóng">
              <IconButton
                onClick={() => {
                  videoInboxChat?.play();
                  handleClose(position, volume);
                }}
                sx={{
                  backgroundColor: 'rgba(255,255,255,.3) !important',
                  color: 'white',
                  width: '30px',
                  height: '30px',
                  margin: '10px 5px 10px 20px',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,.5) !important'
                  }
                }}
              >
                <i
                  style={{ fontSize: '16px' }}
                  className="fa-light fa-xmark-large"
                ></i>
              </IconButton>
            </Tooltip>
            {/* <Avatar
              style={{
                cursor: 'pointer',
                width: '40px',
                height: '40px',
                marginTop: '5px',
                marginLeft: '5px'
              }}
              onClick={() => {
                history.push(PATHS.HOME);
              }}
              alt="EasyEdu"
              src={LOGO}
            /> */}
          </div>
          {/* right header */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start'
            }}
          >
            <RightHeaderGlobal
              handleClosePreview={handleClose}
              onSelectExpand={() => {
                setOpenMenuArrow(prev => !prev);
              }}
              numberNotification={0}
              setNumberTitle={setNumberTitle}
              defaultDataNoti={defaultDataNoti}
              setDefaultDataNoti={setDefaultDataNoti}
            >
              <Tooltip title="Tải xuống">
                <IconButton
                  sx={{
                    color: 'white',
                    width: '30px',
                    height: '30px',
                    margin: '10px 5px 10px 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.3)'
                    }
                  }}
                >
                  <a
                    href={file.attachments[0].url_download}
                    download={file.attachments?.[0]?.title}
                    style={{ color: 'unset' }}
                  >
                    <i
                      style={{ fontSize: '16px' }}
                      className="fa-light fa-download"
                    ></i>
                  </a>
                </IconButton>
              </Tooltip>
              <Tooltip title="Chuyển tiếp">
                <IconButton
                  onClick={handleClickForward}
                  sx={{
                    color: 'white',
                    width: '30px',
                    height: '30px',
                    margin: '10px 20px 10px 5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.3)'
                    }
                  }}
                >
                  <i
                    style={{ fontSize: '16px' }}
                    className="fa-light fa-share-from-square"
                  ></i>
                </IconButton>
              </Tooltip>
              {openDialogForwardMessage ? (
                <DialogForwardMessage
                  open={openDialogForwardMessage}
                  handleClose={() => setOpenDialogForwardMessage(false)}
                  message={file}
                  // file={file}
                  meInfo={meInfo}
                />
              ) : null}
              <DropdownGlobalContainer handleClose={handleClose} />
            </RightHeaderGlobal>
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            width: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 34,
            padding: 0
          }}
        >
          {renderContent(currentFile)}
          <div
            style={{
              height: '100%',
              width: '80px',
              float: 'right',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <IconButton className={classes.mediaIcon} onClick={handlePreFile}>
              <i
                style={{ color: '#fff important' }}
                className="fa-solid fa-chevron-up"
              ></i>
            </IconButton>
            <IconButton className={classes.mediaIcon} onClick={handleNextFile}>
              <i className="fa-solid fa-chevron-down"></i>
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden'
          }}
        >
          <Box
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            {renderListFiles()}
          </Box>
        </DialogActions>
        {openMenuArrow && (
          <div style={{ zIndex: 36 }}>
            <DropdownArrow
              handleClosePreview={handleClose}
              handleLinkToProfile={() => {
                history.push(
                  `/user/${meInfo.username ? meInfo.username : meInfo.id}`
                );
              }}
              setOpen={setOpenMenuArrow}
            />
          </div>
        )}
      </Dialog>
    </>
  );
}

export default PreviewFile;
