import { IconButton } from '@material-ui/core';
import { Box, ButtonGroup, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import ButtonComponent from 'src/components/Button/Button';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';

function DialogSearchInConversation(props) {
  const {
    keyword,
    setKeyword,
    handleSearchInConversation,
    isShowResultSearched,
    isLoading,
    listMessageSearch,
    handleIncrement,
    MAX_COUNT,
    count,
    handleDecrement,
    handleCloseDialogSearch
  } = props;

  const theme: any = useTheme();

  return (
    <Box
      style={{
        display: 'flex',
        width: '100%',
        padding: '8px 16px'
      }}
    >
      <Box
        style={{
          display: 'flex',
          width: '100%',
          position: 'relative',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        <InputSearch
          keyword={keyword}
          setKeyword={setKeyword}
          action={handleSearchInConversation}
        />
        <Box
          style={{
            display: 'flex',
            position: 'absolute',
            right: 40
          }}
        >
          {isLoading ? (
            <CircularProgress size={12} color="inherit" />
          ) : isShowResultSearched ? null : (
            <Typography
              style={{
                fontSize: '12px',
                opacity: 0.9
              }}
            >
              {keyword && listMessageSearch && listMessageSearch.length > 0
                ? `${count + 1} / ${MAX_COUNT} kết quả`
                : keyword && 'Không có kết quả'}
              {/* {keyword && `kết quả`} */}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <ButtonGroup>
          <IconButton
            color="primary"
            onClick={handleIncrement}
            disabled={
              count === MAX_COUNT - 1
                ? true
                : listMessageSearch.length <= 1
                ? true
                : false
            }
          >
            <i className="fa-regular fa-chevron-up"></i>
          </IconButton>
          <IconButton
            color="primary"
            onClick={handleDecrement}
            disabled={
              count === 0 ? true : listMessageSearch.length <= 1 ? true : false
            }
          >
            <i className="fa-regular fa-chevron-down"></i>
          </IconButton>
          <ButtonComponent
            style={{
              fontSize: '14px',
              padding: '0px 12px',
              textTransform: 'none',
              border: 'none',
              borderRadius: '8px',
              backgroundColor: theme.palette.mode === 'dark' ? '#fafafa' : '',
              color: '#373536'
            }}
            action={handleCloseDialogSearch}
            label="Đóng"
          />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default React.memo(DialogSearchInConversation);
