import React from 'react';import { Link, Typography } from '@mui/material';
import { PATHS } from 'src/constants/paths';

export default function Footer(props) {
  let date = new Date();
  return (
    <div style={{ margin: '8px 1px 6px' }}>
      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
        Quyền riêng tư · Điều khoản · Quảng cáo · Xem thêm ·{' '}
        <Link href={'/about_us/mission'} sx={{ color: 'text.secondary' }}>
          EMSO © {date.getFullYear()}
        </Link>
      </Typography>
    </div>
  );
}
