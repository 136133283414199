export const UPDATE_INFOR_LIVESTREAM = 'UPDATE_INFOR_LIVESTREAM';

export const RESET_LIVESTREAM = 'RESET_LIVESTREAM';

export const TYPE_LIVESTREAM = 'TYPE_LIVESTREAM';

export const OPEN_DIALOG_POST = 'OPEN_DIALOG_POST';

export const RESET_LIVESTREAM_SOCKET = 'RESET_LIVESTREAM_SOCKET';

export const ADD_LIVESTREAM_SOCKET = 'ADD_LIVESTREAM_SOCKET';

export const UPDATE_USER_JOIN_LIVESTREAM = 'UPDATE_USER_JOIN_LIVESTREAM';

export const UPDATE_EYE_LIVESTREAM = 'UPDATE_EYE_LIVESTREAM';

export const CREATE_NEW_COMMENT_LIVESTREAM = 'CREATE_NEW_COMMENT_LIVSETREAM';

export const CREATE_NEW_COMMENT = 'CREATE_NEW_COMMENT';

export const UPDATE_TYPING_COMMENT = 'UPDATE_TYPING_COMMENT';

export const FOLLOW_PAGE_LIVESTREAM = 'FOLLOW_PAGE_LIVESTREAM';

export const REACTIONS_POST_LIVESTREAM = 'REACTIONS_POST_LIVESTREAM';

export const UPDATE_REACTIONS_LIVESTREAM = 'UPDATE_REACTIONS_LIVESTREAM'
