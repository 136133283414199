import React from 'react';const ModalView3D = props => {
  const { path } = props;

  const modelRef = React.useRef();
  const [annots, setAnnots] = React.useState([]);

  const handleClick = event => {
    const { clientX, clientY } = event;

    if (modelRef.current) {
      let hit = modelRef.current.positionAndNormalFromPoint(clientX, clientY);
      if (hit) {
        setAnnots(annots => {
          return [...annots, hit];
        });
      }
    }
  };

  const getDataPosition = annot => {
    return `${annot.position.x} ${annot.position.y} ${annot.position.z}`;
  };

  const getDataNormal = annot => {
    return `${annot.normal.x} ${annot.normal.y} ${annot.normal.z}`;
  };

  const renderImage = document.getElementById('model-viewer');
  return (
    <model-viewer
      src={path}
      poster={renderImage !== null ? renderImage.toDataURL('image/jpeg') : ''}
      alt="A rock"
      exposure="1"
      camera-controls
      shadow-intensity="1"
      ar
      ar-modes="webxr scene-viewer quick-look"
      onClick={handleClick}
      ref={ref => {
        modelRef.current = ref;
      }}
      style={{ width: '100%', height: 500 }}
      id="model-viewer"
    >
      {annots.map((annot, idx) => (
        <button
          key={`hotspot-${idx}`}
          className="view-button"
          slot={`hotspot-${idx}`}
          data-position={getDataPosition(annot)}
          data-normal={getDataNormal(annot)}
        ></button>
      ))}
    </model-viewer>
  );
};

export default ModalView3D;
