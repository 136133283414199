import * as React from 'react';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  TextField,
  DialogTitle,
  DialogActions
} from '@mui/material';
import { collapseString } from 'src/common/string';
import { makeStyles } from '@mui/styles';

import { default as SnackbarNotification } from 'src/components/Snackbar/SnackbarSocial';
import {
  createLinkInviteGroup,
  handleCreateSocialChat
} from 'src/apis/socialChat.apis';

import DialogInviteMembersSocialChat from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogInviteMembersSocialChat';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import Button from 'src/components/Button/Button';
import { buttonColor, buttonColorHover } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  style_root: {
    width: '100%',
    height: '100%',
    marginTop: '10px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  }
}));

export default function DialogCreateSocialChat(props) {
  const { open, setOpen, group } = props;

  const [inputValue, setInputValue] = React.useState<string>('');
  const [openDialogInviteMembers, setOpenDialogInviteMembers] =
    React.useState<boolean>(false);
  const [linkInviteMembers, setLinkInviteMembers] = React.useState<string>('');
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleChange = event => {
    const value = event.target.value;
    setInputValue(value.slice(0, 30)); // Giới hạn độ dài của chuỗi đầu vào là 30 ký tự
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCreateSocialGroupChat = async () => {
    try {
      // const roomName = inputValue;
      const members = [group.id];
      const response = await handleCreateSocialChat(inputValue, members);
      if (response.status === 200) {
        await setOpenDialogInviteMembers(true);
        const roomId = response.data.channel._id;
        const days = 1;
        const responseCreateLinkInvite = await createLinkInviteGroup(
          roomId,
          days
        );
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Tạo đoạn chat thành công'
        });
        if (responseCreateLinkInvite.status === 200) {
          setLinkInviteMembers(responseCreateLinkInvite.data.url);
        }
        setInputValue('');
      }
    } catch (error: any) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message:
          error.response.data.errorType === 'error-duplicate-channel-name '
            ? 'Tên nhóm đã tồn tại, vui lòng nhập tên khác '
            : 'Tạo đoạn chat thất bại !'
      });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minHeight: '200px',
            width: '497px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '24px', fontWeight: '700' }}>
              Đoạn chat cộng đồng mới
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            gap: 2,
            height: 'fit-content',
            position: 'relative',
            paddingTop: '10px'
          }}
        >
          <Box
            style={{
              position: 'relative',
              width: '100%',
              height: 'fit-content',
              // border: '1px solid',
              // borderRadius: '12px',
              padding: '8px 4px'
            }}
          >
            <Typography
              style={{ position: 'absolute', top: 10, right: 10 }}
            >{`${inputValue.length}/30`}</Typography>
            <TextField
              id="outlined-basic"
              fullWidth
              value={inputValue}
              label="Đặt tên đoạn chat"
              variant="outlined"
              onChange={handleChange}
            />
          </Box>
          <Typography style={{ fontSize: '12px', opacity: 0.8 }}>
            Mọi thành viên của{' '}
            <span style={{ fontWeight: 600 }}>
              {collapseString(group?.title, 24)}{' '}
            </span>{' '}
            đều nhìn thấy và tham gia đoạn chat này.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0 16px 8px'
          }}
        >
          <Button
            style={{
              backgroundColor: buttonColor.backgroundColor,
              '&:hover': {
                backgroundColor: buttonColorHover.backgroundColor
              },
              width: '100%',
              cursor: 'pointer',
              color: '#fff'
            }}
            action={() => {
              // setOpenDialogInviteMembers(true);
              handleCreateSocialGroupChat();
            }}
            label={`Tạo đoạn chat`}
          />
        </DialogActions>
      </Dialog>
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
      {openDialogInviteMembers ? (
        <DialogInviteMembersSocialChat
          open={openDialogInviteMembers}
          handleClose={() => setOpenDialogInviteMembers(false)}
          group={group}
          linkInviteMembers={linkInviteMembers}
        />
      ) : null}
    </>
  );
}
