import { makeStyles } from '@material-ui/core';
import { Box, Card, FormControl, InputBase, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import React from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';
import ButtonInherit from 'src/components/Button/ButtonInherit';
const useStyles = makeStyles({});
const MediaList = props => {
  const {
    files,
    setFiles,
    classes,
    setOpenEdit,
    handleClose,
    setIndexEditImage
  } = props;
  const theme: any = useTheme();

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      margin: '0 5px',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow'
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',

        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }));
  const inputRef = React.useRef(null);

  const debounceDescription = function (text: any, index: any, item: any) {
    let indexMedia = files.findIndex(
      (el: any) => el.position === item.position
    );
    let newFile = files[indexMedia];
    Object.defineProperty(newFile, 'description', {
      value: text,
      writable: true
    });

    setFiles((prev: any) => [
      ...prev.slice(0, index),
      newFile,
      ...prev.slice(index + 1)
    ]);
  };
  // React.useEffect(() => {
  //   if (files.length > 0) {
  //     setFiles(prev =>
  //       prev.map((file, index) =>
  //         Object.assign(file, {
  //           id: index + 1
  //         })
  //       )
  //     );
  //   }
  // }, []);

  // const debounceDescription = function (text) {
  //   setText(text);
  //   let indexMedia = files.findIndex((el: any) => el.id === item.id);
  //   let newFile = files[indexMedia];
  //   Object.defineProperty(newFile, 'description', {
  //     value: text,
  //     writable: true
  //   });

  //   setFiles((prev: any) => [
  //     ...prev.slice(0, index),
  //     newFile,
  //     ...prev.slice(index + 1)
  //   ]);
  // };
  const renderSize = () => {
    if (files?.length > 5) {
      return 3;
    } else if (files?.length > 1 && files?.length <= 5) {
      return 2;
    } else return 1;
    // files?.length > 1 ? 2 : 1;
    // if(files?.length > )
    // if (files.length <= 2) {
    //   return 12;
    // } else if (files.length >= 3 && files.length <= 4) {
    //   return 6;
    // } else {
    //   return 4;
    // }
  };
  const handleClickEditImage = (file, index) => {
    setIndexEditImage(index);
    handleClose();
    setOpenEdit(true);
    // if (
    //   files[index].path?.includes('mp4') ||
    //   files[index].path?.includes('mov')
    // ) {
    //   // setOpenEdit(true);
    // } else {
    //   editImage(files[index], output => {
    //     const updatedFiles = [...files];
    //     updatedFiles[index] = output;
    //     if (files[index].preview) URL.revokeObjectURL(files[index].preview);
    //     Object.assign(output, {
    //       preview: URL.createObjectURL(output)
    //     });
    //     setFiles(updatedFiles);
    //   });
    // }
  };
  // const renderMedia = React.useCallback((item, index) => {
  //   return item ? (
  //     <Motion
  //       key={index}
  //       style={{
  //         y: spring(index * 80, {
  //           stiffness: 500,
  //           damping: 32
  //         }),
  //         border: '2px solid #2374E1'
  //       }}
  //     >
  //       {({ y, border }) => (
  //         <GridItemz
  //           key={index}
  //           index={index}
  //           item={item}
  //           classes={classes}
  //           files={files}
  //           setFiles={setFiles}
  //           moveCard={moveCard}
  //           setOpenCropAndRotation={setOpenEdit}
  //           handleClose={handleClose}
  //           setIndexEditImage={setIndexEditImage}
  //         />
  //       )}
  //     </Motion>
  //   ) : null;
  // }, []);

  function onChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) {
    const nextState = swap(files, sourceIndex, targetIndex);
    setFiles(nextState);
  }
  const removeFiles = (index: any) => {
    setFiles((prev: any) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1)
    ]);
  };
  return (
    <Box
      sx={{
        paddingLeft: '10px',
        marginTop: '10px'
      }}
      className="App"
    >
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={renderSize()}
          rowHeight={360}
          style={{
            height: 360 * Math.ceil(8 / (files?.length > 2 ? 4 : 8))
          }}
        >
          {files.map((item: any, index) => (
            <GridItem key={item.position}>
              <Card
                sx={{
                  marginRight: '10px',
                  borderRadius: '10px',
                  cursor: '-webkit-grab'
                }}
              >
                <div className={classes.buttonArea}>
                  <div>
                    {item?.path?.includes('mp4') ||
                    item?.path?.includes('mov') ? null : (
                      <ButtonInherit
                        style={{
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#4b4b4b'
                              : '#e4e6eb'
                        }}
                        action={() => handleClickEditImage(item, index)}
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: 'background.primary'
                            }}
                          >
                            <Typography
                              sx={{ color: 'text.primary', fontWeight: 500 }}
                            >
                              <i className="fas fa-pen fa-sm"></i> &nbsp; Chỉnh
                              sửa
                            </Typography>
                          </Box>
                        }
                        color="#fff"
                      />
                    )}
                  </div>
                  <div
                    className={classes.iconClose}
                    style={{ marginRight: '10px' }}
                    onClick={() => removeFiles(index)}
                  >
                    <i className="far fa-times"></i>
                  </div>
                </div>

                {item?.path?.includes('mp4') || item?.path?.includes('mov') ? (
                  <video
                    style={{
                      backgroundColor: 'black',
                      borderRadius: '10px 10px 0px 0px',
                      objectFit: 'cover',
                      display: 'block'
                    }}
                    width="100%"
                    height="280"
                    src={item.preview}
                    controls
                  />
                ) : (
                  <Box sx={{ position: 'relative' }}>
                    <div
                      style={{
                        height: 280,
                        backgroundImage: `url(${item.preview})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '10px 10px 0px 0px',
                        backgroundColor: '#8A8D91',
                        filter: 'opacity(0.5)'
                      }}
                    ></div>
                    <img
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 4,
                        height: 280,
                        width: '98%',
                        borderRadius: '10px 10px 0px 0px',
                        zIndex: 1800,
                        objectFit: 'contain'
                      }}
                      src={
                        item.preview_remote_url ??
                        item.preview_url ??
                        item.preview
                      }
                      alt="images"
                    />
                    <Box
                      sx={{
                        position: 'absolute',

                        height: 280,
                        width: '98%',
                        zIndex: 1900,
                        top: 0
                      }}
                    ></Box>
                  </Box>
                )}
                {files && files.length > 1 && (
                  <Box
                    style={{
                      // paddingTop: '5px',
                      height: '72px',
                      display: 'flex',
                      alignItems: 'center',
                      boxSizing: 'border-box'
                    }}
                  >
                    {/* <input type="text" placeholder="alo" /> */}
                    {/* <TextInput
                      value={text}
                      action={debounceDescription}
                      style={{ height: 72 }}
                      placeholder="Chú thích"
                    /> */}

                    <FormControl style={{ width: '100%' }} variant="standard">
                      <BootstrapInput
                        fullWidth
                        placeholder="Chú thích"
                        name="title"
                        defaultValue={item?.description ?? ''}
                        onBlur={(event: any) => {
                          debounceDescription(event.target.value, index, item);
                        }}
                      />
                    </FormControl>
                  </Box>
                )}
              </Card>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </Box>
  );
};

export default MediaList;
