import {  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';

import MediaList from './MediaList';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import { buttonColor, modalBackdrop, scrollStyle } from 'src/constants/styles';
import DialogCropAndRotaionImage from 'src/components/Dialog/DialogCropAndRotaionImage';
import image from 'src/assets/images/group/image.svg';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: any) => ({
  rootGrid: {
    padding: '2px !important',
    margin: '0px !important',
    width: '100% !important'
  },
  wrapAction: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.anTd.primary,
    borderRadius: 10
  },
  buttonArea: {
    position: 'absolute',
    top: 10,
    padding: '0px 10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 2000
  },
  iconClose: {
    borderRadius: '50%',
    width: 28,
    height: 28,
    backgroundColor: theme.palette.anTd.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  textInput: {
    height: 80,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0
  },
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.anTd.primary,
    padding: '20px 0px'
  },
  motionContainer: {
    border: '2px solid #000' // Đặt kiểu border cho container Motion
    // Tùy chọn: Đặt padding cho container Motion
  },
  motionElement: {
    border: '1px solid #f00' // Đặt kiểu border cho thành phần Motion
    // Tùy chọn: Đặt padding cho thành phần Motion
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: 0,
    ...scrollStyle,
    backgroundColor: theme.palette.anTd.primary,
    padding: '0px 0px 1px 0px',
    maxHeight: 700,
    overflowX: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

export default function CustomizedDialogs(props) {
  const { open, handleClose, files, setFiles } = props;
  const classes = useStyles();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [indexEditImage, setIndexEditImage] = React.useState<any>(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      'image/jpeg,image/png,.mp4, .avi, .mkv, .wmv, .flv, .mov, .3gp, .ogv, .mpeg',
    onDrop: (acceptedFiles: any) => {
      let filesState = [...files];
      let fileAccepts = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      let newFiles = filesState.concat(fileAccepts);
      setFiles(newFiles);
    }
  });

  const ContentNoneMedia = () => {
    return (
      <div className={classes.wrapNoMedia}>
        <img src={image} style={{ width: 112, height: 112 }} alt="video" />
        <Typography style={{ color: '#65676b', fontSize: 17, fontWeight: 500 }}>
          Thêm ảnh/video để bắt đầu
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        //TransitionComponent={Transition}
        fullWidth
        maxWidth={files.length <= 2 ? 'sm' : files.length <= 4 ? 'md' : 'lg'}
        disableAutoFocus
        disableEnforceFocus={true}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={'center'}
          >
            Chỉnh sửa Ảnh/Video
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          {files.length ? (
            <MediaList
              files={files}
              setFiles={setFiles}
              classes={classes}
              setOpenEdit={setOpenEdit}
              handleClose={handleClose}
              setIndexEditImage={setIndexEditImage}
            />
          ) : (
            <ContentNoneMedia />
          )}
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={
              <div
                {...getRootProps({ className: 'dropzone' })}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <i className="fas fa-file-plus fa-sm"></i> &nbsp;
                <span>Thêm ảnh/video</span>
                <input {...getInputProps()} />
              </div>
            }
          />
          <ButtonInherit
            action={handleClose}
            color={buttonColor.backgroundColor}
            label="Xong"
          />
        </DialogActions>
      </BootstrapDialog>
      {openEdit && (
        <DialogCropAndRotaionImage
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          files={files}
          setFiles={setFiles}
          indexEditImage={indexEditImage}
        />
      )}
    </div>
  );
}
