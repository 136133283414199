import CloseIcon from '@mui/icons-material/Close';import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';

const useStyles = makeStyles({
  textArea: {
    width: '95%',
    minHeight: '120px !important',
    height: '120px !important',
    color: '#000',
    fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    border: '1px solid #ccc',
    wordBreak: 'break-word',
    '&:focus-visible': {
      outline: 'none'
    },
    backgroundColor: 'transparent',
    resize: 'none',
    marginTop: '10px',
    marginLeft: '8px',
    padding: '10px ',

    zIndex: 110
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0px',
    overflow: 'hidden'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const {
    title,
    open,
    handleClose,
    action,
    loading,
    setLoading,
    alignText,
    hiddenButton,
    styleDialogContent,
    maxWidth
  } = props;
  const classes = useStyles();
  const [valueText, setValueText] = React.useState<any>('');

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth ?? undefined}
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20 }}
            textAlign={alignText ?? 'start'}
          >
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent style={{ ...styleDialogContent }}>
          <TextareaAutosize
            id="textInputFeedBack"
            className={classes.textArea}
            value={valueText}
            placeholder="Phản hồi quyết định..."
            onChange={e => setValueText(e.target.value)}
          ></TextareaAutosize>
        </DialogContent>
        {!hiddenButton && (
          <DialogActions>
            <Button
              onClick={handleClose}
              style={{
                textTransform: 'none'
              }}
            >
              {'Hủy'}
            </Button>

            <ButtonInherit
              action={() => {
                action(valueText);
                setLoading && setLoading(true);
              }}
              color={buttonColor.backgroundColor}
              style={{ fontSize: '14px' }}
              label={'Phản hồi quyết định'}
              loading={loading}
            />
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
}
