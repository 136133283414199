import * as types from 'src/constants/store/user';
const _ = require('lodash');

const initialState = {
  info: {},
  error: null
};

export const meReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_INFO_ME: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      newState.info = { ...newState.info, ...data };
      return newState;
    }
    case types.GET_INFO_ME_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      newState.info = _.cloneDeep(data);
      return newState;
    }
    case types.GET_INFO_ME_FAILED: {
      let { data } = action.payload;
      let newState = _.cloneDeep(state);
      newState.error = _.cloneDeep(data);
      return newState;
    }
    case types.RESET_ME: {
      return {
        info: {},
        error: null
      };
    }
    default:
      return state;
  }
};
