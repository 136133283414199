import React from 'react';
import {
  Theme,
  Button,
  Typography,
  styled,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  Tooltip
} from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector } from 'react-redux';
import { submitCreateVote } from 'src/apis/socialChat.apis';

import OptionVote from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogCheckVote';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  wrapVote: {
    borderRadius: 10,
    padding: '5px 10px 12px 10px',
    margin: '10px 0px'
  },
  topWrapVote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  bottomWrapAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapFormOption: {
    padding: '5px 10px 5px 5px',
    margin: '0px 0px 10px 0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  formOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0px'
  },
  buttonOption: {
    width: 27,
    height: 25,
    borderRadius: '50%',
    backgroundColor: '#333',

    '&:hover': {
      backgroundColor: '#665d5d',
      cursor: 'pointer'
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  buttonVote: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#e5e5e5',

    '&:hover': {
      backgroundColor: '#D5D5D5',
      cursor: 'pointer'
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divCheckbox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-expandOpen',
    cursor: 'pointer'
  },
  spanCheckbox: {
    paddingRight: '5px'
  },
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      // backgroundColor: 'transparent',
      borderRadius: '20px',
      background: theme.palette.mode === 'dark' ? '#fafafa20' : '#1c1e2120'
    }
  }
}));

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    id="standard-basic"
    label="Standard"
    variant="standard"
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const CreateVoteForm = props => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const [listOptions, setListOptions] = React.useState(['', '']);
  const [question, setQuestion] = React.useState<string>('');
  const configInitial = {
    mode: 'multiple',
    visibility: 'open',
    showResults: 'always'
  };
  const [config, setConfig] = React.useState<any>(configInitial);

  const createVoteForm = useSelector(
    (state: any) => state.socialChatReducer.voteForm
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateVote = async () => {
    try {
      let poll = { question };
      for (let i = 0; i < listOptions.length; i++) {
        poll[`option-${i}`] = listOptions[i];
      }

      const response = await submitCreateVote(
        config,
        poll,
        createVoteForm?.view?.id
      );
      if (response.status === 200) {
        setOpen(false);
      }
    } catch (error) {}
  };

  const optionsVote = [
    {
      title: 'Chỉ 1 lựa chọn',
      action: 'mode',
      valueOption: 'single',
      valueDefault: 'multiple'
    }
    // {
    //   title: 'Bỏ phiếu kín',
    //   action: 'visibility',
    //   valueOption: 'confidential',
    //   valueDefault: 'open'
    // },
    // {
    //   title: 'Hiện thị kết quả sau khi kết thúc',
    //   action: 'showResults',
    //   valueOption: 'finished',
    //   valueDefault: 'always'
    // }
  ];

  const removeOption = index => {
    if (listOptions.length > 2) {
      setListOptions([
        ...listOptions.slice(0, index),
        ...listOptions.slice(index + 1)
      ]);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '16px',
            height: '532px',
            width: '497px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          onClick={handleClose}
          style={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            height: '40px',
            padding: '8px',
            cursor: 'pointer'
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              right: '-10px',
              top: '-7px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '24px', fontWeight: '700' }}>
              Cuộc thăm dò ý kiến
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent
          className={classes.customScrollbar}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <div className={classes.wrapVote}>
            <div className={classes.wrapFormOption}>
              <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                Câu hỏi
              </Typography>
              <TextField
                value={question}
                onChange={e => setQuestion(e.target.value)}
                fullWidth
                style={{ margin: '8px 0' }}
                placeholder="Aa..."
                variant="standard"
              />

              {optionsVote.map((option, index) => (
                <OptionVote
                  title={option.title}
                  valueOption={option.valueOption}
                  setConfig={setConfig}
                  config={config}
                  action={option.action}
                  key={index}
                  valueDefault={option.valueDefault}
                />
              ))}
              <Typography
                sx={{ fontSize: '16px', fontWeight: 600, padding: '2px 0' }}
              >
                Lựa chọn
              </Typography>
              {listOptions?.map((el: any, index: number) => (
                <div key={index} className={classes.formOption}>
                  <RedditTextField
                    key={index}
                    label={`Lựa chọn ${index + 1}`}
                    id={el}
                    // variant="filled"
                    style={{ marginRight: 5 }}
                    fullWidth
                    onChange={e =>
                      setListOptions([
                        ...listOptions.slice(0, index),
                        e.target.value,
                        ...listOptions.slice(index + 1)
                      ])
                    }
                    helperText={
                      listOptions[index].length > 50
                        ? 'Số kí tự không vượt quá 50'
                        : ''
                    }
                  />

                  <IconButton
                    className={classes.buttonOption}
                    onClick={() => removeOption(index)}
                    sx={{ display: index > 1 ? 'flex' : 'none', fontSize: 20 }}
                  >
                    <i className="far fa-times"></i>
                  </IconButton>
                </div>
              ))}
              <Tooltip
                arrow
                style={{ padding: '2px 8px', width: '100%' }}
                title={'Tối đa 10 lựa chọn'}
              >
                <Box className={classes.bottomWrapAction}>
                  <Button
                    sx={{ textTransform: 'none', width: 240 }}
                    variant="contained"
                    // color="inherit"
                    disabled={listOptions.length >= 10}
                    onClick={() => setListOptions([...listOptions, ''])}
                  >
                    + Thêm lựa chọn
                  </Button>
                </Box>
              </Tooltip>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Button
            sx={{
              textTransform: 'none',
              width: 'fit-content',
              padding: '12px 24px',
              borderRadius: '100px',
              boxShadow: 'none',
              background: buttonColor.backgroundColor
            }}
            variant="contained"
            color="inherit"
            onClick={() => handleCreateVote()}
          >
            Tạo ngay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateVoteForm;
