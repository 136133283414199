import { Divider, Grid, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import ButtonCustom from 'src/components/Button/Button';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DialogCreateCollectionSaved from 'src/components/Dialog/DialogCreateCollectionSaved';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    position: 'fixed',
    width: '310px',
    height: '100%',
    margin: 0,
    padding: '5px 10px 40px 10px'
  },
  wrapMenu: {
    borderRadius: '10px!important'
  },
  wrapMenuSelected: {
    borderRadius: '10px!important',
    backgroundColor: `${theme.palette.button.secondary.background} !important`
  },
  nameCollection: {
    fontSize: '15px !important',
    fontWeight: '600 !important',
    marginLeft: '10px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const PostSavedLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [openDialogCreateCollection, setOpenDialogCreateCollection] =
    useState<any>(null);

  let listCollections = useSelector((state: any) => {
    return state.socialSavedReducer.collectionSaved;
  });
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent="space-between" alignItems="center">
          <Grid
            item
            style={{
              display: 'flex',
              width: '100%',
              padding: 10
            }}
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <Typography style={{ fontWeight: 'bold' }} variant="h5">
              Đã lưu
            </Typography>
            {/* <IconButton sx={{ backgroundColor: 'button.secondary.background' }}>
              <i className="fas fa-cog"></i>
            </IconButton> */}
          </Grid>
          <Grid item xs={12}>
            <ListItem
              button
              className={
                match.path === PATHS.PAGE_SAVED
                  ? classes.wrapMenuSelected
                  : classes.wrapMenu
              }
              onClick={() => {
                history.push('/saved');
              }}
            >
              <div
                style={{
                  width: 36,
                  height: 36,
                  borderRadius: '50%',
                  backgroundColor: buttonColor.backgroundColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <i
                  style={{
                    color: 'white',
                    fontSize: 15
                  }}
                  className="fa-solid fa-bookmark"
                ></i>
              </div>
              <Typography
                sx={{ fontSize: '15px', fontWeight: '600', marginLeft: '10px' }}
              >
                Mục đã lưu
              </Typography>
            </ListItem>
          </Grid>
          <Divider sx={{ margin: '8px 16px' }} />
          <Grid item xs={12} sx={{ paddingBottom: '10px', marginLeft: '10px' }}>
            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
              Bộ sưu tập của tôi
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: '20px' }}>
            {listCollections?.length
              ? listCollections.map(el => (
                  <ListItem
                    key={el.id}
                    button
                    className={
                      el.id === match.params.id
                        ? classes.wrapMenuSelected
                        : classes.wrapMenu
                    }
                    onClick={() => {
                      history.push(`/saved/${el.id}`);
                    }}
                  >
                    <div
                      style={{
                        minWidth: 36,
                        height: 36,
                        borderRadius: '10px',
                        backgroundColor: buttonColor.backgroundColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <i
                        style={{
                          color: 'white',
                          fontSize: 15
                        }}
                        className="fa-solid fa-bookmark"
                      ></i>
                    </div>
                    <Typography className={classes.nameCollection}>
                      {el.name}
                    </Typography>
                  </ListItem>
                ))
              : null}
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: '0px 10px' }}>
              <ButtonCustom
                label="Tạo bộ sưu tập mới"
                action={() => {
                  setOpenDialogCreateCollection(1);
                }}
                icon={<i className="far fa-plus"></i>}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {openDialogCreateCollection && (
        <DialogCreateCollectionSaved
          open={openDialogCreateCollection}
          handleClose={() => {
            setOpenDialogCreateCollection(null);
          }}
          setNoti={setNoti}
          setOpenSnackbar={setOpenSnackbar}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti.code}
          message={noti.message}
        />
      )}
    </div>
  );
};

export default PostSavedLeft;
