import React from 'react';
import './style.scss';
import Popover from '@mui/material/Popover';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import { Popper } from '@mui/material';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import EmojiPicker, {
  EmojiStyle,
  Categories,
  EmojiClickData
} from 'emoji-picker-react';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tab: {},
      popover: {
        zIndex: 1400
      }
    }),
  { index: 1 }
);

function EmojiPopup(props) {
  let { anchorEl, handleCloseEmoji, iconSelect, popover } = props;
  const classes = useStyles();
  const theme: any = useTheme();
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;
  function onClick(emojiData: EmojiClickData, event: MouseEvent) {
    iconSelect(emojiData.emoji);
  }

  return !popover ? (
    <ClickAwayListener onClickAway={handleCloseEmoji}>
      <Popper
        className={classes.popover}
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        placement={'bottom-end'}
      >
        <EmojiPicker
          onEmojiClick={onClick}
          searchDisabled
          height={350}
          previewConfig={{
            showPreview: false
          }}
          emojiStyle={EmojiStyle.FACEBOOK}
          theme={theme.palette.mode}
          emojiVersion="0.6"
          lazyLoadEmojis={true}
          categories={[
            {
              name: 'Dùng thường xuyên',
              category: Categories.SUGGESTED
            },
            {
              name: 'Mặt cười và hình người',
              category: Categories.SMILEYS_PEOPLE
            },
            {
              name: 'Động vật và thiên nhiên',
              category: Categories.ANIMALS_NATURE
            },
            {
              name: 'Đồ ăn',
              category: Categories.FOOD_DRINK
            },
            {
              name: 'Địa điểm',
              category: Categories.TRAVEL_PLACES
            },
            {
              name: 'Hoạt động',
              category: Categories.ACTIVITIES
            },
            {
              name: 'Đối tượng',
              category: Categories.OBJECTS
            },
            {
              name: 'Ký hiệu',
              category: Categories.SYMBOLS
            }
            // {
            //   name: 'Cờ',
            //   category: Categories.FLAGS
            // }
          ]}
        />
      </Popper>
    </ClickAwayListener>
  ) : (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleCloseEmoji}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <EmojiPicker
        onEmojiClick={onClick}
        searchDisabled
        height={330}
        width={350}
        previewConfig={{
          showPreview: false
        }}
        emojiStyle={EmojiStyle.FACEBOOK}
        theme={theme.palette.mode}
        emojiVersion="0.6"
        lazyLoadEmojis={true}
        categories={[
          {
            name: 'Dùng thường xuyên',
            category: Categories.SUGGESTED
          },
          {
            name: 'Mặt cười và hình người',
            category: Categories.SMILEYS_PEOPLE
          },
          {
            name: 'Động vật và thiên nhiên',
            category: Categories.ANIMALS_NATURE
          },
          {
            name: 'Đồ ăn',
            category: Categories.FOOD_DRINK
          },
          {
            name: 'Địa điểm',
            category: Categories.TRAVEL_PLACES
          },
          {
            name: 'Hoạt động',
            category: Categories.ACTIVITIES
          },
          {
            name: 'Đối tượng',
            category: Categories.OBJECTS
          },
          {
            name: 'Ký hiệu',
            category: Categories.SYMBOLS
          }
          // {
          //   name: 'Cờ',
          //   category: Categories.FLAGS
          // }
        ]}
      />
    </Popover>
  );
}

export default EmojiPopup;
