import React, { FunctionComponent } from 'react';

import { useTheme } from '@mui/styles';

import { Prompt } from 'react-router-dom';

import DialogConfirm from 'src/components/Dialog/DialogConfirm';

interface CustomPrompt {
  navigate: any;
  when: boolean;
  shouldBlockNavigation: any;
  title: string;
  content: string | FunctionComponent;
  optionDialog: number;
  labelButton: Array<string>;
  actionOption?: any;
  loading?: boolean;
}
const CustomPrompt = ({
  navigate,
  when,
  shouldBlockNavigation,
  title,
  content,
  optionDialog,
  labelButton,
  actionOption,
  loading
}: CustomPrompt) => {
  const theme: any = useTheme();

  const [lastLocation, setLastLocation] = React.useState<any>();
  const [confirmedNavigation, updateConfirmedNavigation] =
    React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  //open dialog
  const showDialog = location => {
    setOpenDialog(true);
    setLastLocation(location);
  };
  const closeDialog = cb => {
    setOpenDialog(false);
    if (cb) {
      cb();
    }
  };

  React.useEffect(() => {
    if (!when) return;
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = '');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
  //action default option in dialog. true hiện thông báo . false không hiện thông báo
  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      showDialog(nextLocation);
      return false;
    }
    return true;
  };

  //action confirmed navigation
  const handleConfirmNavigationClick = () => {
    closeDialog(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };
  // const handleCreateProductDraft = () => {
  //   actionOption[0]();
  //   updateConfirmedNavigation(true);
  // };

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <DialogConfirm
        open={openDialog}
        alignText={'center'}
        title={title}
        text={content}
        hiddenButton={true}
        optionDialog={optionDialog}
        labelButton={labelButton}
        actionOption={[closeDialog, handleConfirmNavigationClick]}
        loading={loading}
        colorButton={[
          '',
          theme.palette.mode === 'dark' ? '#4b4b4b' : '#eeeeee'
        ]}
        styleInBtn={[
          {},
          {
            color: theme.palette.mode === 'dark' ? '#e4e6ea' : '#050505'
          }
        ]}
      />
    </>
  );
};

export default CustomPrompt;
