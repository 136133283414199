import * as types from 'src/constants/store/courseSocial';
export const coursePreview = (data: Object) => ({
  type: types.COURSE_PREVIEW_INFO,
  payload: {
    data
  }
});

export const getCourseDetailSuccess = (data: any) => ({
  type: types.GET_COURSE_DETAIL_SUCCESS,
  payload: {
    data
  }
});

export const getCourseDetailError = (data: any) => ({
  type: types.GET_COURSE_DETAIL_ERROR,
  payload: {
    data
  }
});

export const getCourseDetailRequest = (idCourse: any) => ({
  type: types.GET_COURSE_DETAIL_REQUEST,
  payload: {
    idCourse
  }
});
export const updateFollowCourse = (data: any) => ({
  type: types.UPDATE_COURSES_RELATIONSHIP,
  payload: {
    data
  }
});

export const resetCourse = () => ({
  type: types.RESET_COURSE
});
export const loadingCourse = (value: any) => ({
  type: types.LOADING_COURSE,
  payload: { value }
});
export const updateCourseShareCount = () => ({
  type: types.UPDATE_SHARE_COUNT_COURSE,
  payload: {}
});
export const checkEmptyLayout = (isCheck: any) => ({
  type: types.CHECK_EMPTY_LAYOUT_COURSE,
  payload: isCheck
});
