import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Radio,
  Theme,
  Box
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { listVisibility, listVisibilityUsers } from 'src/constants/common';
import { modalBackdrop } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    width: 500
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 76,
    borderRadius: 10,
    padding: 6,
    display: 'flex',
    margin: '10px 0px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.button.primary.hover
    }
  },
  rootSelected: {
    height: 76,
    borderRadius: 10,
    padding: 6,
    display: 'flex',
    margin: '10px 0px',
    cursor: 'pointer',
    backgroundColor: theme.palette?.button.custom.background
  },
  wrapIcon: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(100% - 68px)'
  },
  wrapText: {
    marginLeft: 10
  },
  textLabel: {
    color: theme.palette.mode === 'dark' ? '#fff' : '#050505',
    fontSize: 17,
    fontWeight: '500 !important'
  },
  textSubLabel: {
    fontSize: 15,
    color: '#65676B'
  }
}));

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  const {
    open,
    setOpen,
    value,
    handleAction,
    handleOther,
    type,
    typeVisibility,
    typeRemove
  } = props;
  const [visibility, setVisibility] = React.useState<any>('public');

  React.useEffect(() => {
    if (value) {
      setVisibility(value);
    }
  }, [value]);
  const handleClose = () => {
    setOpen(false);
    handleOther && handleOther();
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: '700', fontSize: 20 }}
            textAlign={'center'}
          >
            Chọn đối tượng
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            {type !== 'editPrivacy' && !typeVisibility && (
              <div>
                <Typography
                  style={{
                    fontSize: 17,

                    fontWeight: 500
                  }}
                >
                  Ai có thể xem bài viết của bạn?
                </Typography>
                <Typography
                  style={{
                    fontSize: 15,
                    color: '#65676b'
                  }}
                >
                  Bài viết của bạn sẽ hiển thị ở Bảng feed hoặc moment, trang cá
                  nhân và kết quả tìm kiếm.
                </Typography>
              </div>
            )}

            {(typeVisibility
              ? listVisibilityUsers
              : type === 'create_album'
              ? [listVisibility[0]]
              : listVisibility
            )?.map((el: any) =>
              (
                typeRemove
                  ? el.key !== type && el.key !== typeRemove
                  : el.key !== type
              ) ? (
                <Box
                  className={
                    visibility === el.key ? classes.rootSelected : classes.root
                  }
                  key={el.key}
                  onClick={() => {
                    handleAction(el.key);
                    setVisibility(el.key);
                    handleClose();
                  }}
                >
                  <div className={classes.wrapIcon}>
                    <i className={`${el.icon} fa-lg`}></i>
                  </div>

                  <div className={classes.wrapContent}>
                    <div className={classes.wrapText}>
                      <Typography className={classes.textLabel}>
                        {el.label}
                      </Typography>

                      <Typography className={classes.textSubLabel}>
                        {el.subLabel}
                      </Typography>
                    </div>
                    <Radio checked={visibility === el.key} color="secondary" />
                  </div>
                </Box>
              ) : null
            )}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
