import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  CircularProgress,
  InputBase,
  Theme,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { styled, alpha } from '@mui/material/styles';
import {
  scrollStyle,
  modalBackdrop,
  boxShadow,
  buttonColor
} from 'src/constants/styles';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-hook-inview';
import SocialContent from 'src/components/SocialPost/SocialContent';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
const useStyles = makeStyles((theme: Theme) => ({
  social: {
    ...boxShadow,
    width: '100%',
    backgroundColor: theme.palette.myBackgroundColor?.primary,
    marginTop: 10,
    marginBottom: 10
    // borderRadius: 10
  }
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '5px 5px 5px 10px',
    borderBottom: '0',
    ...scrollStyle
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  '& .MuiPaper-root': {
    width: 600
  }
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-root': { width: '80% !important' },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '6px 6px',
    width: '95%',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function CustomizedDialogs(props) {
  const { open, setOpen, dataEmbed, type, post, setDataEmbed } = props;

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const [ref, isVisible] = useInView({
    threshold: 1
  });
  const handleClick = async () => {
    await navigator.clipboard.writeText(dataEmbed);
  };
  const renderContent = (icon: any, action: any, count: any) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 20px 5px 0px'
        }}
      >
        <i
          className={icon}
          style={{
            color: '#7f7f7f',
            fontSize: 12
          }}
        ></i>
        <Typography
          variant="subtitle1"
          style={{
            color: '#7f7f7f',
            fontSize: 12,
            fontWeight: 500
          }}
        >
          &nbsp; {count === '0' ? action : count}
        </Typography>
      </div>
    );
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: 20
            }}
            textAlign={'center'}
          >
            Nhúng bài viết
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent sx={{ padding: 5 }}>
          <div style={{ display: 'flex', width: '100%', paddingRight: '5px' }}>
            <div style={{ width: '100%' }}>
              <Typography style={{ marginBottom: 5, marginTop: 10 }}>
                Sao chép và dán mã này vào trang web của bạn.
              </Typography>
              <div style={{ display: 'flex', width: '100%' }}>
                <BootstrapInput
                  fullWidth
                  defaultValue={dataEmbed}
                  sx={{
                    backgroundColor: 'background.input',
                    fontWeight: 500
                  }}
                  onChange={e => setDataEmbed(e.target.value)}
                />
                <ButtonInherit
                  label="Sao chép mã"
                  style={{
                    backgroundColor: buttonColor.backgroundColor,
                    color: '#ffffff',
                    minWidth: '130px',
                    marginRight: 0
                  }}
                  action={handleClick}
                />
              </div>
              <div className={classes.social}>
                <div ref={ref}>
                  <SocialPostInformation
                    type={type}
                    key={`${post.id}-postInformation`}
                    post={post}
                    embed="embed"
                    // setPostData={setPostData}
                  />
                </div>
                <SocialContent key={`${post.id}-socialContent`} post={post} />

                <div
                  style={
                    type === 'watch-selected'
                      ? { padding: '10px 0 0 0' }
                      : type === 'previewMedia'
                      ? { padding: '10px 6px 0px 6px' }
                      : { padding: '0px 16px 4px 16px' }
                  }
                >
                  <Divider></Divider>
                  <div style={{ display: 'flex' }}>
                    {renderContent(
                      'fa-solid fa-thumbs-up',
                      'Thích',
                      post.favourites_count
                    )}
                    {renderContent(
                      'fa-solid fa-message',
                      'Bình luận',
                      post.replies_total
                    )}
                    {renderContent(
                      'fa-sharp fa-solid fa-share',
                      'Chia sẻ',
                      post.reblogs_count
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
