import _ from 'lodash';
import * as types from 'src/constants/store/socialSaveCache';
const initialState = {
  activitiesCache: [],
  activitiesCacheUser: [],
  groupSuggest: null,
  friendSuggest: null,
  pageSuggest: null,
  momentSuggest: null,
  friendRequest: null,
  listGroup: null,
  listPage: null,
  listPageManage: null,
  listEvent: null,
  listEventHost: null,
  listGroupChat: null,
  checkMeOnline: null,
  typesCache: null,
  indexPost: null,
  isLoad: false
};
export const saveCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_CACHE_PAGE: {
      let newState = _.cloneDeep(state);
      const dataPage = action.payload;
      return {
        ...newState,
        activitiesCache: [...dataPage, ...newState.activitiesCache].filter(
          (item, index, self) =>
            self.findIndex(value => value.id === item.id) === index
        )
      };
    }
    case types.UPDATE_CACHE_PAGE: {
      const data = action.payload;
      return {
        ...state,
        activitiesCache: data
      };
    }
    case types.SAVE_CACHE_PAGE_USER: {
      let newState = _.cloneDeep(state);
      const dataPageUser = action.payload.activitiesUser;
      const userId = action.payload.userId;
      return {
        ...newState,
        activitiesCacheUser: [
          { activitiesUser: dataPageUser, userId: userId },
          ...newState.activitiesCacheUser
        ].filter(
          (item, index, self) =>
            self.findIndex(value => value.userId === item.userId) === index
        )
      };
    }
    case types.TYPE_CACHE: {
      return {
        ...state,
        typeCache: action.payload
      };
    }
    case types.SAVE_INDEX_POST: {
      return {
        ...state,
        indexPost: action.payload
      };
    }
    case types.SAVE_GROUPS_SUGGEST: {
      return {
        ...state,
        groupSuggest: action.payload
      };
    }
    case types.SAVE_FRIENDS_SUGGEST: {
      return {
        ...state,
        friendSuggest: action.payload
      };
    }
    case types.SAVE_PAGES_SUGGEST: {
      return {
        ...state,
        pageSuggest: action.payload
      };
    }
    case types.SAVE_MOMENTS_SUGGEST: {
      return {
        ...state,
        momentSuggest: action.payload
      };
    }
    case types.SAVE_LIST_FRIEND_REQUEST: {
      return {
        ...state,
        friendRequest: action.payload
      };
    }
    case types.SAVE_LIST_GROUP: {
      return {
        ...state,
        listGroup: action.payload
      };
    }
    case types.SAVE_LIST_PAGE: {
      return {
        ...state,
        listPage: action.payload
      };
    }
    case types.SAVE_LIST_PAGE_MANAGE: {
      return {
        ...state,
        listPageManage: action.payload
      };
    }
    case types.SAVE_LIST_EVENT: {
      return {
        ...state,
        listEvent: action.payload
      };
    }
    case types.SAVE_LIST_EVENT_HOST: {
      return {
        ...state,
        listEventHost: action.payload
      };
    }
    case types.SAVE_LIST_GROUP_CHAT: {
      return {
        ...state,
        listGroupChat: action.payload
      };
    }
    case types.SAVE_CHECK_ME_ONLINE: {
      return {
        ...state,
        checkMeOnline: action.payload
      };
    }
    case types.ACTION_RELOAD: {
      return {
        ...state,
        isLoad: action.payload
      };
    }

    case types.RESET_ACTIVITIES_CACHE_USER: {
      return {
        ...state,
        activitiesCacheUser: []
      };
    }
    default:
      return state;
  }
};
