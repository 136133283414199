import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';

import SocialFieldUpdate from 'src/components/SocialFieldUpdate/SocialFieldUpdate';
import DialogLocation from 'src/components/Dialog/DialogLocation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '5px 10px -10px 10px',
    borderRadius: 10,
    width: '100%'
  }
}));

interface OtherInformation {
  infoUpdate?: any;
  formik?: any;
  setCheckStep?: React.Dispatch<React.SetStateAction<any>> | any;
  growInfo?: any;
  locationSelected?: any;
  setLocationSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  openDialogUpdateLocation?: boolean | any;
  setOpenDialogUpdateLocation?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setCheckDuration?: React.Dispatch<React.SetStateAction<any>> | any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
}

const OtherInformation: React.FC<OtherInformation> = props => {
  const classes = useStyles();
  const {
    infoUpdate,
    formik,
    setCheckStep,
    growInfo,
    locationSelected,
    setLocationSelected,
    openDialogUpdateLocation,
    setOpenDialogUpdateLocation,
    setOpenSnackbar,
    setCheckDuration,
    setNoti,
    setNotificationLeave
  } = props;
  const [loading, setLoading] = React.useState<any>('');
  const [verify, setVerify] = React.useState<any>(false);

  React.useEffect(() => {
    if (growInfo?.location) {
      setLocationSelected({ location: [growInfo.location] });
    }
  }, [JSON.stringify(growInfo?.location)]);

  React.useEffect(() => {
    if (locationSelected?.location) {
      formik.setFieldValue('location', locationSelected?.location);
    } else if (locationSelected?.title) {
      formik.setFieldValue('location', {
        lat: 22.2872717,
        lng: 105.6157884
      });
    }
  }, [JSON.stringify(locationSelected)]);

  React.useEffect(() => {
    if (setCheckStep) {
      if (
        formik.values.introduction_video &&
        !formik.errors.introduction_video &&
        Object.keys(formik.errors).length <= 1
      ) {
        setCheckStep(prev => ({ ...prev, step3: true }));
      } else {
        setCheckStep(prev => ({ ...prev, step3: false }));
      }
    }
  }, [JSON.stringify(formik.values), JSON.stringify(formik.errors)]);

  let fieldUpdate: any = [
    {
      field_type: 'upload_media',
      name: 'introduction_video',
      label: 'Video giới thiệu dự án (không quá 3 phút)',
      type: 'file',
      type_video: 'file_video_intro',
      height: 160,
      descriptionLabel: 'Bắt buộc*',
      default_media: growInfo?.introduction_video
        ? growInfo?.introduction_video
        : formik.values.introduction_video
    },
    {
      field_type: 'textField',
      name: 'address',
      placeholder: 'VD: Số 250 Minh Khai, Hai Bà Trưng',
      label: 'Địa chỉ',
      onChange: true,
      maxRows: 2,
      defaultValue: formik.values.address
    },
    {
      field_type: locationSelected ? 'map' : 'autocomplete',
      name: 'location',
      options: [{ name: 'Chọn vị trí' }],
      label: 'Vị trí',
      placeholder: 'Vị trí',
      descriptionTitle: 'Thêm vị trí thực để mọi người biết đến dự án của bạn.',
      endButton: {
        icon: 'fa-solid fa-location-dot'
      },
      disabled: true,
      action: () => setOpenDialogUpdateLocation(true),
      style: { margin: '8px 0px 0px' },
      styleLabel: { margin: '8px 0px 0px' }
    },
    {
      field_type: locationSelected ? 'button_add' : null,
      name: 'remove_location',
      label: 'Chọn lại vị trí dự án',
      action: () => {
        setLocationSelected(null);
        formik.setFieldValue('location', null);
      }
    }
  ];

  return (
    <Box className={classes.root}>
      <SocialFieldUpdate
        setVerify={setVerify}
        verify={verify}
        setLoading={setLoading}
        formik={formik}
        fieldUpdate={fieldUpdate}
        infoUpdate={infoUpdate}
        spacing={0}
        setNoti={setNoti}
        setOpenSnackbar={setOpenSnackbar}
        setCheckDuration={setCheckDuration}
        setNotificationLeave={setNotificationLeave}
      />
      <DialogLocation
        open={openDialogUpdateLocation}
        handleClose={() => {
          setOpenDialogUpdateLocation(false);
        }}
        locationSelected={locationSelected}
        setLocationSelected={setLocationSelected}
        type={'grow'}
      />
    </Box>
  );
};

export default OtherInformation;
