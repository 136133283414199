export enum EnumEmojiReactType {
  default = -1,
  like,
  love,
  haha,
  yay,
  wow,
  sad,
  angry
}
