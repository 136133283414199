import { LinearProgress, Box } from '@mui/material';import React from 'react';
function ThumbFileVideo(props) {
  const { classesImg, file, classesWrapIcon } = props;

  const [isLoadingProgress, setIsLoadingProgress] =
    React.useState<boolean>(true);
  const fileStore = file.size / 1024 / 1024;
  const timeUpload = Math.round(fileStore / 2.2) * 1000;

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingProgress(false);
    }, timeUpload);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        display: 'block',
        position: 'relative'
      }}
    >
      <video
        // src={file.preview}
        className={classesImg}
      >
        <source src={file.preview} />
      </video>
      <div className={classesWrapIcon}>
        <i className="fa-solid fa-play fa-lg" style={{ color: '#ccc' }}></i>
      </div>
      {isLoadingProgress && (
        <Box sx={{ width: '100%', position: 'absolute', bottom: '0' }}>
          <LinearProgress sx={{ borderRadius: '50px' }} />
        </Box>
      )}
    </div>
  );
}

export default ThumbFileVideo;
