import _ from 'lodash';import * as types from 'src/constants/store/albumSocial';
const initialState = {
  listAlbum: [] as any
};

export const socialAlbumReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ALBUM: {
      let newState = _.cloneDeep(state);
      const dataAlbum = action.payload.listAlbum;
      return {
        ...newState,
        listAlbum: dataAlbum
      };
    }
    case types.CREATE_ALBUM: {
      let newState = _.cloneDeep(state);
      const newAlbum: any = action.payload.album;
      return {
        ...newState,
        listAlbum: [newAlbum, ...newState.listAlbum]
      };
    }
    case types.DELETE_ALBUM: {
      let newState = _.cloneDeep(state);
      const idAlbum = action.payload.idAlbum;
      const dataFilter: any = newState.listAlbum.filter(
        item => item.id !== idAlbum
      );
      return {
        ...newState,
        listAlbum: dataFilter
      };
    }
    case types.RESET_ALBUM: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
