import { Box, Grid, Theme } from '@mui/material';import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  }
}));

export default function StepperSocial(props) {
  const classes = useStyles();
  const { activeStep, steps } = props;
  const data = Array.from({ length: steps }, (_, index) => index);
  return (
    <div className={classes.root}>
      <Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
        {data?.map((item, index) => (
          <Grid key={index} item xs={12 / steps}>
            <Box
              sx={{
                width: '100%',
                height: '10px',
                backgroundColor:
                  activeStep >= index ? buttonColor.backgroundColor : '#F0F2F5',
                borderRadius: '10px'
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
