import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from 'src/store/action/socialRecruitAction';
import * as types from 'src/constants/store/recruitSocial';
import {
  getDetailRecruitApi,
  getRecruitCategories
} from 'src/apis/socialRecruit.api';
const typesSetting: any = types;

export const getRecruitDetailSagas = function* ({ payload }) {
  try {
    yield put(actions.loadingRecruit(true));
    const { idRecruit } = payload;
    let response = yield call(getDetailRecruitApi, idRecruit);
    if (response.status === 200) {
      yield put(actions.loadingRecruit(false));
      yield put(actions.getRecruitDetailSuccess(response.data));
      yield put(actions.resetRecruitError());
    }
  } catch (error: any) {
    yield put(actions.loadingRecruit(false));
    yield put(actions.getRecruitDetailError(error.response));
  }
};

export const getRecruitCategorySagas = function* () {
  try {
    let response = yield call(getRecruitCategories);
    if (response.status === 200) {
      yield put(actions.getRecruitCategoriesSuccess(response.data));
    }
  } catch (error: any) {}
};

export function* watchRecruitSocialAsync() {
  yield takeEvery(
    typesSetting.GET_RECRUIT_DETAIL_REQUEST,
    getRecruitDetailSagas
  );
  yield takeEvery(
    typesSetting.GET_RECRUIT_CATEGORIES_REQ,
    getRecruitCategorySagas
  );
}
