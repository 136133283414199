import React, { useState } from 'react';import { useSelector } from 'react-redux';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';

export default function NotiPost() {
  const notiPost =
    useSelector((state: any) => state.socialPostReducer.notiPost) || null;
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [noti, setNoti] = useState<any>({
    code: 200,
    message: ''
  });

  React.useEffect(() => {
    if (notiPost) {
      setOpenSnackbar(true);
      setNoti({
        code: 200,
        message: notiPost?.target_account?.id
          ? 'Đã chia sẻ lên trang cá nhân của bạn bè'
          : notiPost?.group?.id
          ? 'Đã chia sẻ lên nhóm của bạn'
          : 'Đã chia sẻ lên trang cá nhân của bạn'
      });
    }
  }, [notiPost?.id, notiPost?.target_account?.id, notiPost?.group?.id]);

  return (
    <SnackbarNotification
      open={openSnackbar}
      setOpen={setOpenSnackbar}
      code={noti?.code}
      message={noti?.message}
      notiPost={notiPost}
    />
  );
}
