import * as React from 'react';import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Theme,
  Chip,
  Avatar,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles, withStyles } from '@mui/styles';
import { scrollStyle, modalBackdrop, buttonColor } from 'src/constants/styles';
import CloseIcon from '@mui/icons-material/Close';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import hobbyz from 'src/assets/images/hobby.png';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import { getAboutUser, updateHobbyUser } from 'src/apis/socialUser';
import { groupCategoryApi } from 'src/apis/socialGroup';
import { DialogActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme: Theme) => ({
  wrapNoMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    flexDirection: 'column',
    width: '100%',
    margin: '20px 0px'
  },
  imgGif: {
    width: '490px !important',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapTag: {
    minHeight: '56px',
    maxHeight: '150px',
    borderRadius: '10px',
    border: theme.palette.border.solid,
    overflow: 'hidden auto',
    padding: 5,
    ...scrollStyle
  },
  divWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f5f5f5',
      borderRadius: 10
    }
  },
  divWrapImage: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapTagElement: {
    display: 'inline-block',
    width: 'fit-content',
    padding: '3px 5px',
    borderRadius: 5,
    marginRight: 5,
    marginBottom: 5
  },
  buttonClose: {
    display: 'inline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapText: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    color: buttonColor.backgroundColor,
    display: 'inline'
  }
}));
const StyledChip = withStyles({
  root: {
    '&&:hover': {
      backgroundColor: '#E4E6EB'
    }
  }
})(Chip);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    borderBottom: '0',
    ...scrollStyle,
    padding: '8px 16px !important'
  },
  '& .MuiPaper-root': {
    width: 550,
    height: 700
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props) {
  const { open, handleClose, hobbySelected, setHobbySelected, setAboutUser } =
    props;
  const classes = useStyles();
  const [categories, setCategories] = React.useState<any>([]);
  const [arrayId, setArrayId] = React.useState<any>([]);
  const meInfo = useSelector((state: any) => state?.meReducer.info);
  const [openDialogSearch, setOpenDialogSearch] = React.useState(false);
  const fetchCategories = async () => {
    let response = await groupCategoryApi();
    if (response.status === 200) {
      let array = response.data;
      array.push({
        text: 'Tìm kiếm sở thích khác',
        action: true
      });
      setCategories(array);
    }
  };
  const checkChip = (id: any) => {
    let result = arrayId?.find((el: any) => el === id);
    if (result) {
      let arr = arrayId?.filter((item: any) => item !== id);
      setArrayId(arr);
    } else {
      setArrayId(prev => [...prev, id]);
    }
  };
  const [keyword, setKeyword] = React.useState<any>('');
  const [hobby, setHobby] = React.useState<any>([]);
  const [dataHobby, setDataHobby] = React.useState<any>(hobbySelected);
  const [loading, setLoading] = React.useState<any>(false);

  async function fetchAboutUser(id, source) {
    let response = await getAboutUser(id, source);
    if (response.status === 200) {
      setAboutUser(response.data);
    }
  }

  const handleDeleteTag = index => {
    setHobbySelected(prev => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1)
    ]);
  };

  const handleUpdate = async () => {
    let response = await updateHobbyUser({ hobby_ids: arrayId });
    if (response.status === 200) {
      fetchAboutUser(meInfo?.id, null);
      handleClose();
    }
  };
  async function getListHobby(params) {
    let response = await groupCategoryApi(params);
    if (response.status === 200) {
      setHobby(
        response.data?.filter(
          (el: any) =>
            !hobbySelected.map((i: any) => i.id).includes(el.id) && el
        )
      );
    }
  }
  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (keyword && meInfo && Object.keys(meInfo).length) {
      getListHobby({
        keyword: keyword
      });
    } else {
      setHobby([]);
    }
  }, [JSON.stringify(meInfo), keyword]);

  const updateHobby = async () => {
    let response = await updateHobbyUser({
      hobby_ids: hobbySelected?.map((el: any) => el.id)
    });
    if (response.status === 200) {
      fetchAboutUser(meInfo?.id, null);
      handleClose();
    }
  };
  return (
    <>
      {!openDialogSearch && dataHobby?.length === 0 ? (
        <>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            BackdropProps={modalBackdrop}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <img src={hobbyz} alt="" style={{ width: '100%' }} />
              <Typography
                style={{ fontWeight: 'bold', fontSize: 20 }}
                textAlign={'center'}
              >
                Thêm sở thích
              </Typography>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'text.secondary',
                  textAlign: 'center'
                }}
              >
                Bạn thích làm gì? Hãy chọn các sở thích phổ biến dưới đây nhé.
              </Typography>
              <div style={{ height: 25 }}></div>
            </BootstrapDialogTitle>

            <DialogContent dividers>
              <div style={{ height: 25 }}></div>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 500,
                  color: 'text.secondary',
                  textAlign: 'center'
                }}
              >
                SỞ THÍCH ĐƯỢC ĐỀ XUẤT
              </Typography>

              <div style={{ height: 25 }}></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <div style={{ width: '400px' }}>
                  {categories?.map((el: any, index) => (
                    <StyledChip
                      sx={{
                        margin: '3px',
                        fontSize: '15px',
                        fontWeight: 500,
                        color:
                          el.action || arrayId?.find(item => item === el?.id)
                            ? 'text.custom'
                            : 'text.primary',
                        border: '1px solid #0000001a',
                        backgroundColor:
                          el.action || arrayId?.find(item => item === el?.id)
                            ? '#E7F3FF'
                            : 'none'
                      }}
                      key={index}
                      label={el?.text}
                      avatar={<Avatar src={el?.icon} />}
                      variant="outlined"
                      onClick={() => {
                        checkChip(el?.id);
                        if (el.action) {
                          setOpenDialogSearch(true);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
              <div style={{ height: 25 }}></div>
            </DialogContent>
            <DialogActions>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  flex: 6
                }}
              >
                <div style={{ flex: 5, display: 'flex', alignItems: 'center' }}>
                  <i className="fa-solid fa-earth-americas"></i> &nbsp;
                  <Typography>Sở thích hiển thị công khai</Typography>
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <ButtonInherit label="Hủy" action={handleClose} />
                  <ButtonInherit
                    label="Lưu"
                    color={buttonColor.backgroundColor}
                    action={() => {
                      handleUpdate();
                      setLoading(true);
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </>
      ) : (
        <>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            BackdropProps={modalBackdrop}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 20,
                  height: 40,
                  marginTop: 10
                }}
                textAlign={'center'}
              >
                SỞ THÍCH
              </Typography>
            </BootstrapDialogTitle>
            <div
              style={{
                margin: '10px 14px 10px 14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginRight: 0
              }}
            >
              <div style={{ width: 390 }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm sở thích "
                />
              </div>
              <ButtonInherit
                action={() => {
                  updateHobby();
                  setLoading(true);
                }}
                label="Xong"
                variant="text"
                textColor={buttonColor.backgroundColor}
                loading={loading}
                style={{ width: 50, marginRight: 12 }}
              />
            </div>
            <DialogContent>
              {hobbySelected?.length ? (
                <>
                  <div style={{ margin: '8px 0px' }}>
                    <Typography
                      style={{
                        color: '#65676b',
                        fontSize: 14,
                        fontWeight: 500
                      }}
                    >
                      ĐÃ GẮN THẺ
                    </Typography>
                  </div>
                  <div className={classes.wrapTag}>
                    {hobbySelected?.map((hobby: any, index) => (
                      <Box
                        key={index}
                        className={classes.wrapTagElement}
                        sx={{ backgroundColor: 'background.custom' }}
                      >
                        <Typography className={classes.wrapText}>
                          <Chip
                            label={hobby?.text}
                            avatar={<Avatar src={hobby?.icon} />}
                            sx={{ backgroundColor: 'background.custom' }}
                          />
                          {/* {hobby?.text} */}
                        </Typography>{' '}
                        &nbsp;
                        <div className={classes.buttonClose}>
                          <i
                            onClick={() => handleDeleteTag(index)}
                            style={{
                              color: buttonColor.backgroundColor,
                              marginTop: 3
                            }}
                            className="fal fa-times"
                          ></i>
                        </div>
                      </Box>
                    ))}
                  </div>
                </>
              ) : null}
              <div style={{ margin: '16px 0px 8px 0px' }}>
                <Typography
                  style={{ color: '#65676b', fontSize: 14, fontWeight: 500 }}
                >
                  KẾT QUẢ TÌM KIẾM
                </Typography>
              </div>
              {hobby?.length ? (
                <div style={{ margin: '8px 0px' }}>
                  {hobby?.map((el: any, index) => (
                    <Chip
                      style={{
                        margin: 5
                      }}
                      key={index}
                      label={el?.text}
                      avatar={<Avatar src={el?.icon} />}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setHobbySelected(prev => [...prev, el]);
                        setKeyword('');
                      }}
                    />
                  ))}
                </div>
              ) : (
                <div className={classes.wrapNoMedia}>
                  <Typography
                    style={{ color: '#65676b', fontSize: 15, fontWeight: 500 }}
                  >
                    Không tìm thấy sở thích nào
                  </Typography>
                </div>
              )}
            </DialogContent>
          </BootstrapDialog>
        </>
      )}
    </>
  );
}
