import React, { useEffect, useState } from 'react';import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './style.css';
import { LatLngExpression } from 'leaflet';
import L from 'leaflet';
import useGeoLocation from 'src/common/useGeoLocation';
import { IconButton } from '@mui/material';
import iconUrl from 'src/assets/images/location.png';

const icon = L.icon({
  iconUrl,
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [28, 30]
});

export default function Location(props) {
  const {
    formik,
    name,
    locationSelected,
    styleMap,
    setLocationSelected,
    setLocationUserChoose,
    isEdit,
    IsShowButtonClickMyLocation,
    draggable
  } = props;

  const [position, setPosition] = useState<LatLngExpression>([
    locationSelected?.lat ?? formik?.values[name]?.lat ?? 20.9945685,
    locationSelected?.lng ?? formik?.values[name]?.lng ?? 105.8642709
  ]);
  const mapRef = React.createRef<any>();
  const [handmade, setHandmade] = useState<any>(true);
  const ZOOM_LEVEL = 15;
  const location = useGeoLocation();
  function handleDragStart(event) {}

  function handleDrag(event) {}

  function handleDragEnd(event) {
    if (isEdit) {
      const { lat, lng } = event.target.getLatLng(); // lấy vị trí hiện tại của Marker

      setHandmade(true);

      setPosition(event.target.getLatLng());
      setLocationUserChoose && setLocationUserChoose({ lat: lat, lng: lng });
      formik &&
        formik.setFieldValue(name, {
          lat: lat,
          lng: lng
        });
    }
  }
  function MyComponent() {
    const map = useMapEvents({
      click: e => {
        if (isEdit) {
          setHandmade(true);
          map.locate();
          map.setView(e.latlng, map.getZoom(), { animate: true });
          setPosition(e.latlng);
          setLocationUserChoose &&
            setLocationUserChoose({ lat: e.latlng.lat, lng: e.latlng.lng });
          formik &&
            formik.setFieldValue(name, {
              lat: e.latlng.lat,
              lng: e.latlng.lng
            });
        }
      }
    });
    return (
      <Marker
        position={
          handmade
            ? position
            : [location.coordinates?.lat, location.coordinates?.lng]
        }
        icon={icon}
        draggable={draggable ?? true}
        eventHandlers={{
          dragstart: handleDragStart,
          drag: handleDrag,
          dragend: handleDragEnd
        }}
      />
    );
  }

  useEffect(() => {
    if (formik?.values[name]?.lat) {
      setPosition([formik.values[name]?.lat, formik.values[name]?.lng]);
      mapRef?.current?.flyTo(
        [formik.values[name]?.lat, formik.values[name]?.lng],
        ZOOM_LEVEL,
        {
          animate: true
        }
      );
    } else if (locationSelected?.lat) {
      setPosition([locationSelected?.lat, locationSelected?.lng]);
      mapRef?.current?.flyTo(
        [locationSelected?.lat, locationSelected?.lng],
        ZOOM_LEVEL,
        {
          animate: true
        }
      );
    }
  }, [formik?.values[name]?.lat, locationSelected?.lat]);

  const showMyLocation = () => {
    setHandmade(false);
    if (location.loaded && !location.error) {
      mapRef?.current.flyTo(
        [location.coordinates.lat, location.coordinates.lng],
        ZOOM_LEVEL,
        { animate: true }
      );
      setHandmade(true);
      setPosition([location.coordinates.lat, location.coordinates.lng]);
      setLocationUserChoose &&
        setLocationUserChoose({
          lat: location.coordinates.lat,
          lng: location.coordinates.lng
        });
      formik &&
        formik.setFieldValue(name, {
          lat: location.coordinates.lat,
          lng: location.coordinates.lng
        });
    } else {
      alert(location.error.message);
      setHandmade(true);
    }
  };

  return (
    <div style={{ position: 'relative', zIndex: 19 }}>
      {IsShowButtonClickMyLocation === false ? null : (
        <IconButton
          sx={{
            backgroundColor: 'background.primary',
            position: 'absolute',
            zIndex: 900,
            bottom: '10px',
            right: '10px',
            border: '1px solid #ccc'
          }}
          onClick={showMyLocation}
          disableRipple
        >
          <i
            className="fa-solid fa-location-crosshairs"
            style={{
              fontSize: 18
            }}
          ></i>
        </IconButton>
      )}

      <MapContainer
        center={position}
        zoom={ZOOM_LEVEL}
        ref={mapRef}
        style={{ height: 250, width: '100%', borderRadius: '8px', ...styleMap }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={`https://api.mapbox.com/styles/v1/thanghoa1420/cl8dw369f000114pdxdhvyrf3/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhhbmdob2ExNDIwIiwiYSI6ImNsOGNzaGNraTBvaXozcW9mOTZvemlxM3QifQ.amy7PdzwTqaolJMVQzpGSg`}
        />
        <MyComponent />
      </MapContainer>
    </div>
  );
}
