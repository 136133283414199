import * as types from 'src/constants/store/scrollSocial';

const initialState = {
  position: 'static'
};

export const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FIXED_SCROLL_BODY: {
      let type = action.payload;
      return {
        ...state,
        position: type
      };
    }
    default:
      return state;
  }
};
