import React from 'react';
import { sendMessageApi, createDirectMessage } from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';
// import { selectedUserBoxChat } from 'src/store/action/socialChatAction';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Theme
} from '@mui/material';
import { makeStyles, createStyles, styled, useTheme } from '@mui/styles';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { collapseString } from 'src/common/string';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
const InviteMembersSocialChat = (props: any) => {
  const { item } = props;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      buttonGroup: {
        '& .css-1daoff1-MuiButtonBase-root-MuiButton-root:hover': {
          backgroundColor: '#cccccc36 !important'
        },
        width: '100%',
        justifyContent: 'flex-start !important',
        color: '#333 !important',
        marginTop: '20px !important',
        padding: '12px 15px !important',
        borderRadius: '10px !important',
        fontSize: '17px !important'
      },
      sendGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      sendFriend: {
        minWidth: '30px !important',
        textTransform: 'unset !important',
        backgroundColor: `${buttonColor.backgroundColor} !important`,

        '&:hover': {
          backgroundColor: `${buttonColorHover.backgroundColor} !important`
        },
        color: '#fff !important',
        fontWeight: '500 !important',
        boxShadow: 'none !important'
      },
      textBold: {
        fontWeight: '500 !important'
      },
      textFriend: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        marginLeft: '15px !important',
        marginBottom: '5px !important'
      },
      title: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        textAlign: 'center'
      }
    })
  );
  const classes = useStyles();

  let avatar_top = {
    show_url: `${urlRocketChat}/avatar/${item?.members?.[0]}`
  };
  let avatar_bottom = {
    show_url: `${urlRocketChat}/avatar/${item?.members?.[1]}`
  };
  return (
    <ListItem>
      <>
        <ListItemAvatar style={{ position: 'relative' }}>
          {item.t === 'p' || item.t === 'c' ? (
            item?.RoomsList?.[0]?.avatarETag ? (
              <Avatar
                alt="avatar"
                sx={{
                  height: 40,
                  width: 40
                }}
                src={
                  item?.avatarETag
                    ? `${urlRocketChat}/avatar/room/${item?.rid}?etag=${item?.avatarETag}`
                    : `${urlRocketChat}/avatar/room/${item?.rid}?etag=${item?.RoomsList?.[0]?.avatarETag}`
                }
              />
            ) : (
              <>
                <AvatarSocial
                  avatarObj={item?.avatar_media?.avatar_top ?? avatar_top}
                  style={{
                    width: 30,
                    height: 30,
                    zIndex: 1
                  }}
                  isAvatarGroupChat={'yes'}
                />
                <AvatarSocial
                  type="feed"
                  avatarObj={item?.avatar_media?.avatar_bottom ?? avatar_bottom}
                  style={{
                    width: 30,
                    height: 30,
                    position: 'absolute',
                    right: '6px',
                    top: '-8px',
                    zIndex: 0
                  }}
                />
              </>
            )
          ) : (
            <Avatar
              alt="avatar"
              sx={{
                height: 40,
                width: 40
              }}
              src={item?.avatar_media?.show_url}
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography className={classes.textBold}>
              {collapseString(
                item.displayName ?? item.fname ?? item.display_name,
                24
              )}
            </Typography>
          }
        />
      </>

      <Button id={item._id} variant="contained" className={classes.sendFriend}>
        Gửi
      </Button>
    </ListItem>
  );
};

export default InviteMembersSocialChat;
