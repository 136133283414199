import * as types from 'src/constants/store/livestream';

export const updateInforLivestream = (data: any) => ({
  type: types.UPDATE_INFOR_LIVESTREAM,
  payload: {
    data
  }
});

export const resetLivestream = () => ({
  type: types.RESET_LIVESTREAM
});

export const typeLiveStream = (type: any) => ({
  type: types.TYPE_LIVESTREAM,
  payload: type
});

export const openDialogPost = (type: any) => ({
  type: types.OPEN_DIALOG_POST,
  payload: type
});

export const addLiveStreamSocket = (post: any) => ({
  type: types.ADD_LIVESTREAM_SOCKET,
  payload: post
});

export const createUpdateCommentLive = (data: any) => ({
  type: types.CREATE_NEW_COMMENT_LIVESTREAM,
  payload: data
});

export const createNewComment = (data: any) => ({
  type: types.CREATE_NEW_COMMENT,
  payload: {
    data
  }
});

export const updateTypingCommentAction = (data: any) => ({
  type: types.UPDATE_TYPING_COMMENT,
  payload: {
    data
  }
});

export const updateUserJoinLivestream = (data: any) => ({
  type: types.UPDATE_USER_JOIN_LIVESTREAM,
  payload: data
});

export const updateEyeLivestream = (count: any) => ({
  type: types.UPDATE_EYE_LIVESTREAM,
  payload: count
});

export const followPageLiveStream = (data: any) => ({
  type: types.FOLLOW_PAGE_LIVESTREAM,
  payload: data
});

export const resetLiveStreamSocket = () => ({
  type: types.RESET_LIVESTREAM_SOCKET
});

export const reactionPostLive = (data: any) => ({
  type: types.REACTIONS_POST_LIVESTREAM,
  payload: { data }
});

export const updateReactionPostLive = (data: any) => ({
  type: types.UPDATE_REACTIONS_LIVESTREAM,
  payload: {data}
})
