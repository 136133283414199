import React from 'react';
import {
  Theme,
  Typography,
  List,
  ListItem,
  Box,
  IconButton
} from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';

import { useDispatch } from 'react-redux';
import { collapseString } from 'src/common/string';
import {
  selectedUserBoxChat,
  removeUserBoxChat
} from 'src/store/action/socialChatAction';
import { subRoom } from 'src/socket/RocketChatSubscriptions';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';

function BadgeChatPlus({ listConversation }) {
  const avatarEnd =
    listConversation[listConversation.length - 1].conversation.type ===
      'user' || 'chat_page'
      ? listConversation[listConversation.length - 1].conversation.avatar_media
          ?.show_url
      : listConversation[listConversation.length - 1].conversation.avatar_media
          ?.avatar_top;
  const [showConversation, setShowConversation] = React.useState(false);
  const theme = useTheme();
  const useStyles = makeStyles(
    (theme: Theme) =>
      createStyles({
        iconClose: {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f1f2f5',
          borderRadius: '50%',
          padding: 1,
          height: 20,
          width: 20,
          right: -5,
          top: 0,
          zIndex: 40,
          border: '2px solid rgba(0,0,0,0.1)',
          '&:hover': {
            cursor: 'pointer'
          }
        },
        arrow: {
          backgroundColor: '#ffffff',
          padding: '8px 12px',
          borderRadius: '100%',
          transform: 'rotate(45deg)'
        },
        countConversation: {
          width: '100%',
          height: '100%',
          backgroundColor: '#33333375',
          borderRadius: '50%',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fafafa',
          fontSize: '14px',
          fontWeight: 600,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#33333350'
          }
        },
        customScrollbar: {
          '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
            borderRadius: '100px'
          }
        }
      }),
    { index: 1 }
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const ConversationPlus = props => {
    const { conversation } = props;
    const handleClick = () => {
      dispatch(selectedUserBoxChat(conversation));
      subRoom(conversation.rid);
    };
    const handleCloseBadge = () => {
      dispatch(removeUserBoxChat(conversation));
    };
    return (
      <>
        <ListItem
          button
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2px 8px',
            borderRadius: '4px',
            fontSize: '10px',
            fontWeight: '700'
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '700',
              cursor: 'pointer',
              color: theme.palette.mode === 'light' ? '1c1e21' : '#fafafa'
            }}
            onClick={handleClick}
          >
            {collapseString(conversation.displayName, 30)}
          </Typography>
          <IconButton>
            <i
              className="fa-solid fa-times"
              style={{
                fontSize: '12px',
                color: theme.palette.mode === 'light' ? '1c1e21' : '#fafafa'
              }}
              onClick={handleCloseBadge}
            />
          </IconButton>
        </ListItem>
      </>
    );
  };

  return (
    <Box
      style={{ position: 'relative', display: 'flex' }}
      onMouseEnter={() => setShowConversation(true)}
      onMouseLeave={() => setShowConversation(false)}
    >
      {showConversation && (
        <List
          // onMouseEnter={() => setShowConversation(true)}
          // onMouseLeave={() => setShowConversation(false)}
          sx={{
            padding: '10px',
            borderRadius: '10px',
            backgroundColor:
              theme.palette.mode === 'light' ? '#fafafa' : '#1c1e21',
            width: '200px',
            height: '120px',
            overflowY: 'auto',
            position: 'absolute',
            right: 50,
            top: -45,
            boxShadow:
              '0 2px 4px rgba(0, 0, 0, 0.1),0 12px 28px rgba(0, 0, 0, 0.2)',
            display: 'flex',

            flexDirection: 'column-reverse'
          }}
          className={classes.customScrollbar}
        >
          {listConversation.map((conversation: any) => (
            <ConversationPlus
              key={conversation.conversation._id}
              conversation={conversation.conversation}
            />
          ))}
        </List>
      )}
      <div style={{ position: 'relative', width: 45, height: 45 }}>
        <AvatarSocial
          type="feed"
          style={{
            border: 'none !important'
          }}
          avatarObj={avatarEnd}
        />
        <Box className={classes.countConversation}>
          {listConversation.length}
        </Box>
      </div>
    </Box>
  );
}

export default BadgeChatPlus;
