export const classifyTypeMessage = message => {
  if (message?.urls?.length > 0) {
    if (
      (message.urls?.[0]?.headers?.contentType &&
        !message.urls?.[0]?.headers?.contentType.includes('image') &&
        !message.urls?.[0]?.headers?.contentType.includes('audio') &&
        !message.urls?.[0]?.headers?.contentType.includes('video')) ||
      (message.urls?.[0]?.url && message.urls?.[0]?.parseUrl) ||
      (message?.metaEmso && Object.keys(message.metaEmso).length > 0)
    ) {
      return 'link';
    } else if (
      message.urls?.[0]?.headers?.contentType === 'image/gif' ||
      message.urls?.[0]?.url?.startsWith('https://media') ||
      message.urls?.[0]?.url?.startsWith('https://sdk.mojitok.im')
    ) {
      return 'gif';
    } else if (message.urls?.[0]?.ignoreParse === true) {
      return 'reply';
    } else if (
      message?.typeForwards === 'image' ||
      message.urls?.[0]?.headers?.contentType.includes('image')
    ) {
      return 'image';
    } else if (message?.typeForwards === 'video') {
      return 'video';
    } else if (message?.typeForwards === 'audio') {
      return 'audio';
    } else {
      return;
    }
  } else {
    if (message && message.alias && message.blocks) {
      return 'vote';
    }
    return message?.file?.type?.split('/')?.[0] ?? 'message';
  }
};

export const addStr = (str, index, stringToAdd) => {
  return (
    str.substring(0, index) + stringToAdd + str.substring(index, str.length)
  );
};

export const subStr = (str, index, subLength) => {
  return str.substring(0, index) + str.substring(index + subLength, str.length);
};
