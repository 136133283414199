import { Box, Typography } from '@mui/material';
import React from 'react';
import ButtonInherit from './ButtonInherit';
import ButtonOption from './ButtonOption';
import { optionDate, optionMonth, optionYear } from 'src/constants/common';
import ClickOutHandler from 'react-onclickout';
import { useTheme } from '@mui/styles';
import { lightTheme } from 'src/App/AppColor';
import { buttonColor, buttonColorHover } from 'src/constants/styles';

interface Props {
  style?: React.CSSProperties;
  action?: any;
  styleButton?: React.CSSProperties | any;
}

const ButtonTime = (props: Props) => {
  const { style, action, styleButton } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dataUpdate, setDataUpdate] = React.useState<any>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    date: new Date().getDate()
  });
  const theme: any = useTheme();

  const handleClick = (event: any) => {
    if (anchorEl) {
      handleCloseMenu();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (action) {
      action(dataUpdate);
    }
  }, [JSON.stringify(dataUpdate)]);

  const ButtonSelect = () => {
    return (
      <>
        <ClickOutHandler onClickOut={handleClick}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                zIndex: 100,
                position: 'absolute',
                width: style?.width,
                borderRadius: '10px',
                backgroundColor:
                  theme?.palette?.mode === 'dark'
                    ? '#242526'
                    : lightTheme?.palette?.myTextColor?.primary,
                bottom: '50px',
                padding: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  'rgb(0, 0, 0, 0.2) 0px 5px 5px -3px, rgb(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonOption
                  style={{ ...styleButton }}
                  buttonDisplay={`${dataUpdate.date}`}
                  options={optionDate}
                  optionSelected={optionDate?.find(
                    (el: any) => el.id === +dataUpdate.date
                  )}
                  setOptionSelected={item => {
                    setDataUpdate(prev => ({
                      ...prev,
                      date: item.id
                    }));
                  }}
                />
                <ButtonOption
                  buttonDisplay={`Tháng ${dataUpdate.month}`}
                  options={optionMonth}
                  optionSelected={optionMonth?.find(
                    (el: any) => el.id === +dataUpdate.month
                  )}
                  setOptionSelected={item => {
                    setDataUpdate(prev => ({
                      ...prev,
                      month: item.id
                    }));
                  }}
                  style={{ ...styleButton }}
                />

                <ButtonOption
                  buttonDisplay={`${dataUpdate.year}`}
                  options={optionYear}
                  optionSelected={optionYear?.find(
                    (el: any) => el?.id === +dataUpdate.year
                  )}
                  setOptionSelected={item => {
                    setDataUpdate(prev => ({
                      ...prev,
                      year: item.id
                    }));
                  }}
                  style={{
                    ...styleButton
                  }}
                />
              </div>
            </Box>
          </div>
        </ClickOutHandler>
      </>
    );
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Box
          onClick={handleClick}
          sx={{
            backgroundColor: 'button.primary.background'
          }}
        >
          <ButtonInherit
            style={{
              width: '100%',
              backgroundColor: buttonColor.backgroundColor,
              color: 'white',
              '&:hover': {
                backgroundColor: buttonColorHover.backgroundColor,
                color: lightTheme?.palette?.myTextColor?.primary
              }
            }}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <i className="fa-regular fa-calendar"></i> &nbsp;
                <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                  {`${dataUpdate.date}, tháng ${dataUpdate.month}, ${dataUpdate.year}`}
                </Typography>
              </Box>
            }
          />
        </Box>

        {anchorEl && <ButtonSelect />}
      </div>
    </>
  );
};

export default ButtonTime;
