import * as types from 'src/constants/store/paymentSocial';const initialState = {
  status: false
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_PAYMENT: {
      const type = action.payload;
      return {
        ...state,
        status: type === 'success' ? true : false
      };
    }
    default: {
      return state;
    }
  }
};
