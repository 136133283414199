import React from 'react';
import Routes from 'src/routes/routes';
import { CssBaseline } from '@mui/material';
import { withTheme } from '@mui/styles';
import './App.css';
import '../assets/scss/myScss/app.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from 'src/constants/common';
import { SnackbarProvider } from 'notistack';
function App() {
  const token: any = localStorage.getItem('token');

  React.useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  React.useEffect(() => {
    if (!token) {
      localStorage.setItem('darkThemeUser', 'light');
    }
  }, []);

  return (
    <div className="app">
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <CssBaseline />
          <Routes />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </div>
  );
}

export default withTheme(App);
