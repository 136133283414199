import { Theme } from '@mui/material';import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { EnumEmojiReactType } from 'src/constants/enum/emojiReact';
import { staticImgReaction } from 'src/helpers/common';
import '../ReactEmojiItem/Emoji.css';
import { buttonColor } from 'src/constants/styles';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      img: {
        width: '20px',
        animation: `$reactIcon 400ms ease-in`
      },
      '@keyframes reactIcon': {
        '0%': {
          transform: 'scale(1.15) rotate(-7deg)'
        },
        '75%': {
          transform: 'scale(1.3) rotate(-15deg)'
        },
        '100%': {
          transform: 'scale(1) rotate(0)'
        }
      },
      like: {
        width: '18px',
        animation: `$reactIcon 400ms ease-in`
      },
      love: {
        width: '28px',
        animation: `$reactIcon 400ms ease-in`
      },
      yay: {
        width: '30px',
        animation: `$reactIcon 400ms ease-in`
      },
      haha: {
        width: '23px',
        animation: `$reactIcon 400ms ease-in`
      },
      wow: {
        width: '21px',
        animation: `$reactIcon 400ms ease-in`
      },
      angry: {
        width: '18px',
        animation: `$reactIcon 400ms ease-in`
      },
      sad: {
        width: '20px',
        animation: `$reactIcon 400ms ease-in`
      }
    }),
  { index: 1 }
);

function Index(props) {
  const classes = useStyles();
  let { id, style, styleIcon, type } = props;
  let emoji;
  switch (id) {
    case EnumEmojiReactType.like:
      emoji =
        type === 'gif' ? (
          <span className="emoji-like"></span>
        ) : (
          <span className={classes.img}>
            <i
              style={{ fontSize: 20, color: buttonColor.backgroundColor }}
              className="fas fa-thumbs-up"
            ></i>
          </span>
        );
      break;
    case EnumEmojiReactType.love:
      emoji =
        type === 'gif' ? (
          <span className="emoji-love"></span>
        ) : (
          <img
            className={classes.love}
            style={{ ...styleIcon?.love }}
            src={staticImgReaction.love}
            alt=""
          />
        );
      break;
    case EnumEmojiReactType.haha:
      emoji =
        type === 'gif' ? (
          <span className="emoji-haha"></span>
        ) : (
          <img
            className={classes.haha}
            style={{ ...styleIcon?.haha }}
            src={staticImgReaction.haha}
            alt=""
          />
        );
      break;
    case EnumEmojiReactType.yay:
      emoji =
        type === 'gif' ? (
          <span className="emoji-yay"></span>
        ) : (
          <img
            className={classes.yay}
            style={{ ...styleIcon?.yay }}
            src={staticImgReaction.yay}
            alt=""
          />
        );
      break;
    case EnumEmojiReactType.wow:
      emoji =
        type === 'gif' ? (
          <span className="emoji-wow"></span>
        ) : (
          <img
            className={classes.wow}
            style={{ ...styleIcon?.wow }}
            src={staticImgReaction.wow}
            alt=""
          />
        );
      break;
    case EnumEmojiReactType.sad:
      emoji =
        type === 'gif' ? (
          <span className="emoji-sad"></span>
        ) : (
          <img
            className={classes.sad}
            style={{ ...styleIcon?.sad }}
            src={staticImgReaction.sad}
            alt=""
          />
        );
      break;
    case EnumEmojiReactType.angry:
      emoji =
        type === 'gif' ? (
          <span className="emoji-angry"></span>
        ) : (
          <img
            className={classes.angry}
            style={{ ...styleIcon?.angry }}
            src={staticImgReaction.angry}
            alt=""
          />
        );
      break;
    default:
      emoji = (
        <span>
          <i style={{ fontSize: 20 }} className="fal fa-thumbs-up"></i>
        </span>
      );
      break;
  }
  return (
    <div style={style} className="popover-content">
      <span style={{ height: '100%', display: 'flex' }}>{emoji}</span>
    </div>
  );
}

export default Index;
