import React from 'react';
import {
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { urlRocketChat } from 'src/util/config';

import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { handleActionVote } from 'src/apis/socialChat.apis';
import { buttonColor } from 'src/constants/styles';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

export function DetailMessageVoted(props) {
  const {
    message,
    conversation,
    myMessageStyle,
    senderMessageStyle,
    mainTopic,
    typeBoxChat,
    renderPostReactEmojis
  } = props;
  const theme = useTheme();
  const [openDialogViewResultVote, setOpenDialogViewResultVote] =
    React.useState<boolean>(false);
  const handleActionVoted = async (message, voteIndex, action) => {
    // target.attributes[3].value
    try {
      const response = await handleActionVote(message, voteIndex, action);
      if (response.status === 200) {
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const buttonOption = message.blocks.filter(
    el => el && el.accessory && el.accessory.type === 'button'
  );
  const [openDialogViewOptionVote, setOpenDialogViewOptionVote] =
    React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [persons, setPersons] = React.useState<any>({});
  const ViewResultOptionVote = props => {
    const { title, persons, open, setOpen } = props;
    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
              setOpenDialogViewResultVote(true);
            }}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              padding: '12px 0',
              flexDirection: 'column'
            }}
          >
            {persons.map((person: any) => (
              <Box
                key={person.id}
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <AvatarSocial
                  type="feed"
                  style={{ width: 30, height: 30 }}
                  avatarObj={`${urlRocketChat}/avatar/${person.username}`}
                />
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                  {person.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  const convertDate = str => {
    const date = new Date(str);
    return `${date.toLocaleDateString('vn-VN')} - ${date.toLocaleTimeString(
      'vn-VN'
    )}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: message?.u?._id === rocketId ? 'flex-end' : 'flex-start',
        flexDirection: 'column',
        position: 'relative'
      }}
    >
      <Box
        id={message?._id}
        sx={
          conversation && !conversation.user_chat_page
            ? message?.u?._id === rocketId
              ? myMessageStyle.box
              : senderMessageStyle.box
            : message?.u?._id !== conversation?.user_chat_page?._id
            ? myMessageStyle.box
            : senderMessageStyle.box
        }
        style={{
          position: 'relative',
          width: typeBoxChat === 'fullScreen' ? '320px' : '220px',
          background:
            conversation && !conversation.user_chat_page
              ? message?.u?._id === rocketId
                ? mainTopic
                  ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                  : buttonColor.backgroundColor
                : theme.palette.mode === 'light'
                ? '#e4e6eb'
                : '#3e4042'
              : message?.u?._id !== conversation?.user_chat_page?._id
              ? mainTopic
                ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                : buttonColor.backgroundColor
              : theme.palette.mode === 'light'
              ? '#e4e6eb'
              : '#3e4042',
          color:
            conversation && !conversation.user_chat_page
              ? message?.u?._id === rocketId
                ? '#fff'
                : theme.palette.mode === 'light'
                ? '#050505'
                : '#fff'
              : message?.u?._id !== conversation?.user_chat_page?._id
              ? '#fff'
              : theme.palette.mode === 'light'
              ? '#050505'
              : '#fff',
          borderRadius: '24px',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          padding: '12px',
          height: '100%',
          flexDirection: 'column'
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            textAlign: 'center',
            padding: '8px 0 '
          }}
        >
          {message.msg}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 1,
            padding: '8px 0 24px'
          }}
        >
          {message &&
            message.blocks &&
            message.blocks[1].type === 'context' && (
              <i
                style={{
                  fontSize: '12px',
                  textTransform: 'inherit',
                  opacity: 0.8
                }}
              >
                Kết thúc lúc {convertDate(message.blocks[1].elements[0].text)}
              </i>
            )}

          {message &&
            message.blocks &&
            buttonOption.slice(0, 2).map((el: any, index: number) => {
              const isSectionVote =
                el.accessory &&
                el.accessory.result &&
                message.blocks[1].type !== 'context' &&
                el.accessory.result.vote_person.length > 0 &&
                el.accessory.result.vote_person.find(el => el.id === rocketId);
              if (el && el.accessory && el.accessory.type === 'button') {
                return (
                  <Button
                    fullWidth
                    variant={
                      message &&
                      message.blocks &&
                      message.blocks[1].type === 'context' &&
                      'contained'
                    }
                    key={index}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '12px',
                      background: isSectionVote
                        ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                        : '#fafafa',
                      textTransform: 'none',
                      fontSize: '16px',
                      padding: '12px 20px',
                      color:
                        theme.palette.mode === 'light' ? '#373534' : '#111',

                      '&:hover': {
                        background:
                          message &&
                          message.blocks &&
                          message.blocks[1].type !== 'context'
                            ? `linear-gradient(to bottom, ${mainTopic?.[0]}, ${mainTopic?.[1]})`
                            : 'none'
                      }
                    }}
                    endIcon={
                      <p
                        style={{
                          fontSize: '18px',
                          fontWeight: 600,
                          color:
                            theme.palette.mode === 'light' ? '#373534' : '#111'
                        }}
                      >
                        {el.accessory.result &&
                          el.accessory.result.vote_quantity}
                      </p>
                    }
                    disabled={
                      message &&
                      message.blocks &&
                      message.blocks[1].type === 'context'
                        ? true
                        : false
                    }
                    onClick={() => handleActionVoted(message, index, 'vote')}
                  >
                    {el.text && el.text.text}
                  </Button>
                );
              }
            })}
        </Box>
        {message.blocks && message.blocks.length > 3 && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {buttonOption.length > 2 && (
              <Typography
                sx={{
                  width: 'fit',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 400
                }}
              >
                {buttonOption.length - 2} lựa chọn khác
              </Typography>
            )}
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
          onClick={() => setOpenDialogViewResultVote(true)}
        >
          <Typography
            sx={{
              width: 'fit',
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: '12px',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 600,
              cursor: 'pointer'
            }}
          >
            Xem tất cả
          </Typography>
        </Box>
        {message.reactions ? renderPostReactEmojis() : null}
      </Box>

      {openDialogViewResultVote && (
        <Dialog
          open={openDialogViewResultVote}
          onClose={() => setOpenDialogViewResultVote(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            {message.msg}
            <IconButton
              aria-label="close"
              onClick={() => setOpenDialogViewResultVote(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                padding: '12px 0',
                alignItems: 'center'
              }}
            >
              <AvatarSocial
                type="feed"
                style={{ width: 30, height: 30, marginRight: '4px' }}
                avatarObj={`${urlRocketChat}/avatar/${message.alias}`}
              />
              <Typography sx={{ fontSize: '16px' }}>
                đã tạo cuộc khảo sát tới{' '}
                <span style={{ fontWeight: 600 }}>{conversation.fname}</span>
              </Typography>
            </Box>
            <Divider />
            {message.alias === meInfo.username &&
              message &&
              message.blocks &&
              message.blocks[1].type !== 'context' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      padding: '12px 0',
                      alignItems: 'center'
                    }}
                  >
                    <Typography sx={{ fontSize: '16px' }}>
                      Kết thúc cuộc thăm dò{' '}
                    </Typography>
                    {message &&
                      message.blocks &&
                      message.blocks[1].type !== 'context' && (
                        <Checkbox
                          icon={<i className="fa-light fa-circle"></i>}
                          checkedIcon={
                            <i className="fa-solid fa-circle-check"></i>
                          }
                          sx={{
                            fontSize: '30px',
                            '&:hover': { background: 'none' }
                          }}
                          onClick={() =>
                            handleActionVoted(message, 'finish', 'finish')
                          }
                          checked={
                            message &&
                            message.blocks &&
                            message.blocks[1].type === 'context'
                              ? true
                              : false
                          }
                          disabled={
                            message &&
                            message.blocks &&
                            message.blocks[1].type === 'context'
                              ? true
                              : false
                          }
                        />
                      )}
                  </Box>
                  <Divider />
                </>
              )}

            {message &&
              message.blocks &&
              message.blocks[1].type === 'context' && (
                <>
                  <Typography sx={{ fontSize: '16px', padding: '8px' }}>
                    Kết thúc cuộc thăm dò{' '}
                    {message &&
                      message.blocks &&
                      message.blocks[1].type === 'context' &&
                      message.blocks[1].elements[0].text}
                  </Typography>
                  <Divider />
                </>
              )}

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                padding: '12px 0',
                flexDirection: 'column'
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                Lựa chọn
              </Typography>
              {message.blocks.map((el: any, index) => {
                const isSectionVote =
                  el.accessory &&
                  el.accessory.result &&
                  el.accessory.result.vote_person &&
                  el.accessory.result.vote_person.length > 0 &&
                  el.accessory.result.vote_person.find(
                    el => el.id === rocketId
                  );
                if (el && el.accessory && el.accessory.type === 'button')
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        gap: 2
                      }}
                      key={index}
                    >
                      <Checkbox
                        onClick={(e: any) => {
                          handleActionVoted(
                            message,
                            (
                              Number(e.target.attributes.value.value) - 1
                            ).toString(),
                            'vote'
                          );
                        }}
                        icon={<i className="fa-light fa-circle"></i>}
                        checkedIcon={
                          <i className="fa-solid fa-circle-check"></i>
                        }
                        sx={{
                          fontSize: '30px',
                          '&:hover': { background: 'none' }
                        }}
                        value={index}
                        checked={isSectionVote ? true : false}
                        disabled={
                          message &&
                          message.blocks &&
                          message.blocks[1].type === 'context'
                            ? true
                            : false
                        }
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                          gap: '4px'
                        }}
                      >
                        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                          {el.text && el.text.text}
                        </Typography>
                        <Box
                          sx={{
                            width: '60%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            style={{
                              position: 'relative',
                              flex: 1
                            }}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={
                                el.accessory?.result &&
                                el.accessory.result.vote_percent
                              }
                            />
                            <Typography
                              style={{
                                position: 'absolute',
                                right: '0',
                                fontSize: '18px',
                                fontWeight: 600,
                                top: '12px'
                              }}
                            >
                              {el.accessory.result.vote_percent > 0
                                ? `${el.accessory.result.vote_percent}%`
                                : ''}
                            </Typography>
                          </Box>
                          {el &&
                          el.accessory &&
                          el.accessory.result &&
                          el.accessory.result.vote_person.length > 0 ? (
                            <IconButton
                              aria-label="open"
                              onClick={() => {
                                setTitle(el?.text?.text);
                                setPersons(el?.accessory?.result?.vote_person);
                                setOpenDialogViewOptionVote(true);
                                setOpenDialogViewResultVote(false);
                              }}
                            >
                              <ArrowForwardIosIcon />
                            </IconButton>
                          ) : (
                            <Box sx={{ width: 40, height: 40 }}></Box>
                          )}
                        </Box>

                        <AvatarGroup
                          sx={{ display: 'flex', justifyContent: 'start' }}
                        >
                          {el.accessory &&
                            el.accessory.result &&
                            el.accessory.result.vote_person.length > 0 &&
                            el.accessory.result.vote_person.map(el => (
                              <AvatarSocial
                                type="feed"
                                style={{ width: 24, height: 24 }}
                                avatarObj={`${urlRocketChat}/avatar/${el.username}`}
                              />
                            ))}
                        </AvatarGroup>
                        <Divider />
                      </Box>
                    </Box>
                  );
              })}
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {openDialogViewOptionVote && (
        <ViewResultOptionVote
          title={title}
          persons={persons}
          open={openDialogViewOptionVote}
          setOpen={setOpenDialogViewOptionVote}
        />
      )}
    </div>
  );
}
