import { call, put, takeEvery } from 'redux-saga/effects';
import { getInfoMe } from 'src/apis/user.api';
import * as types from 'src/constants/store/user';
import * as actions from 'src/store/action/userAction';
import * as actionsCache from 'src/store/action/saveUser';
import { push } from 'connected-react-router';
const typesUser: any = types;

export const getInfoMeSagas = function* () {
  try {
    let response = yield call(getInfoMe);
    if (response.status === 200) {
      let { data } = response;
      let infoMe = {
        ...data
      };
      localStorage.setItem('userId', data.id);
      localStorage.setItem('darkThemeUser', data.theme ?? 'light');
      localStorage.setItem('me', JSON.stringify(data));
      yield put(actions.getInfoMeSuccess(infoMe));
      yield put(actionsCache.saveMeInfor(infoMe));
    }
  } catch (err: any) {
    let infoError = {
      code: err?.response?.status,
      message: err?.response?.data?.error
    };
    const dataMe: any = localStorage.getItem('me');
    if (
      err.response.status === 500 ||
      err.response.status === 504 ||
      err.response.status === 502
    ) {
      yield put(actions.getInfoMeSuccess(JSON.parse(dataMe)));
      yield put(actionsCache.saveMeInfor(JSON.parse(dataMe)));
    } else {
      yield put(push('/login'));
      yield put(actions.getInfoMeFailed(infoError));
    }
  }
};

export function* watchUserAsync() {
  yield takeEvery(typesUser.GET_INFO_ME_REQ, getInfoMeSagas);
}
