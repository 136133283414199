import React from 'react';import { Checkbox, Radio, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getPeopleVotePostApi } from 'src/apis/socialPost.api';
import DialogShowPeopleVotes from 'src/components/Dialog/DialogShowPeopleVotes';

const useStyles = makeStyles((theme: any) => ({
  wrapPoll: {
    padding: '0px 15px 0px 0px'
  },
  divOption: {
    padding: 10,
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 5,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  divOptionMax: {
    padding: 10,
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: 5,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.button.primary.background
  },
  divWrapOption: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px'
  },
  divBottomPoll: {
    margin: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  spanButton: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  wrapAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12
  }
}));

const Poll = React.memo((props: any) => {
  const classes = useStyles();
  const { info, handleVote, handleChangeVote } = props;

  const [openShowVotes, setOpenShowVotes] = React.useState(false);
  const [listPeopleVotes, setListPeopleVotes] = React.useState<any>([]);
  const [dataVotes, setDataVotes] = React.useState<any>(
    info?.poll?.own_votes?.map((el: any) => `${el}`) ?? []
  );

  let options = info?.poll?.options || [];
  let totalVotes = info?.poll?.options?.reduce(
    (total, item) => total + item.votes_count,
    0
  );
  let voteMax =
    Math.max(...info?.poll?.options?.map((el: any) => el.votes_count)) ?? 0;

  let timeStamps =
    new Date(info?.poll?.expires_at).getTime() - new Date().getTime();

  const renderTimeExpires = time => {
    if (Math.floor(timeStamps / (24 * 3600 * 1000)) > 0) {
      return `${Math.floor(timeStamps / (24 * 3600 * 1000))} ngày`;
    } else if (Math.floor(timeStamps / (60 * 60 * 1000))) {
      return `${Math.floor(timeStamps / (60 * 60 * 1000))} giờ`;
    } else {
      return `${new Date(time).getMinutes()} phút`;
    }
  };

  const handleCheck = (item, index, type) => {
    if (type === 'multiple') {
      if (!dataVotes?.includes(`${index}`)) {
        setDataVotes([...dataVotes, `${index}`]);
      } else {
        let indexId = dataVotes?.findIndex(el => +el === index);
        setDataVotes([
          ...dataVotes.slice(0, indexId),
          ...dataVotes.slice(indexId + 1)
        ]);
      }
    } else {
      setDataVotes([`${index}`]);
    }
  };
  React.useEffect(() => {
    if (info?.poll?.own_votes.length) {
      handleChangeVote(info.poll.id, {
        choices: dataVotes
      });
    } else {
      handleVote(info.poll.id, {
        choices: dataVotes
      });
    }
  }, [dataVotes]);
  const handleShowVote = async index => {
    setOpenShowVotes(true);
    let data = {
      choice: index
    };
    let response = await getPeopleVotePostApi(info?.poll?.id, data);

    setListPeopleVotes(response.data);
  };

  return (
    <div className={classes.wrapPoll}>
      {options?.map((item: any, index: any) => (
        <div key={`vote-${index}`} className={classes.divWrapOption}>
          {!info.poll.multiple ? (
            <Radio
              key={`radio-${index}`}
              disabled={info?.poll.expired}
              checked={dataVotes?.includes(`${index}`)}
              onChange={() => handleCheck(item, index, 'unmultiple')}
            />
          ) : (
            <Checkbox
              key={`checkbox-${index}`}
              disabled={info?.poll.expired}
              checked={dataVotes?.includes(`${index}`)}
              onChange={() => handleCheck(item, index, 'multiple')}
            />
          )}
          <div
            key={`divVote-${index}`}
            className={
              item.votes_count === voteMax && item.votes_count
                ? classes.divOptionMax
                : classes.divOption
            }
          >
            <span>{item.title}</span>
            <span
              className={item.votes_count ? classes.spanButton : 'undefined'}
              onClick={() => item.votes_count && handleShowVote(index)}
            >
              {item.votes_count} lượt bình chọn
            </span>
          </div>
        </div>
      ))}
      <div className={classes.wrapAction}>
        <div className={classes.divBottomPoll}>
          <span style={{ fontSize: 14 }}>Tổng số lượt vote: {totalVotes}</span>{' '}
          . &nbsp;
          <span style={{ fontSize: 14 }}>
            {timeStamps > 0
              ? `Thời gian còn lại: ${renderTimeExpires(timeStamps)}`
              : 'Đã kết thúc'}
          </span>
        </div>
      </div>

      <DialogShowPeopleVotes
        open={openShowVotes}
        setOpen={setOpenShowVotes}
        listPeopleVotes={listPeopleVotes}
      />
    </div>
  );
});

export default Poll;
