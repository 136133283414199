import React from 'react';import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  title: {
    color: 'white',
    fontSize: '40px !important',
    fontWeight: '600 !important',
    textAlign: 'center',
    width: '100%'
  },
  button: {},
  wrapIcon: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
const PictureGrid = props => {
  const classes = useStyles();

  const { images, imagesType, removeFiles } = props;

  const isAVideo = path => {
    if (path?.includes('video')) {
      return true;
    }

    return false;
  };

  const hasAVideo = paths => {
    var hasVideo = false;
    paths.map(path => {
      if (path?.includes('video')) {
        hasVideo = true;
      }
    });

    return hasVideo;
  };

  const renderIcon = preview => {
    return (
      <div onClick={() => removeFiles(preview)} className={classes.wrapIcon}>
        <i style={{ zIndex: 10 }} className="fa-light fa-trash-can"></i>
      </div>
    );
  };

  const displayImage = images => {
    if (images) {
      if (images.length === 1) {
        if (isAVideo(imagesType[0])) {
          return (
            <Grid
              style={{ position: 'relative' }}
              container
              direction="row"
              justify="center"
            >
              {renderIcon(images[0])}
              <video
                className={classes.button}
                style={{
                  backgroundColor: '#000',
                  maxHeight: '482px',
                  minHeight: '282px'
                }}
                width={'100%'}
                height={'auto'}
                src={images[0]}
                controls
              />
            </Grid>
          );
        } else {
          return (
            <div container justify="center">
              {renderIcon(images[0])}
              <img
                style={{
                  marginBottom: -6,
                  height: '482px',
                  width: '100%',
                  objectFit: 'cover'
                }}
                src={images[0]}
                alt="images"
              />
            </div>
          );
        }
      } else if (images.length === 2) {
        if (hasAVideo(imagesType)) {
          return (
            <Grid spacing={0.5} container>
              {images.map((image, index) => {
                if (isAVideo(imagesType[index])) {
                  return (
                    <Grid
                      style={{ position: 'relative' }}
                      key={index}
                      item
                      xs={6}
                    >
                      {renderIcon(image)}
                      <Grid container direction="row" justify="center">
                        <video
                          style={{
                            backgroundColor: '#000'
                          }}
                          className={classes.button}
                          width="100%"
                          height="482"
                          src={image}
                          controls
                        />
                      </Grid>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${image}`,
                        width: '100%',
                        height: 482,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative'
                      }}
                      className={classes.button}
                    >
                      {renderIcon(image)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        } else {
          return (
            <Grid container>
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{ padding: '1px !important' }}
                    item
                    xs={6}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${image}`,
                        width: 'auto',
                        height: 400,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative'
                      }}
                      className={classes.button}
                    >
                      {renderIcon(image)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        }
      } else if (images.length === 3) {
        return (
          <Grid container spacing={0.5}>
            <Grid
              key={0}
              sx={{ padding: '1px !important', position: 'relative' }}
              item
              xs={6}
            >
              {renderIcon(images[0])}
              {isAVideo(imagesType[0]) ? (
                <Grid container direction="row" justify="center">
                  <video
                    style={{
                      backgroundColor: '#000'
                    }}
                    className={classes.button}
                    width="100%"
                    height="400"
                    src={images[0]}
                    controls
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{
                    backgroundImage: `url(${images[0]}`,
                    height: 400,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                  className={classes.button}
                ></Grid>
              )}
            </Grid>
            <Grid sx={{ padding: '1px !important' }} item xs={6}>
              {images.map((image, index) => {
                if (index != 0) {
                  if (isAVideo(imagesType[index])) {
                    return (
                      <Grid
                        style={{ position: 'relative' }}
                        key={index}
                        item
                        xs={12}
                      >
                        {renderIcon(image)}
                        <Grid container direction="row" justify="center">
                          <video
                            style={{
                              backgroundColor: '#000',
                              maxHeight: 205,
                              marginTop:
                                index === 2 && isAVideo(imagesType[index - 1])
                                  ? 2
                                  : 0
                            }}
                            width="100%"
                            height="199z"
                            src={image}
                            controls
                            className={classes.button}
                          />
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        key={index}
                        container
                        direction="row"
                        justify="center"
                        style={{
                          backgroundImage: `url(${image}`,
                          width: 'auto',
                          height: 199,
                          marginBottom: 2,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          position: 'relative'
                        }}
                        className={classes.button}
                      >
                        {renderIcon(image)}
                      </Grid>
                    );
                  }
                }
              })}
            </Grid>
          </Grid>
        );
      } else if (images.length === 4) {
        return (
          <Grid container spacing={0.5}>
            <Grid
              sx={{ padding: '1px !important', position: 'relative' }}
              item
              xs={7}
            >
              {renderIcon(images[0])}
              {isAVideo(imagesType[0]) ? (
                <Grid container direction="row" justify="center">
                  <video
                    style={{
                      backgroundColor: '#000'
                    }}
                    className={classes.button}
                    width="100%"
                    height="400"
                    src={images[0]}
                    controls
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  style={{
                    backgroundImage: `url(${images[0]}`,
                    // width: 250,
                    height: 400,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                  className={classes.button}
                ></Grid>
              )}
            </Grid>{' '}
            <Grid sx={{ padding: '1px !important' }} item xs={5}>
              {images.map((image, index) => {
                if (index != 0) {
                  if (isAVideo(imagesType[index])) {
                    return (
                      <Grid
                        style={{ position: 'relative' }}
                        key={index}
                        item
                        xs={12}
                      >
                        {renderIcon(image)}
                        <Grid container direction="row" justify="center">
                          <video
                            style={{
                              backgroundColor: '#000',
                              margin:
                                (index === 2 || index === 3) &&
                                isAVideo(imagesType[index - 1])
                                  ? '2px 0px 0px 0px'
                                  : '0px'
                            }}
                            width="100%"
                            height="131.8"
                            src={image}
                            controls
                            className={classes.button}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                  return (
                    <Grid
                      key={index}
                      container
                      direction="row"
                      justify="center"
                      style={{
                        backgroundImage: `url(${image}`,
                        width: 'auto',
                        height: 131.8,
                        marginBottom: 2,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative'
                      }}
                      className={classes.button}
                    >
                      {renderIcon(image)}
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid style={{ position: 'relative' }} spacing={0.2} container>
            {renderIcon(images[0])}
            <Grid item xs={6}>
              {isAVideo(imagesType[0]) ? (
                <Grid container direction="row" justify="center">
                  <video
                    style={{
                      backgroundColor: '#000'
                    }}
                    className={classes.button}
                    width="100%"
                    height="292"
                    src={images[0]}
                    controls
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  sx={{ padding: '1px !important' }}
                  style={{
                    backgroundImage: `url(${images[0]}`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: 292
                  }}
                  className={classes.button}
                ></Grid>
              )}
            </Grid>{' '}
            <Grid style={{ position: 'relative' }} item xs={6}>
              {renderIcon(images[1])}
              {isAVideo(imagesType[1]) ? (
                <Grid container direction="row" justify="center">
                  <video
                    style={{
                      backgroundColor: '#000'
                    }}
                    className={classes.button}
                    width="100%"
                    height="292"
                    src={images[1]}
                    controls
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justify="center"
                  sx={{ padding: '1px !important' }}
                  style={{
                    backgroundImage: `url(${images[1]}`,
                    paddingRight: 5,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: 292
                  }}
                  className={classes.button}
                ></Grid>
              )}
            </Grid>
            <Grid spacing={0.2} container item xs={12}>
              {images.map((image, index) => {
                if (index >= 2 && index <= 3) {
                  return (
                    <Grid
                      key={index}
                      sx={{ padding: '1px !important', position: 'relative' }}
                      item
                      xs={4}
                    >
                      {renderIcon(image)}
                      {isAVideo(imagesType[index]) ? (
                        <Grid container direction="row" justify="center">
                          <video
                            style={{
                              backgroundColor: '#000'
                            }}
                            className={classes.button}
                            width="100%"
                            height="190"
                            src={images[index]}
                            controls
                          />
                        </Grid>
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          style={{
                            backgroundImage: `url(${image}`,
                            width: 'auto',
                            height: 190,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                          }}
                          className={classes.button}
                        ></Grid>
                      )}
                    </Grid>
                  );
                } else if (index === 4) {
                  return (
                    <Grid style={{ position: 'relative' }} key={4} item xs={4}>
                      {renderIcon(images[4])}
                      {isAVideo(imagesType[4]) ? (
                        <Grid
                          style={{ position: 'relative' }}
                          container
                          direction="row"
                          justify="center"
                        >
                          <video
                            style={{
                              backgroundColor: '#000'
                            }}
                            className={classes.button}
                            width="100%"
                            height="190"
                            src={images[4]}
                            controls
                          />
                          {images.length - index - 1 ? (
                            <Typography
                              style={{
                                position: 'absolute',
                                zIndex: 1500,
                                top: 70,
                                height: 190
                              }}
                              className={classes.title}
                            >
                              +{images.length - index - 1}
                            </Typography>
                          ) : null}
                        </Grid>
                      ) : (
                        <Grid
                          container
                          sx={{ padding: '1px !important' }}
                          direction="row"
                          justify="center"
                          alignItems="center"
                          style={{
                            background: 'gray',
                            width: 'auto',
                            height: 190,
                            cursor: 'pointer',
                            backgroundImage: `url(${images[index]})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'none'
                          }}
                          className={classes.button}
                        >
                          {images.length - index - 1 ? (
                            <Typography className={classes.title}>
                              +{images.length - index - 1}
                            </Typography>
                          ) : null}
                        </Grid>
                      )}
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        );
      }
    }
  };
  return (
    <div style={{ width: '100%' }}>
      {displayImage(images) != null && <div>{displayImage(images)}</div>}
    </div>
  );
};

export default React.memo(PictureGrid);
