import * as React from 'react';
import DataItem from './DataItem';
import { Typography } from '@mui/material';

export default function FolderList(props) {
  const { userInfo, relationshipInfo, lifeEvents, buttonSocial } = props;
  const [listWork, setListWork] = React.useState<any>([]);
  const [listEducation, setListEducation] = React.useState<any>([]);
  const [listSocial, setListSocial] = React.useState<any>([]);
  const [listWeblink, setListWebLink] = React.useState<any>([]);
  const filterWorkAndEducation = () => {
    let arrayWork: any = [];
    let arrayEducation: any = [];
    lifeEvents?.map((el: any) =>
      !el?.life_event?.school_type
        ? arrayWork.push({
            icon: 'fas fa-briefcase fa-xl',
            text:
              `${
                el?.life_event?.position
                  ? el?.life_event?.position + ' tại '
                  : ''
              }` +
              ` ${
                el?.life_event?.company
                  ? el?.life_event?.company
                  : el?.life_event?.name
              }` +
              `${
                el?.life_event?.name && el?.life_event?.place
                  ? ' tại ' + el?.life_event?.place?.title
                  : ''
              }`
          })
        : el?.life_event?.school_type
        ? arrayEducation.push({
            icon: 'fas fa-graduation-cap fa-xl',
            text:
              `${
                el?.life_event?.school_type === 'UNIVERSITY'
                  ? el?.life_event?.currently
                    ? 'Từng học'
                    : el?.life_event?.life_event_category?.name ?? 'Học'
                  : el?.life_event?.currently
                  ? 'Đã học'
                  : el?.life_event?.life_event_category?.name ?? 'Học'
              }` +
              `${
                el?.life_event?.company
                  ? ' tại ' + el?.life_event?.company
                  : el?.life_event?.place
                  ? ' tại ' + el?.life_event?.place?.title
                  : ''
              }`
          })
        : null
    );
    setListWork(arrayWork);
    setListEducation(arrayEducation);
  };

  React.useEffect(() => {
    if (lifeEvents?.length) {
      filterWorkAndEducation();
    }

    if (
      userInfo?.account_web_link?.length ||
      userInfo?.account_social?.length
    ) {
      setListWebLink(
        userInfo?.account_web_link?.map(item => ({
          icon: 'fas fa-globe-asia fa-xl',
          text:
            item.url.length > 60 ? `${item.url.substring(0, 60)}...` : item.url
        }))
      );
      setListSocial(
        userInfo?.account_social?.map(item => ({
          icon: buttonSocial?.find(el => el.id === item.social_category_id)
            ?.icon,
          text: item.text,
          typeIcon: 'image'
        }))
      );
    }
  }, [lifeEvents, JSON.stringify(userInfo)]);

  const listInfoOverview = [
    ...listWork,
    ...listEducation,
    {
      icon: 'fas fa-house-user fa-xl',
      text: (
        <span>
          Sống tại
          <span style={{ fontWeight: '500' }}>
            {' '}
            {userInfo?.place_live?.name ?? userInfo?.place_live?.title}
          </span>
        </span>
      )
    },
    {
      icon: 'fas fa-map-marker fa-xl',
      text: (
        <span>
          Đến từ
          <span style={{ fontWeight: '500' }}>
            {' '}
            {userInfo?.hometown?.name ?? userInfo?.hometown?.title}
          </span>
        </span>
      )
    },
    {
      icon: 'fas fa-heart fa-xl',
      text: relationshipInfo?.relationship_category?.name ? (
        relationshipInfo?.relationship_category?.name === 'Độc thân' ? (
          <span style={{ fontWeight: '500' }}>Độc thân</span>
        ) : (
          <span>
            <span>{relationshipInfo?.relationship_category?.name}</span>{' '}
            {relationshipInfo?.partner?.id !== userInfo?.id ? (
              <span>
                cùng với&nbsp;
                <span style={{ fontWeight: '500' }}>
                  {relationshipInfo?.partner?.display_name}
                </span>{' '}
              </span>
            ) : null}
            {relationshipInfo?.status === 'pending' ? (
              <span> (Đang chờ)</span>
            ) : (
              ''
            )}
          </span>
        )
      ) : (
        ''
      )
    },
    {
      icon: 'fas fa-phone fa-xl',
      text: userInfo?.phone_number
    },
    {
      icon: 'fa-solid fa-file-signature fa-xl',
      text: userInfo?.other_name
    },
    ...listWeblink,
    ...listSocial
  ];
  
  return <DataItem listItem={listInfoOverview} />;
}
