import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

interface Props {
  // buttonRef: React.MutableRefObject<any>;
  children: React.ReactElement;
  handleScroll: () => any;
}

export default function ButtonScrollMess(props: Props) {
  const { children, handleScroll } = props;

  return (
    <Fade in={true}>
      <Box
        onClick={handleScroll}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 100,
          zIndex: 1000,
          transform: 'translateX(45%)'
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}
