import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openBoxCreate } from 'src/store/action/socialChatAction';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

function BadgeCreate() {
  const dispatch = useDispatch();

  const isOpenBoxCreate = useSelector(
    (state: any) => state.socialChatReducer.isOpenBoxCreate
  );

  const handleClick = () => {
    dispatch(openBoxCreate());
  };
  const theme = useTheme();
  return (
    <>
      {!isOpenBoxCreate ? (
        <Box
          sx={{
            width: '45px',
            height: '45px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            marginBottom: '10px',
            color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505',
            background: theme.palette.mode === 'dark' ? '#050505' : '#e5e5e5'
          }}
          onClick={handleClick}
        >
          <i
            style={{
              fontSize: 20
            }}
            className="fa-solid fa-pen-to-square"
          ></i>
        </Box>
      ) : null}
    </>
  );
}

export default BadgeCreate;
