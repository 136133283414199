import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getLifeEventUser } from 'src/apis/socialUser';
import * as types from 'src/constants/saga/user';
import * as actionsCache from 'src/store/action/saveUser';
import * as actions from 'src/store/action/socialUserAction';

const typesUser: any = types;

export const getInfoUserSagas = function* ({ payload }) {
  try {
    let data = payload.data;
    let infoMe = yield select((state: any) => state.meReducer.info);
    let meId = infoMe.uuid;
    let infoUser = {
      ...data,
      is_me: meId === data.uuid
    };
    yield put(actions.getInforlUserSuccess(infoUser));
  } catch (err) {
    yield put(actions.getInforlUserFailed());
  }
};

export const getListUserSagas = function* ({ payload }) {
  // try {
  //   const { params } = payload;
  //   let listUser: any = yield select((state: any) => state?.userReducer.list);
  //   let response = yield call(getListUser, params);
  //   if (response.status === 200) {
  //     let { data } = response.data;
  //     let newData = listUser.data.concat(data);
  //     if (params.page === 0) {
  //       newData = [...data];
  //     }
  //     newData = uniqBy(newData, 'uuid');
  //     let loaded = true;
  //     if (data.length < params.per_page) {
  //       loaded = false;
  //     }
  //     yield put(actions.getListUserSuccess(newData, params.page, loaded));
  //   }
  // } catch (err) {}
};

export const getListLifeEventUser = function* ({ payload }) {
  try {
    const idUser = payload.userInfo.id;
    let response = yield call(getLifeEventUser, idUser);
    if (response.status === 200) {
      yield put(actions.getListLifeEventSuccess(response.data));
      yield put(
        actionsCache.saveLifeEventsUser({
          lifeEvents: response.data,
          userId: idUser,
          userName: payload.userInfo.username
        })
      );
    }
  } catch (err) {}
};

export function* watchUserSpaceAsync() {
  yield takeEvery(typesUser.GET_INFOR_USER_REQ, getInfoUserSagas);
  yield takeEvery(typesUser.GET_LIST_USER_REQ, getListUserSagas);
  yield takeEvery(typesUser.GET_LIST_LIFE_EVENTS_REQUEST, getListLifeEventUser);
}
