import CheckCircleIcon from '@mui/icons-material/CheckCircle';import {
  Box,
  CircularProgress,
  CircularProgressProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  circularProgressClasses
} from '@mui/material';
import DialogConfirmPage from 'src/components/Dialog/DialogConfirmDelete';

import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import { getListMyPageReq } from 'src/store/action/socialPageAction';
import { getProductOwner } from 'src/store/action/socialProductAction';
import { getDetailPageApi } from 'src/apis/socialPages.api';
import avatarDefault from 'src/assets/images/avatar_default.jpg';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '300px',
    position: 'fixed',
    top: '75px',
    backgroundColor: theme.palette.background.primary,
    borderRadius: '10px !important',
    padding: '20px'
  },
  wrapList: {
    width: '100%'
  },
  icon: {
    minWidth: '35px !important',
    marginTop: 'auto',
    cursor: 'pointer'
  },
  iconChecked: {
    minWidth: '35px !important',
    cursor: 'pointer'
  },
  typography: {
    cursor: 'pointer'
  }
}));

function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: theme =>
            theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export const NavigationSidebar = props => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const pagesOwner = useSelector((state: any) => state.pageReducer.my_pages);
  const productInfo = useSelector((state: any) => state.productReducer.info);
  const infoPreview = useSelector(
    (state: any) => state.productReducer.infoPreview
  );

  const productOwner = useSelector(
    (state: any) => state.productReducer.productOwner
  );
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);

  const [openDialogConfirmPage, setOpenDialogConfirmPage] =
    React.useState<any>(null);

  const history = useHistory();

  const pagesAdmin = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.map((el: any) => ({
    id: el.id,
    username: el.username,
    avatar_icon: el.avatar_media
      ? el.avatar_media.show_url ??
        el.avatar_media.preview_url ??
        el.avatar_media.url
      : avatarDefault,
    action: () => {
      dispatch(
        getProductOwner({
          ...el,
          page_owner_id: el.id,
          title: el.title,
          avatar_icon: el.avatar_media
            ? el.avatar_media.show_url ??
              el.avatar_media.preview_url ??
              el.avatar_media.url
            : avatarDefault,
          description: 'Shop - Trang của bạn',
          ward: el.ward,
          username: el.username
        })
      );
      history.push(`/products/create/offline?page_id=${el.id}`);
    },
    label: el.title,
    styleLabel: { width: '125px' }
  }));

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const pageIdUrl: any = query.get('page_id');

  const handleDispatchPageIdUrl = async pageId => {
    try {
      if (!pageId) {
        pageId = pagesOwner[0]?.id;
      }

      const res = await getDetailPageApi(pageId);
      const { data: pageDetail } = res;

      if (res.status === 200 && res.data?.page_relationship.role) {
        const avatarIcon = pageDetail?.avatar_media?.show_url || avatarDefault;

        dispatch(
          getProductOwner({
            ...pageDetail,
            page_owner_id: pageDetail?.id,
            title: pageDetail?.title,
            avatar_icon: avatarIcon,
            description: 'Shop - Trang của bạn',
            username: pageDetail?.username,
            ward: pageDetail?.ward
          })
        );
      }
    } catch (error) {
      pageId = pagesOwner[0]?.id;
    }

    history.push(`/products/create/offline?page_id=${pageId}`);
  };

  React.useEffect(() => {
    if (pagesOwner?.length) {
      if (productInfo?.id) {
        dispatch(
          getProductOwner({
            ...productInfo,
            page_owner_id: productInfo?.page?.id,
            title: productInfo?.page?.title,
            avatar_icon: productInfo?.page?.avatar_media?.show_url
              ? productInfo?.page?.avatar_media?.show_url
              : avatarDefault,
            description: 'Shop - Trang của bạn',
            username: productInfo?.page?.username,
            // dispatch thêm ward do api trả về không có ward
            // tìm page trong mảng pageOwer có id bằng productInfo?.page?.id để lấy ward
            ward: pagesOwner.find(item => {
              if (item.id === productInfo?.page?.id) return item.ward;
            })?.ward
          })
        );
      } else {
        handleDispatchPageIdUrl(pageIdUrl);
      }
    }
  }, [JSON.stringify(pagesOwner), productInfo?.id, pageIdUrl]);

  const loadActivity = maxId => {
    dispatch(
      getListMyPageReq({
        max_id: maxId,
        limit: 10
      })
    );
  };

  const funcLoad = () => {
    let pageLast = [...pagesAdmin].pop();
    let maxId = pageLast?.id;
    loadActivity(maxId);
  };
  //  content sibar cũ bên create product
  // const listNavigation = [
  //   {
  //     id: 'basic-info',
  //     icon:
  //       infoPreview?.basic?.length === 4 ? (
  //         <CheckCircleIcon color="primary" />
  //       ) : (
  //         <FacebookCircularProgress value={25 * infoPreview?.basic?.length} />
  //       ),
  //     text: 'Thông tin cơ bản'
  //   },
  //   {
  //     id: 'detail-info',
  //     icon: <FacebookCircularProgress value={25} />,
  //     text: 'Thông tin chi tiết'
  //   },
  //   {
  //     id: 'sold-info',
  //     icon: !Object.keys(infoPreview?.sale)?.length ? (
  //       <CheckCircleIcon color="primary" />
  //     ) : (
  //       <FacebookCircularProgress
  //         value={50 * (2 - Object.keys(infoPreview?.sale)?.length)}
  //       />
  //     ),
  //     text: 'Thông tin bán hàng'
  //   }
  //   // {
  //   //   id: 'transport-info',
  //   //   icon: <CircleOutlinedIcon />,
  //   //   text: 'Vận chuyển'
  //   // },
  //   // {
  //   //   id: 'other-info',
  //   //   icon: <CircleOutlinedIcon />,
  //   //   text: 'Thông tin khác'
  //   // }
  // ];

  return (
    <Paper className={classes.root}>
      {productInfo?.id ? null : (
        <IconButtonOptions
          name={productOwner?.title}
          styleNameButton={{
            width: '90px',
            color: 'text.primary'
          }}
          avatar={productOwner?.avatar_icon}
          description={productOwner?.description}
          styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
          icon={
            <i
              style={{ fontSize: '16px' }}
              className="fa-solid fa-caret-down"
            ></i>
          }
          openPopup={true}
          style={{
            margin: '0px 10px',
            width: '240px',
            minHeight: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'button.primary.background'
          }}
          options={[
            [{ title: 'Trang' }].concat(
              pagesAdmin.map((el: any) => ({
                ...el,
                checkbox: true
              }))
            )
          ]}
          styleListMenu={{
            minWidth: '230px',
            height: '250px',
            overflowY: 'scroll'
          }}
          stylePopup={{ zIndex: '1001', width: '240px' }}
          widthListItem="225px"
          styleChecked="0px"
          searchFeature={true}
          actionSearch={el => {
            dispatch(
              getProductOwner({
                ...el,
                page_owner_id: el.id,
                title: el.title,
                avatar_icon: el.avatar_media?.show_url
                  ? el.avatar_media.show_url
                  : avatarDefault,
                description: 'Shop - Trang của bạn',
                ward: el.ward,
                username: el.username
              })
            );
          }}
          infiniteScroll={true}
          funcLoad={funcLoad}
          hasMore={hasMore}
        />
      )}
      {/* Sibar cũ bên create product */}
      {/* <List className={classes.wrapList}>
        {listNavigation.map(item => (
          <ListItem key={item.id}>
            <ListItemIcon
              className={
                infoPreview?.basic?.length === 4
                  ? classes.iconChecked
                  : classes.icon
              }
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  className={classes.typography}
                >
                  {item.text}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List> */}

      {!!openDialogConfirmPage && (
        <DialogConfirmPage
          title="Cảnh báo"
          contentDialog={openDialogConfirmPage}
          open={!!openDialogConfirmPage}
          handleClose={() => setOpenDialogConfirmPage(null)}
          action={() => {
            if (!pagesOwner?.length) {
              history.push('/pages/creation');
              return;
            } else {
              history.push(
                `/page/${
                  productOwner?.username ?? productOwner?.page_owner_id
                }/edit_page_info`
              );
            }
          }}
          labelButton={!pagesOwner?.length ? 'Tạo trang ngay' : 'Cập nhật'}
        />
      )}
    </Paper>
  );
};
