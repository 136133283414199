import React from 'react';import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import Autocomplete from '@mui/material/Autocomplete';
import { Theme, Typography, Avatar, Button, Divider } from '@mui/material';

const AutocompleteUser = props => {
  const { options, handleChange, value } = props;
  return (
    <Autocomplete
      options={options}
      value={value}
      autoHighlight
      getOptionLabel={(option: any) => option.label}
      onChange={handleChange}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <AvatarSocial
            type="feed"
            avatarObj={option?.url}
            style={{ width: 32, height: 32, marginRight: 15 }}
          />
          <Typography style={{ fontWeight: 500 }}>{option?.label}</Typography>
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Chọn người thân của bạn"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default AutocompleteUser;
