import React from 'react';
import { Box, Avatar, Grid, CircularProgress } from '@mui/material';
import PrimarySearchAppBar from 'src/components/Header/HeaderGlobal/HeaderGlobal';
import logo from 'src/assets/images/LogoEmso.svg';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { QRCodeCanvas } from 'qrcode.react';
import {
  getPostRequestAction,
  resetActivities
} from 'src/store/action/socialPostAction';
import { useDispatch } from 'react-redux';
import {
  handleUseInvitesToken,
  validateInviteToken,
  showInviteRoom
} from 'src/apis/socialChat.apis';
import Button from 'src/components/Button/Button';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { urlRocketChat } from 'src/util/config';
import Login from 'src/pages/Login/Login';
import ToastMessage from 'src/pages/Chat/InvitesLinkChat/ToastMessage';
import { buttonColor } from 'src/constants/styles';

const InvitesChat = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  let { token } = useParams() as any;
  const [dataRoom, setDataRoom] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [isAlert, setIsAlert] = React.useState<boolean>(false);
  const QRCode = `${window.location.origin}/invite/${token}`;
  const validateInviteLink = async () => {
    try {
      if (token) {
        const response = await validateInviteToken(token);
        if (response.status === 200) {
          const { valid } = response.data;
          if (valid) {
            const response = await showInviteRoom(token);
            if (response.status === 200) {
              setDataRoom(response.data.room);
              setLoading(false);
            }
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    validateInviteLink();
  }, []);
  const handleLinkToHome = () => {
    if (match.path === PATHS.HOME) {
      history.push(PATHS.HOME);
      dispatch(resetActivities());
      dispatch(
        getPostRequestAction(
          {
            exclude_replies: true,
            limit: 4
          },
          'stream_home',
          window.location.href
        )
      );
    } else {
      history.push(PATHS.HOME);
    }
  };
  const handleConvertToRoom = async () => {
    try {
      const response = await validateInviteToken(token);
      if (response.status === 200) {
        const accessToken = await localStorage.getItem('token');
        if (accessToken) {
          const response = await handleUseInvitesToken(token);
          if (response.status === 200) {
            history.push(PATHS.HOME);
          }
        } else {
          setIsLogin(true);
        }
      }
    } catch (error) {
      console.log(error);
      setIsAlert(true);
    }
  };

  const Logo = () => {
    return (
      <>
        <Avatar
          style={{
            cursor: 'pointer'
          }}
          onClick={handleLinkToHome}
          alt="EmsoLogo"
          src={logo}
        />
      </>
    );
  };
  const imageSettings = {
    src: logo,
    height: 30,
    width: 30,
    excavate: true
  };

  return isLogin ? (
    <Login />
  ) : (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        background: '#fafafa'
      }}
    >
      <PrimarySearchAppBar children={<Logo />} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexGrow: 1,
              padding: '100px 0',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid item xs={10} md={8}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '24px',
                  backgroundColor: '#fff',
                  padding: '24px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    flex: 1
                  }}
                >
                  <AvatarSocial
                    type="feed"
                    src={`${urlRocketChat}/avatar/room/${dataRoom?.rid}?etag=${dataRoom?.avatarETag}`}
                    style={{ width: 80, height: 80 }}
                  />
                  <Box sx={{ padding: '0 12px' }}>
                    <p
                      style={{
                        fontSize: '28px',
                        fontWeight: '600'
                      }}
                    >
                      {dataRoom?.fname || `Không tìm thấy nhóm này!`}
                    </p>
                    <p
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                        padding: '12px  0 0'
                      }}
                    >
                      Nhóm {dataRoom?.t === 'c' ? 'công khai' : 'riêng tư'} .{' '}
                      {dataRoom?.members.length} thành viên{' '}
                    </p>
                    <Button
                      label={'Tham gia nhóm'}
                      action={handleConvertToRoom}
                      style={{
                        width: '200px !important',
                        padding: '12px 18px',
                        color: '#f1f2f3',
                        margin: '24px 0',
                        backgroundColor: dataRoom?.fname
                          ? buttonColor.backgroundColor
                          : '#ddd'
                      }}
                      disabled={dataRoom?.fname ? false : true}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flex: '1'
                  }}
                >
                  <QRCodeCanvas
                    size={200}
                    value={QRCode}
                    level="H"
                    fgColor="#373536"
                    imageSettings={imageSettings}
                  />
                  <p
                    style={{
                      fontSize: '13px',
                      opacity: 0.75,
                      width: '200px',
                      textAlign: 'center',
                      padding: '8px 0'
                    }}
                  >
                    Mở EMSO Chat bấm quét QR để quét và xem trên điện thoại
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      {isAlert && (
        <ToastMessage
          open={isAlert}
          setOpen={setIsAlert}
          statusToast={'error'}
          label={'Vui lòng đăng nhập lại!'}
        />
      )}
    </Box>
  );
};

export default InvitesChat;
